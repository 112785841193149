import { Link } from 'react-router-dom'
import '../../App.css'
import Rectangle from '../../images/Rectangle-3.jpg'

export function PreInformacion(){
    return(
        <>
            <div className=" cont_Info">
                <a className="back"> {'<'} Regresar a mis oferta hipotecarias</a>
                <div className="dosColumns cont_flex">
                    <div className="col6">
                        <h1 className="titt">Antes de continuar te pediremos la siguiente información</h1>
                        <p>Toda los datos que envies serán evaluados por uno de nuestros agentes. 
                            Recibirás un correo electrónico en una lapso de 3 días con respuesta a tu solicitud.
                        </p>
                        <ul className="check">
                            <li><Link to="/informacion/inf-laboral"><i className="fa fa-circle-check"></i> Información laboral <span>Editar <i className="fa fa-pencil"></i></span></Link></li>
                            <li><Link to="/informacion/inf-personal"><i className="fa fa-circle-check"></i> Información personal <span>Editar <i className="fa fa-pencil"></i></span></Link></li>
                            <li><Link to="/informacion/inf-medica"><i className="fa fa-circle-check"></i> Información médica <span>Editar <i className="fa fa-pencil"></i></span></Link></li>
                            <li><Link to="/informacion/inf-inmueble"><i className="fa fa-circle-check"></i> Información del inmueble <span>Editar <i className="fa fa-pencil"></i></span></Link></li>
                        </ul>
                        {/* <!--<a hrf="#" className="btn btn_morado">Listos cuando tu lo estés</a>--> */}
                        <Link to='/informacionEnviada' className="btn btn_gris">Enviar documentos</Link>
                    </div>
                    <div className="cont_img col6"> <img src={Rectangle}/></div>
            </div>
        </div>
        </>
    )
}

export function InfoLaborar(){
    return(
        <>
            <div className=" cont_Info">
                <a className="back">{'<'} Regresar a mis oferta hipotecarias</a>

                <h1 className="titt">Información Laboral</h1>
                <p>Asegurate de poner la información correcta. Podrás regresar a editarlo.</p>

                <div className="Resum">
                    <div className="header">
                        <h3>Empleo actual</h3>
                    </div>
                    <div className="cont_flex cont-form">
                        <form>
                            <div className="col3 campo-form">
                                <label>Nombre de la empresa</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Puesto</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Teléfono</label>
                                <input type="tel"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Fecha de ingreso</label>
                                <select type="date"><option value="01">01</option></select>
                                <select type="date"><option value="Enero">Enero</option></select>
                                <select type="date"><option value="2022">2022</option></select>
                            </div>
                            <div className="col3 campo-form">
                                <label>Guiro de la empresa</label>
                                <input type="text"></input>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="Resum">
                    <div className="header">
                        <h3>Dirección del empleo</h3>
                    </div>
                    <div className="cont_flex cont-form">
                        <form>
                            <div className="col3 campo-form">
                                <label>Calle</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Número Ext</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Número Int</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Código Postal</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form" id="delegacion">
                                <label>Delegación</label>
                                <select type="date"><option value="Selecciona">Selecciona</option></select>
                            </div>
                            <div className="col3 campo-form" id="colonia">
                                <label>Colonia</label>
                                <select type="date"><option value="Selecciona">Selecciona</option></select>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="Resum">
                    <div className="header">
                    <h3>Empleo anterior</h3>
                    
                    </div>
                    <div className="cont_flex cont-form">
                        <form>
                            <div className="col3 campo-form">
                                <label>Nombre de la empresa</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Puesto</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Teléfono</label>
                                <input type="tel"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Fecha de ingreso</label>
                                <select type="date"><option value="01">01</option></select>
                                <select type="date"><option value="Enero">Enero</option></select>
                                <select type="date"><option value="2022">2022</option></select>
                            </div>
                            <div className="col3 campo-form">
                                <label>Guiro de la empresa</label>
                                <input type="text"></input>
                            </div>
                        </form>
                    </div>
                </div>
                <Link to="/informacion/inf-personal" className="btn btn_morado">Continuar con información personal</Link>
            </div>
        </>
    )
}

export function InfoPersonal(){
    return(
        <>
            <div className=" cont_Info">
                <a className="back">{'<'} Regresar a mis oferta hipotecarias</a>
                <h1 className="titt">Información Personal</h1>
                <p>Asegurate de poner la información correcta. Podrás regresar a editarlo.</p>
                <div className="Resum">
                    <div className="header">
                        <h3>Empleo actual</h3>
                    </div>
                    <div className="cont_flex cont-form">
                        <form>
                            <div className="col3 campo-form">
                                <label>PPE <small>(Persona politicamente expuesta)</small></label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>CURP</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Sexo</label>
                                <input type="tel"></input>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="Resum">
                    <div className="header">
                        <h3>Referencia familiar 1</h3>
                    </div>
                    <div className="cont_flex cont-form">
                        <form>
                            <div className="col3 campo-form">
                                <label>Nombre</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Apellido Paterno</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Apellido Materno</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Relación</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form" id="delegacion">
                                <label>Teléfono</label>
                                <input type="tel"></input>
                                
                            </div>
                            <div className="col3 campo-form" id="colonia">
                                <label>E-mail ( opcinal)</label>
                                <input type="input"></input>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="Resum">
                    <div className="header">
                        <h3>Referencia familiar 2</h3>
                    </div>
                    <div className="cont_flex cont-form">
                        <form>
                            <div className="col3 campo-form">
                                <label>Nombre</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Apellido Paterno</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Apellido Materno</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Relación</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form" id="delegacion">
                                <label>Teléfono</label>
                                <input type="tel"></input>
                                
                            </div>
                            <div className="col3 campo-form" id="colonia">
                                <label>E-mail ( opcinal)</label>
                                <input type="input"></input>
                            </div>
                        </form>
                    </div>
                </div>
                <Link to="/informacion/inf-medica" className="btn btn_morado">Continuar con información medica</Link>
            </div>
        </>
    )
}

export function InfoMedica(){
    return(
        <>
            <div className=" cont_Info">
                <a className="back">{'<'} Regresar a mis oferta hipotecarias</a>
                <h1 className="titt">Información Personal</h1>
                <p>Asegurate de poner la información correcta. Podrás regresar a editarlo.</p>
                <div className="Resum">
                    <div className="header">
                        <h3>Empleo actual</h3>
                    </div>
                    <div className="cont_flex cont-form">
                        <form>
                            <div className="col3 campo-form">
                                <label>PPE <small>(Persona politicamente expuesta)</small></label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>CURP</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Sexo</label>
                                <input type="tel"></input>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="Resum">
                    <div className="header">
                        <h3>Referencia familiar 1</h3>
                    </div>
                    <div className="cont_flex cont-form">
                        <form>
                            <div className="col3 campo-form">
                                <label>Nombre</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Apellido Paterno</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Apellido Materno</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Relación</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form" id="delegacion">
                                <label>Teléfono</label>
                                <input type="tel"></input>
                            </div>
                            <div className="col3 campo-form" id="colonia">
                                <label>E-mail ( opcinal)</label>
                                <input type="input"></input>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="Resum">
                    <div className="header">
                        <h3>Referencia familiar 2</h3>
                    </div>
                    <div className="cont_flex cont-form">
                        <form>
                            <div className="col3 campo-form">
                                <label>Nombre</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Apellido Paterno</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Apellido Materno</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Relación</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form" id="delegacion">
                                <label>Teléfono</label>
                                <input type="tel"></input>
                            </div>
                            <div className="col3 campo-form" id="colonia">
                                <label>E-mail ( opcinal)</label>
                                <input type="input"></input>
                            </div>
                        </form>
                    </div>
                </div>
                <Link to="/informacion/inf-inmueble" className="btn btn_morado">Continuar con información inmueble</Link>
            </div>
        </>
    )
}

export function InfoInmueble(){
    return(
        <>
            <div className=" cont_Info">
                <a className="back">{'<'} Regresar a mis oferta hipotecarias</a>
                <h1 className="titt">Información Personal</h1>
                <p>Asegurate de poner la información correcta. Podrás regresar a editarlo.</p>
                <div className="Resum">
                    <div className="header">
                        <h3>Empleo actual</h3>
                    </div>
                    <div className="cont_flex cont-form">
                        <form>
                            <div className="col3 campo-form">
                                <label>PPE <small>(Persona politicamente expuesta)</small></label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>CURP</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Sexo</label>
                                <input type="tel"></input>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="Resum">
                    <div className="header">
                        <h3>Referencia familiar 1</h3>
                    </div>
                    <div className="cont_flex cont-form">
                        <form>
                            <div className="col3 campo-form">
                                <label>Nombre</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Apellido Paterno</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Apellido Materno</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Relación</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form" id="delegacion">
                                <label>Teléfono</label>
                                <input type="tel"></input>
                            </div>
                            <div className="col3 campo-form" id="colonia">
                                <label>E-mail ( opcinal)</label>
                                <input type="input"></input>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="Resum">
                    <div className="header">
                        <h3>Referencia familiar 2</h3>
                    </div>
                    <div className="cont_flex cont-form">
                        <form>
                            <div className="col3 campo-form">
                                <label>Nombre</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Apellido Paterno</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Apellido Materno</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form">
                                <label>Relación</label>
                                <input type="text"></input>
                            </div>
                            <div className="col3 campo-form" id="delegacion">
                                <label>Teléfono</label>
                                <input type="tel"></input>
                            </div>
                            <div className="col3 campo-form" id="colonia">
                                <label>E-mail ( opcinal)</label>
                                <input type="input"></input>
                            </div>
                        </form>
                    </div>
                </div>
                <Link to="/informacion/inf-personal" className="btn btn_morado">Continuar con información inmueble</Link>
            </div>
        </>
    )
}

export function DocumentosEnviados(){
    return(
        <>
            <div class=" cont_Info">
                <a class="back">{'<'} Regresar a mis oferta hipotecarias</a>
                <div class="dosColumns cont_flex">
                    <div class="col6 bg_verde">
                        <h1 class="titt">Tus documentos se han enviado correctamente</h1>
                        <p>Tus documentos serán evaluados por uno de nuestros agentes. <b>Recibirás un correo electrónico en un lapso de 3 días con respuesta a tu solicitud.</b></p>
                        {/* <!--<a hrf="#" class="btn btn_morado">Listos cuando tu lo estés</a>--> */}
                        <Link to='/home' class="btn btn_azul">Regresar a tu resúmen</Link>
                    </div>
                    <div class="cont_img col6"> <img src={Rectangle}/> </div>
                </div>
            </div>
        </>
        
    )
}