import "../App.css";
import { useNavigate } from "react-router-dom";
import Frame_43 from '../images/Frame_43.png'
import { useState, useEffect } from "react";
import { ModalBuroDeCredito, ModalLoadingGeneral } from '../components/Modals'
import { InfoScore } from '../components/Cards'
import Swal from "sweetalert2";
import { put, get } from '../utils/http'

// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------------- Mensaje cuando el paso1(cotización) fue aprobado -------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function Aprobado1(){
    const [isOpenModalBuro, setIsOpenModalBuro] = useState(false)
    const [statuscode, setstatuscode] = useState()
    const [pintarScore,setPintarScore] = useState()

    let responseBtBuro
    const verModalburo = async() => { 
        responseBtBuro = await get('obtDatosPaso2PorIdResPaso1',parseInt(localStorage.getItem("idResPaso1Buro")))
        setstatuscode(responseBtBuro.statuscode)
        if(responseBtBuro.statuscode === 200){
            let infoGetScore = [{
                pagoMensual: responseBtBuro.paso2.pagoMensual,
                capacidadPago: responseBtBuro.paso2.capacidadPago,
                deuda: responseBtBuro.paso2.deuda,
                endeudamiento: responseBtBuro.paso2.endeudamiento,
                comportamiento: responseBtBuro.paso2.comportamiento,
                mensualidadSinDeuda: responseBtBuro.paso2.mensualidadSinDeuda,
                montoSinDeuda: responseBtBuro.paso2.montoSinDeuda,
                mensualidadConDeuda: responseBtBuro.paso2.mensualidadConDeuda,
                montoConDeuda: responseBtBuro.paso2.montoConDeuda
            }]

            let cardScore = infoGetScore.map((infoScore) => <InfoScore
                                                                pagoMensual={infoScore.pagoMensual}
                                                                capacidadPago={infoScore.capacidadPago}
                                                                deuda={infoScore.deuda}
                                                                endeudamiento={infoScore.endeudamiento}
                                                                comportamiento={infoScore.comportamiento}
                                                                mensualidadSinDeuda={infoScore.mensualidadSinDeuda}
                                                                montoSinDeuda={infoScore.montoSinDeuda}
                                                                mensualidadConDeuda={infoScore.mensualidadConDeuda}
                                                                montoConDeuda={infoScore.montoConDeuda}            
                                                            />)
            setPintarScore(cardScore) 
        }
    }
   
    useEffect( () => {
        verModalburo()    
    },[])
    
    return(
        <>
            <div className="bg_morado2">
                <p className="aprobado"><i className="fa fa-circle-check"></i> Aprobado</p> <h3>¡Felicidades! Cuentas con capacidad de crédito</h3> 
                {statuscode === 200 && <button  className="btn btn_bco marginEspacio" onClick={() => {setIsOpenModalBuro(true) }}>Consulta los detalles de tu buro</button>}
                <ModalBuroDeCredito open = {isOpenModalBuro} cardScoreModal = {pintarScore} close = { () => setIsOpenModalBuro(false)} ></ModalBuroDeCredito>
            </div>
        </>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------- Mensaje mostrado en cuestionario PreAutoriza cuando el paso1(cotización) fue aprobado -------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function  AprobadoLogueado(){
    const [isOpenModalBuro, setIsOpenModalBuro] = useState(false)
    const [statuscode, setstatuscode] = useState()
    const [pintarScore,setPintarScore] = useState()

    let responseBtBuro
    const verModalburo = async() => { 
        responseBtBuro = await get('obtDatosPaso2PorIdResPaso1',parseInt(localStorage.getItem("idResPaso1Cuestionario")))
        setstatuscode(responseBtBuro.statuscode)
        if(responseBtBuro.statuscode === 200){
            let infoGetScore = [{
                pagoMensual: responseBtBuro.paso2.pagoMensual,
                capacidadPago: responseBtBuro.paso2.capacidadPago,
                deuda: responseBtBuro.paso2.deuda,
                endeudamiento: responseBtBuro.paso2.endeudamiento,
                comportamiento: responseBtBuro.paso2.comportamiento,
                mensualidadSinDeuda: responseBtBuro.paso2.mensualidadSinDeuda,
                montoSinDeuda: responseBtBuro.paso2.montoSinDeuda,
                mensualidadConDeuda: responseBtBuro.paso2.mensualidadConDeuda,
                montoConDeuda: responseBtBuro.paso2.montoConDeuda
            }]

            let cardScore = infoGetScore.map((infoScore) => <InfoScore
                                                                pagoMensual={infoScore.pagoMensual}
                                                                capacidadPago={infoScore.capacidadPago}
                                                                deuda={infoScore.deuda}
                                                                endeudamiento={infoScore.endeudamiento}
                                                                comportamiento={infoScore.comportamiento}
                                                                mensualidadSinDeuda={infoScore.mensualidadSinDeuda}
                                                                montoSinDeuda={infoScore.montoSinDeuda}
                                                                mensualidadConDeuda={infoScore.mensualidadConDeuda}
                                                                montoConDeuda={infoScore.montoConDeuda}            
                                                            />)
            setPintarScore(cardScore) 
        }
    }
   
    useEffect( () => {
        verModalburo()    
    },[])
    return(
        <>
            <div className="anchoEvenly bg_morado2 ">
                <h3 className="textoCentrado"> ¡Felicidades! Cuentas con capacidad de crédito</h3> 
                <p className="aprobado"><i className="fa fa-circle-check"></i> Aprobado</p>
                <button  className="btn_bco" onClick={() => {setIsOpenModalBuro(true) }}>Consulta los detalles de tu buro</button>
                <ModalBuroDeCredito open = {isOpenModalBuro} cardScoreModal = {pintarScore} close = { () => setIsOpenModalBuro(false)} ></ModalBuroDeCredito>
            </div>
        </>
    )
}
  
// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------------------- Mensaje cuando el paso1(cotización) no fue aprobado ------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function NoAprobado1(){
    const [isOpenModalBuro, setIsOpenModalBuro] = useState(false)
    const [statuscode, setstatuscode] = useState()
    const [pintarScore,setPintarScore] = useState()

    let responseBtBuro
    const verModalburo = async() => { 
        responseBtBuro = await get('obtDatosPaso2PorIdResPaso1',parseInt(localStorage.getItem("idResPaso1Buro")))
        setstatuscode(responseBtBuro.statuscode)
        if(responseBtBuro.statuscode === 200){
            let infoGetScore = [{
                pagoMensual: responseBtBuro.paso2.pagoMensual,
                capacidadPago: responseBtBuro.paso2.capacidadPago,
                deuda: responseBtBuro.paso2.deuda,
                endeudamiento: responseBtBuro.paso2.endeudamiento,
                comportamiento: responseBtBuro.paso2.comportamiento,
                mensualidadSinDeuda: responseBtBuro.paso2.mensualidadSinDeuda,
                montoSinDeuda: responseBtBuro.paso2.montoSinDeuda,
                mensualidadConDeuda: responseBtBuro.paso2.mensualidadConDeuda,
                montoConDeuda: responseBtBuro.paso2.montoConDeuda
            }]

            let cardScore = infoGetScore.map((infoScore) => <InfoScore
                                                                pagoMensual={infoScore.pagoMensual}
                                                                capacidadPago={infoScore.capacidadPago}
                                                                deuda={infoScore.deuda}
                                                                endeudamiento={infoScore.endeudamiento}
                                                                comportamiento={infoScore.comportamiento}
                                                                mensualidadSinDeuda={infoScore.mensualidadSinDeuda}
                                                                montoSinDeuda={infoScore.montoSinDeuda}
                                                                mensualidadConDeuda={infoScore.mensualidadConDeuda}
                                                                montoConDeuda={infoScore.montoConDeuda}            
                                                            />)
            setPintarScore(cardScore) 
        }
    }
    
    useEffect( () => {
        verModalburo()    
    },[])

    return(
        <>
            <div className="cont_flex">
                <div className="bg_gris2">
                    <p className="alert"> <i className="fa fa-circle-exclamation-blanco" ></i></p>
                    <h4>Con los datos que nos muestras no eres sujeto de crédito, sin embargo te recomendamos</h4>
                    <ul>
                        <li><i className="fa fa-circle-check"></i>Pagar algunas deudas y cancelar tarjetas de crédito que no utilices.</li>
                        <li><i className="fa fa-circle-check"></i>Sumar ingresos con alguién más, como tu pareja.</li>
                        <li><i className="fa fa-circle-check"></i>Poner recordatorios en tu agenda para pagar tus tarjetas siempre a tiempo.</li>
                        <li><i className="fa fa-circle-check"></i>Sin embargo, puedes registrarte para ver las opciones de crédito disponibles. </li>
                        {statuscode === 200 && <button  className="btn btn_bco marginEspacio" onClick={() => {setIsOpenModalBuro(true) }}>Consulta los detalles de tu buro</button>}
                        <ModalBuroDeCredito open = {isOpenModalBuro} cardScoreModal = {pintarScore} close = { () => setIsOpenModalBuro(false)} ></ModalBuroDeCredito>
                    </ul>
                </div>
            </div>
        </>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------------------- Mensaje cuando el paso1(cotización) no fue aprobado ------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function NoAprobado1Logueado(){
    const [isOpenModalBuro, setIsOpenModalBuro] = useState(false)
    const [statuscode, setstatuscode] = useState()
    const [pintarScore,setPintarScore] = useState()

    let responseBtBuro
    const verModalburo = async() => { 
        responseBtBuro = await get('obtDatosPaso2PorIdResPaso1',parseInt(localStorage.getItem("idResPaso1Buro")))
        setstatuscode(responseBtBuro.statuscode)
        if(responseBtBuro.statuscode === 200){
            let infoGetScore = [{
                pagoMensual: responseBtBuro.paso2.pagoMensual,
                capacidadPago: responseBtBuro.paso2.capacidadPago,
                deuda: responseBtBuro.paso2.deuda,
                endeudamiento: responseBtBuro.paso2.endeudamiento,
                comportamiento: responseBtBuro.paso2.comportamiento,
                mensualidadSinDeuda: responseBtBuro.paso2.mensualidadSinDeuda,
                montoSinDeuda: responseBtBuro.paso2.montoSinDeuda,
                mensualidadConDeuda: responseBtBuro.paso2.mensualidadConDeuda,
                montoConDeuda: responseBtBuro.paso2.montoConDeuda
            }]

            let cardScore = infoGetScore.map((infoScore) => <InfoScore
                                                                pagoMensual={infoScore.pagoMensual}
                                                                capacidadPago={infoScore.capacidadPago}
                                                                deuda={infoScore.deuda}
                                                                endeudamiento={infoScore.endeudamiento}
                                                                comportamiento={infoScore.comportamiento}
                                                                mensualidadSinDeuda={infoScore.mensualidadSinDeuda}
                                                                montoSinDeuda={infoScore.montoSinDeuda}
                                                                mensualidadConDeuda={infoScore.mensualidadConDeuda}
                                                                montoConDeuda={infoScore.montoConDeuda}            
                                                            />)
            setPintarScore(cardScore) 
        }
    }
    
    useEffect( () => {
        verModalburo()    
    },[])

    return(
        <>
            <div className="cont_flex">
                <div className="bg_gris2">
                    <p className="alert"> <i className="fa fa-circle-exclamation-blanco"></i></p>
                    <h4>Con los datos que nos muestras no eres sujeto de crédito, sin embargo te recomendamos</h4>
                    <br />
                    <ul>
                        <li><i className="fa fa-circle-check"></i>Pagar algunas deudas y cancelar tarjetas de crédito que no utilices.</li>
                        <li><i className="fa fa-circle-check"></i>Sumar ingresos con alguién más, como tu pareja.</li>
                        <li><i className="fa fa-circle-check"></i>Poner recordatorios en tu agenda para pagar tus tarjetas siempre a tiempo.</li>
                        {statuscode === 200 && <button  className="btn btn_bco marginEspacio" onClick={() => {setIsOpenModalBuro(true) }}>Consulta los detalles de tu buro</button>}
                        <ModalBuroDeCredito open = {isOpenModalBuro} cardScoreModal = {pintarScore} close = { () => setIsOpenModalBuro(false)} ></ModalBuroDeCredito>
                    </ul>
                </div>
            </div>
        </>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------------------- Mensaje cuando no se obtiene respuesta del buro  ------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function NoBuro(){
    const [isOpenModalBuro, setIsOpenModalBuro] = useState(false)
    const [statuscode, setstatuscode] = useState()
    const [pintarScore,setPintarScore] = useState()

    let responseBtBuro
    const verModalburo = async() => { 
        responseBtBuro = await get('obtDatosPaso2PorIdResPaso1',parseInt(localStorage.getItem("idResPaso1Buro")))
        setstatuscode(responseBtBuro.statuscode)
        if(responseBtBuro.statuscode === 200){
            let infoGetScore = [{
                pagoMensual: responseBtBuro.paso2.pagoMensual,
                capacidadPago: responseBtBuro.paso2.capacidadPago,
                deuda: responseBtBuro.paso2.deuda,
                endeudamiento: responseBtBuro.paso2.endeudamiento,
                comportamiento: responseBtBuro.paso2.comportamiento,
                mensualidadSinDeuda: responseBtBuro.paso2.mensualidadSinDeuda,
                montoSinDeuda: responseBtBuro.paso2.montoSinDeuda,
                mensualidadConDeuda: responseBtBuro.paso2.mensualidadConDeuda,
                montoConDeuda: responseBtBuro.paso2.montoConDeuda
            }]

            let cardScore = infoGetScore.map((infoScore) => <InfoScore
                                                                pagoMensual={infoScore.pagoMensual}
                                                                capacidadPago={infoScore.capacidadPago}
                                                                deuda={infoScore.deuda}
                                                                endeudamiento={infoScore.endeudamiento}
                                                                comportamiento={infoScore.comportamiento}
                                                                mensualidadSinDeuda={infoScore.mensualidadSinDeuda}
                                                                montoSinDeuda={infoScore.montoSinDeuda}
                                                                mensualidadConDeuda={infoScore.mensualidadConDeuda}
                                                                montoConDeuda={infoScore.montoConDeuda}            
                                                            />)
            setPintarScore(cardScore) 
        }
    }
    
    useEffect( () => {
        verModalburo()    
    },[])

    return(
        <>
            <div className="cont_flex">
                <div className="bg_gris2">
                    <p className="alert"></p>
                    <h1 className="textoErrorCrearCuenta">¡Lo sentimos!</h1>
                    <h4> <i className="fa fa-circle-exclamation-blanco" ></i> No nos fue posible consultar tu buró de crédito, sin embargo, registrate para ver las opciones de crédito disponibles</h4>
                </div>
            </div>
        </>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------------------- Mensaje cuando no se obtiene respuesta del buro LOGUEADO  ------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function NoBuroLogueado(){
    const [isOpenModalBuro, setIsOpenModalBuro] = useState(false)
    const [statuscode, setstatuscode] = useState()
    const [pintarScore,setPintarScore] = useState()

    let responseBtBuro
    const verModalburo = async() => { 
        responseBtBuro = await get('obtDatosPaso2PorIdResPaso1',parseInt(localStorage.getItem("idResPaso1Buro")))
        setstatuscode(responseBtBuro.statuscode)
        if(responseBtBuro.statuscode === 200){
            let infoGetScore = [{
                pagoMensual: responseBtBuro.paso2.pagoMensual,
                capacidadPago: responseBtBuro.paso2.capacidadPago,
                deuda: responseBtBuro.paso2.deuda,
                endeudamiento: responseBtBuro.paso2.endeudamiento,
                comportamiento: responseBtBuro.paso2.comportamiento,
                mensualidadSinDeuda: responseBtBuro.paso2.mensualidadSinDeuda,
                montoSinDeuda: responseBtBuro.paso2.montoSinDeuda,
                mensualidadConDeuda: responseBtBuro.paso2.mensualidadConDeuda,
                montoConDeuda: responseBtBuro.paso2.montoConDeuda
            }]

            let cardScore = infoGetScore.map((infoScore) => <InfoScore
                                                                pagoMensual={infoScore.pagoMensual}
                                                                capacidadPago={infoScore.capacidadPago}
                                                                deuda={infoScore.deuda}
                                                                endeudamiento={infoScore.endeudamiento}
                                                                comportamiento={infoScore.comportamiento}
                                                                mensualidadSinDeuda={infoScore.mensualidadSinDeuda}
                                                                montoSinDeuda={infoScore.montoSinDeuda}
                                                                mensualidadConDeuda={infoScore.mensualidadConDeuda}
                                                                montoConDeuda={infoScore.montoConDeuda}            
                                                            />)
            setPintarScore(cardScore) 
        }
    }
    
    useEffect( () => {
        verModalburo()    
    },[])

    return(
        <>
            <div className="cont_flex">
                <div className="bg_gris2">
                    <p className="alert"></p>
                    <h1 className="textoErrorCrearCuenta">¡Lo sentimos!</h1>
                    <h4> <i className="fa fa-circle-exclamation-blanco" ></i> No nos fue posible consultar tu buró de crédito, sin embargo, puedes continuar con el proceso para ver las opciones de crédito disponibles. </h4>
                </div>
            </div>
        </>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------------------------- Mensaje cuando no tienes cotizaciones -------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function SinCotizacion(){
    return(
        <>
            <h1 className="titt">Mis cotizaciones</h1>
            <p>Aquí podrás visualizar todas tus cotizaciones, obtener información detallada sobre los mejores créditos y comenzar tu solicitud.</p>
            <p className="aviso_sinDatos"><i className="fa fa-circle-exclamation"></i> Aún no tienes cotizaciones. Cotiza y obtén la oferta ideal.</p> 

            <div className="banner">
                <h3>Buscamos la mejor opción para ti.</h3>
                <p>Cuéntanos qué buscas en un crédito y nosotros nos encargaremos de encontrar la mejor oferta para ti.</p>
                <a href="simulacion" className="btn btn_morado">Cotiza ahora</a>
            </div> 
        </>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------------------- Mensaje renderizado cuando si tienes cotizaciones -------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ConCotizacionP1(){
    return(
        <>
            <h1 className="titt">Mis cotizaciones</h1>
            <p>Aquí podrás ver todas tus cotizaciones, conocer a detalle los mejores créditos e iniciar tu solicitud.</p>
            <div className="Resum cont-Cotizaciones">
                <hr/>
                <h3><b>Estas son tus cotizaciones creadas</b></h3>
                <p>Puedes generar todas las cotizaciones que necesites</p>
            </div>
        </>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------------------- Mensaje renderizado para realizar una cotización --------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ConCotizacionP2(){
    return(
        <>
            <div className="Resum cont-Cotizaciones">
                <div className="bg_morado2">
                    <h2>Buscamos la mejor opción para ti.</h2>
                    <a href="simulacion" className="btn btn_morado">Cotiza ahora</a>
                </div> 
            </div>
        </>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------------- Mensaje con botón para guardar las dos ofertas seleccionadas  -------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ConSeleccionBancos(){
    const [isOpenModalLoading, setIsOpenModalLoading] = useState(false)
    const navigate = useNavigate()

    function guardarBancos(){          
        if( localStorage.getItem("BancoLS1") === null || localStorage.getItem("BancoLS2") === null){
            Swal.fire({
                icon: 'error',
                title: 'Debe seleccionar forzosamente 2 ofertas para continuar',
                text: 'Inténtelo de nuevo.',
                confirmButtonColor: '#9C36B2',
                showCancelButton: false,
                confirmButtonText: 'Aceptar'
            }).then((result) => {
            })
        } else {
            escogerBancosFunction();
            // KRP 17-10-2023 Se comenta para que deje pasar sin confirmar
            /*Swal.fire({
                title: 'Espera...',
                showCancelButton: true,
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Regresar',
                confirmButtonColor: "#9C36B2",
                icon: 'info',
                iconColor: "#9C36B2", 
                html:
                '<b>¿Ya tienes una propiedad elegida?</b></br></br>'+
                'Te recomendamos continuar solo si ya tienes una propiedad elegida y el vendedor ha aceptado tu oferta; esto debido a que la aprobación del banco tiene un plazo de vencimiento.',
              }).then((result) => {
                if (result.isConfirmed) {
                    escogerBancosFunction()
                } 
              })
              */
        }
    }

    const escogerBancosFunction = async() =>{
        setIsOpenModalLoading(true)
        let bancoSeleccionadoNo1 = parseInt(localStorage.getItem("BancoLS1"));
        let bancoSeleccionadoNo2 = parseInt(localStorage.getItem("BancoLS2"));
    
        let jsonBancosGuardar = {
            idResPrecalifica1: bancoSeleccionadoNo1,
            idResPrecalifica2: bancoSeleccionadoNo2,
        }

        let dataPostBancos = await put('seleccionarBancosPreAutoriza', jsonBancosGuardar)

        if(dataPostBancos.statuscode === 200){
            localStorage.removeItem("BancoLS1")
            localStorage.removeItem("BancoLS2")
            setIsOpenModalLoading(false)
            Swal.fire({
                icon: 'success',
                title: 'Felicidades',
                text: 'Ofertas seleccionadas correctamente. Pronto serás contactado por uno de nuestros ejecutivos para continuar tu solicitud.',
                confirmButtonColor: '#9C36B2',
                showCancelButton: false,
                confirmButtonText: 'Aceptar'
            }).then((result) => {
                navigate('/cotizaciones')
            })               
        } else {
            setIsOpenModalLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Algo salió mal',
                text: 'Inténtelo nuevamente más tarde.',
                confirmButtonColor: '#9C36B2',
                showCancelButton: false,
                confirmButtonText: 'Aceptar'
            }).then((result) => {
                
            })    
        }
    }

    return(
        <>
            <div className="Resum cont-Cotizaciones">
                <div className="bg_morado2 padding_pequeno responsivoColumn">
                    <h2>¿Quieres iniciar tu solicitud con las opciones seleccionadas?</h2>
                    <button className="btn btn_morado pequeno_boton" id="btnAplicarBancosPequeno" onClick={guardarBancos}>Sí, iniciar</button>
                    <button className="botonAplicarGrande" id="btnAplicarBancosGrande" onClick={guardarBancos}>Sí, iniciar</button>
                </div> 
                <ModalLoadingGeneral open = {isOpenModalLoading} ></ModalLoadingGeneral>
            </div>
        </>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------------------- Mensaje para ir a la pantalla "Mis cotizaciones"  -------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ConCotizacionP13(){
    return(
        <>  
            <h1 className="titt"> </h1>
            <div className="cont_responsive">
                <a href="/simulacion"><img src={Frame_43} alt="simulacion"/> </a>
            </div>   
            <div className="">
                <div className="header">
                    <br></br>
                    <div className="elementoFlex">
                        <h3>Mis cotizaciones</h3>
                        <a href="cotizaciones" className="link">Ir a mis cotizaciones</a>
                    </div>
                </div>
            </div>  
        </>
    )
}





