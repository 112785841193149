import "../../App.css";
import img_olvidePass from "../../images/olvidePassword.jpg";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSetDataPublic } from "../../providers/dataPublic/PublicProvider";
import { useForm } from 'react-hook-form'
import { ruleEmail } from "../../assets/validateRules";
import { get } from "../../utils/http";
import Swal from "sweetalert2";
import { ModalLoadingGeneral } from '../../components/Modals'

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------------ Pantalla: Recuperar contraseña socios ----------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function RecuperarPassSocio() {
  const {register,formState:{errors},handleSubmit} = useForm()
  const [email,setEmail] =useState('')
  const [isOpenModalLoading, setIsOpenModalLoading] = useState(false)
  const navigate = useNavigate()
  const actionSetDataPublic = useSetDataPublic()
  
  const recover =() =>{
    validarPass()
  }

const enviarMailForm = async() => {

    setIsOpenModalLoading(true);
    
    let response2 = await get('enviarEmailCambioPasswordSocio', email);

    if (response2.statuscode===200) 
    {
        setIsOpenModalLoading(false);
        Swal.fire({
        confirmButtonColor: '#9C36B2',
        title: 'Se ha enviado un correo para asegurarnos que eres tú.',
        icon: 'success',
        confirmButtonText: 'Aceptar'
        });
    }
    else
    {
        setIsOpenModalLoading(false);
        Swal.fire({
            confirmButtonColor: '#9C36B2',
            title: 'Algo ha salido mal, intentelo más tarde',
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }
}

  const validarPass = async() => {
    //*Verificamos si existe el usuario con el correo
    let correo = email;
    let response = await get("getSocio", correo);

    actionSetDataPublic({ correo: email});
    
    //*Si existe entonces enviamos el email
    if (response.statuscode===200) 
    {
      enviarMailForm();
    }
    else
    {
        Swal.fire({
            confirmButtonColor: '#9C36B2',
            title: 'El usuario no esta registrado',
            icon: 'warning',
            confirmButtonText: 'Crear cuenta'
        }).then((result) => {
            //TODO: Reedirigir a la pantalla de crear cuenta socio
            //navigate("/crearcuentaSinSimulacion")
        })
    }
  }

  useEffect( () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },[])
  
  return (
    <>
      <div className="cont_flex">
        <div className="col6">
          <h1>Olvide mi contraseña</h1>
          <p>
            No te preocupes. Ingresa tu correo electrónico y te enviaremos un
            link de acceso para que crear una nueva contraseña
          </p>
          <form onSubmit={handleSubmit(recover)}>
            <label htmlFor="email">Correo electrónico socio</label>
            <input 
                type="email" 
                name="email" 
                id="email" 
                value={email} 
                onInput={(ev)=>{setEmail(ev.target.value)}} 
                {...register('email',{required:true, validate:ruleEmail})}
            />
            {errors.email?.type === 'required' && <span>Este campo es requerdio</span>}
            {errors.email && <span>Este campo no tiene el formato correcto</span>}
            <br />
            <br />
            <button type="submit" className="btn btn_morado w100" >Enviar link</button>
          </form>
        </div>

        <div className="col6 cont_img-index">
          <img src={img_olvidePass} alt="recuperar contraseña" />
        </div>
        <ModalLoadingGeneral open = {isOpenModalLoading} ></ModalLoadingGeneral>
      </div>
    </>
  );
}