
import { get, post, put, postDocs} from "./http";



//Para guardar los pasos de las
export const guardarPaso = async (idPantalla, idCuestionarioSolicitud, idResPrecalifica)  => {

    let dataActualizarPantalla = {
        idResPrecalifica : idResPrecalifica, 
        idCuestionario: idCuestionarioSolicitud,
        idCatPantallas: idPantalla,
    }

    let actualizarPantallaAct = await put("updatePantallaActual", dataActualizarPantalla );
    if(actualizarPantallaAct.statuscode === 200) console.log("Pantalla actualizada");
}

//Para actualizar los estatus de los socios invitacion
export const actualizarEstatusInvitacion = async (idInvitacion, estatusId)  => {

    console.log(idInvitacion, estatusId);
    //Siempre vamos a evaluar hacia arriba
    //Entonces solo podemos actualizar el estatus si es mayor al que esta actualmente en bd
    let obtInvitacionInfo = await get("getInvitacion", idInvitacion );
    console.log(obtInvitacionInfo);
    if(obtInvitacionInfo.statuscode === 200)
    {
        let ultimoEstatus = obtInvitacionInfo.infoInvitacion[0].estatus;
        console.log(ultimoEstatus);
        if(estatusId > ultimoEstatus){
            let params = {
                idInvitacion: idInvitacion,
                estatus: estatusId
            }
            let actualizarEstatus = await put("actualizaEstatusInvitacion", params );
            if(actualizarEstatus.statuscode === 200) console.log("Estatus invitacion actualizado");
        }
    }
    else
    {
        console.log("No existe la invitacion");
    }
}
export const desencriptarToken = async (token) => 
{
    let desencriptar = await get("desencriptarToken", token );
    if(desencriptar.statuscode === 200){        
        return desencriptar.token;
    }
    else{
        return false;
    }
}

export const obtInformacionUsuarioRemax = async (idUsuario) => 
{
    
    let user = await get("obtInformacionUsuarioRemax", idUsuario );
    if(user.statuscode === 200){        
        return user.data;
    }
    else{
        return false;
    }
}


export const obtInformacionUsuarioSocioRemax = async (idUsuario) => 
{
    
    let user = await get("obtInformacionUsuarioSocioRemax", idUsuario );
    if(user.statuscode === 200){        
        return user.data;
    }
    else{
        return false;
    }
}