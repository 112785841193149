import Cookies from 'universal-cookie'
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import { ruleEmail } from "../assets/validateRules";
import { post } from "../utils/http";
import "../App.css";
import "../sass/style.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import login from "../images/login.jpg";
import login_imgRemax from "../images/Img_login.jpg";

export function Raiz({ children }) {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------------ Pantalla: Index/Iniciar Sesión ------------------------------------------------>
// <-------------------------------------------------------------------------------------------------------------------------------->
export function Index() {
  const [altColors, setAltColors] = useState(false);
  const [email,setEmail] = useState('');
  const [password,setPassword] = useState('')
  const cookie = new Cookies()
  const {register,formState:{errors},handleSubmit} =useForm()
  
  const onSubmit = () => {
    let timerInterval
    Swal.fire({
      title: 'Iniciando sesión',  
      color: '#9C36B2',
      timer: 20000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
        const b = Swal.getHtmlContainer().querySelector('b')
        timerInterval = setInterval(() => {
          b.textContent = Swal.getTimerLeft()
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        Swal.fire({
          title: 'Upss...',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: "#9C36B2",
          icon: 'info',
          iconColor: "#9C36B2", 
          html:
          '<b>El tiempo de espera ha sido superado, intentalo mas tarde...</b>'
        })
      }
    })
    signIn()
  }

  const signIn = async() => {
    let data = {
      "usaurio": {
        "correo": email
      },
      "password": {
        "password1": password
      }
    }

    let response = await post("login",data)
    if(response.statuscode === 200){
      cookie.set('session',true,{path:'/'})
      cookie.set('nombre',response.nombre,{path:'/'})
      cookie.set('correo',response.correo,{path:'/'})//TODO: Posiblemente correo ath o jwt 
      cookie.set('id',response.id,{path:'/'})
      
      window.location.href='./home'
    }else{
      Swal.fire({
        confirmButtonColor: '#9C36B2',
        title: 'Usuario y/o contraseña erronea!',
        text: 'Verifica que la cuenta y la contraseña sea correcta',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      })
    }
  }

  function verPassword() {

    var x = document.getElementById("password");
    var icono = document.getElementById("iconoPassword");
    if (x.type === "password") {
      x.type = "text";
      icono.className = 'fa fa-eye-slash iconoOcultarPassword'
    } else {
      x.type = "password";
      icono.className = 'fa fa-eye iconoOcultarPassword'
    }
  }

  useEffect( () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },[])

  return (
    <>
      <div className="cont_flex">
        <div className="col6">
          <h1>Accede a tu cuenta</h1>
          <p>
            Crea tu cuenta y comienza a hacer uso de los beneficios de
            pertenecer a nuestra comunidad.
          </p>
          <form onSubmit={ handleSubmit(onSubmit)}>
            <label htmlFor="email">Correo electrónico </label>
            <input type="email" name="email" id="email" autoComplete="off" value={email} 
              onInput={(ev) => { setEmail(ev.target.value)}}
              {...register('email',{required:true, validate: ruleEmail})}
              />
              {errors.email?.type === 'required' && <span class="span">Este campo es requerido</span>}
              {errors.email && <span class="span">  El formato del correo no es valido</span>}
            <label htmlFor="password">Contraseña </label>
            <div className='relativePosition'>
              <input type="password" name="password" id="password" value={password} 
              {...register('password',{required:true})}
              onInput={(ev) => {setPassword(ev.target.value)}} 
              /> 
              <i id="iconoPassword" className="fa fa-eye iconoOcultarPassword" onClick={() => {verPassword()}}> </i>
             </div>
             {errors.password?.type === 'required' && <span class="span">Este campo es requerido</span>}
            <div className="botones">
              <button type='submit' className="btn btn_morado" id="btnIniciar">Iniciar sesión</button>
              <Link className="btn btn_bco" to="/crearcuentaSinSimulacion">
                Crear cuenta
              </Link>
            </div>
            <Link to="/recuperapass" className="link">
              Olvidé mi contraseña
            </Link>
          </form>
        </div>
        <div className="col6 cont_img-index">
          <img src={altColors ? login_imgRemax : login} alt="Login" />
        </div>
      </div>
    </>
  );
}