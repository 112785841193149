import '../../App.css'
import { InfoCotizacionXL,InfoDefault } from '../../components/Cards'
import { useState, useEffect } from 'react'
import { Cookies } from 'react-cookie'
import { get } from '../../utils/http'
import { SinCotizacion, ConCotizacionP1, ConCotizacionP2 } from '../../components/Msjs'
import * as ReactDOM from 'react-dom';
import { ModalLoadingGeneral } from '../../components/Modals'

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------ Pantalla donde se muestran las cotizaciones del usuario ----------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function Cotizaciones(){
    const [isOpenModalLoading, setIsOpenModalLoading] = useState(true)
    const cookies = new Cookies()
    localStorage.removeItem("idResPaso1Cuestionario")
    localStorage.removeItem("BancoLS1")
    localStorage.removeItem("BancoLS2")
    localStorage.removeItem("idResPaso1SeleccionarBancos")
    localStorage.removeItem("verBancos")
    localStorage.removeItem("plazoParaBancosSeleccionados")

    localStorage.removeItem("monto")
    localStorage.removeItem("plazoVal")
    localStorage.removeItem("montoPrestar")
    localStorage.removeItem("enganche")
    localStorage.removeItem("tasa")
    localStorage.removeItem("mensualidad")
    localStorage.removeItem("sinRespuestaBuro")

    localStorage.removeItem("statuscodepaso2")

    localStorage.removeItem("idCuestionarioSolicitud");
    localStorage.removeItem("idResPrecalifica");

    
    let cotizaciones 
    const info = async() =>{
        let data = await get('userCotizaciones', cookies.get('id'))
        if(data.statucode === 200){
            let infoCotizaciones=[]
            for(let num=0; num<data.cotizaciones.length; num++){
                let datosIdResPaso0 = await get ('ObtIdCuestionarioPorIdResPaso1', parseInt(data.cotizaciones[num].id))
                let responseBtBuro = await get('obtDatosPaso2PorIdResPaso1',parseInt(data.cotizaciones[num].id))
                
                if(datosIdResPaso0.statucode === 200 ){
                    if(responseBtBuro.statuscode !== 0){
                        let dataBLC
                        let enviarTotalBancos = 0
                        if(datosIdResPaso0.idCuestionario !== 0){
                            dataBLC = await get('userBancos', datosIdResPaso0.idCuestionario)
                            if(dataBLC.statucode === 200){
                                enviarTotalBancos = dataBLC.bancos.length
                            }
                        }
                        
                        infoCotizaciones.push({
                            nombre: 'Cotización '+[num+1],
                            fechaCrecion : data.cotizaciones[num].fechaCrecion,
                            valorPropiedad : data.cotizaciones[num].valorPropiedad,
                            plazo : data.cotizaciones[num].plazo,
                            montoSolicitado : data.cotizaciones[num].montoSolicitado,
                            enganche : data.cotizaciones[num].enganche,
                            tasa : data.cotizaciones[num].tasaPromedio,
                            cuestionarioId: datosIdResPaso0.idCuestionario,
                            idResPaso1: data.cotizaciones[num].id,
                            longitudBancos: enviarTotalBancos,
                            statusBuro: responseBtBuro.statuscode
                        })
                    }
                }
            }

            if(infoCotizaciones.length === 0 ){
                // <---------- Mensaje cuando no hay cotizaciones ---------->
                cotizaciones = <InfoDefault nombre='cotizaciones'/>
                const root = ReactDOM.createRoot(
                    document.getElementById('mostrarSinCotizaciones')
                );
                root.render(<SinCotizacion/>);

                setIsOpenModalLoading(false)
            }else{
                // <---------- Tarjetas con la información de las cotizaciones ---------->
                cotizaciones = infoCotizaciones.map((contizacion) => <InfoCotizacionXL
                                                                        nombre={contizacion.nombre}
                                                                        valorPropeidad={contizacion.valorPropiedad}
                                                                        plazo={contizacion.plazo}
                                                                        fecha={contizacion.fechaCrecion}
                                                                        montoSolicitado={contizacion.montoSolicitado}
                                                                        enganche={contizacion.enganche}
                                                                        tasa={contizacion.tasa}
                                                                        cuestionarioId={contizacion.cuestionarioId}
                                                                        longitudBancos={contizacion.longitudBancos}
                                                                        idResPaso1={contizacion.idResPaso1}
                                                                        statusBuro={contizacion.statusBuro}
                                                                        key={contizacion.nombre} />)
            
                const root = ReactDOM.createRoot(
                    document.getElementById('todasCotizaciones'),
                ); 
                root.render(cotizaciones);
                
                // <---------- Titulo de la pantalla ---------->
                const root2 = ReactDOM.createRoot(
                    document.getElementById('cont-CotizacionesP1'),
                ); 
                root2.render(<ConCotizacionP1/>);
                
                // <---------- Botón inferior para una nueva cotización ---------->
                const root3 = ReactDOM.createRoot(
                    document.getElementById('cont-CotizacionesP2'),
                ); 
                root3.render(<ConCotizacionP2/>);

                setIsOpenModalLoading(false)
            }
        }
    } 

    useEffect( () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        info()    
    },[])

    return(
        <>
            <div className=" cont_Info">
                <div id="mostrarSinCotizaciones"></div>
                <div className="Resum cont-Cotizaciones" id="cont-CotizacionesP1"></div>
                <div className="cont_flex con_flex_wrap" id="todasCotizaciones"></div>

                <div className="cont_flex con_flex_wrap" id="mostrarMostrarBancos"></div>
                <div className="Resum cont-Cotizaciones" id="cont-CotizacionesP2"></div>
                
                <ModalLoadingGeneral open = {isOpenModalLoading} ></ModalLoadingGeneral>
            </div>
        </>
    )
}
