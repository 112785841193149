import "../App.css";
import "../sass/style.css";
import isotipo_blanco from "../images/isotipo-blanco.svg";
import { Link } from "react-router-dom";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------- Footer cuando el usuario si esta logueado ------------------------------------------>
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function FooterHome(){
    return(
    <>
        <footer className="cont_responsive">
            <div className="copyright">
                <a href="#/" className="isotipo" target="_blank"><img className="isotipo" src={isotipo_blanco} alt="logo"/></a>
                <li> <Link to="/AvisoPrivacidadLogueado" className="link">Aviso de privacidad</Link></li>
                <li> <Link to="/TerminosCondicionesLogueado" className="link">Términos y condiciones</Link></li>
                <p>© 2023. Saturn5 SAPI de CV</p>
            </div>
        </footer>
    </>
)}