import Cookies from 'universal-cookie'
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import "../../App.css";
import login from "../../images/login.jpg";
import { useDataPublic,useSetDataPublic } from "../../providers/dataPublic/PublicProvider";
import { ruleEmail, ruleContraseña } from "../../assets/validateRules";
import { post, put } from "../../utils/http";
import { actualizarEstatusInvitacion } from "../../utils/global_functions";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------------------------- Pantalla: Crear cuenta sin simulación --------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function CrearCuentaSinSimulacion() {
  const {register,formState:{errors},handleSubmit} = useForm()
  const {correo,statuscode} = useDataPublic()
  const actionContext = useSetDataPublic()
  const [emailR,setEmail] = useState(correo ? correo: '')
  const [passwordR,setPassword] = useState('')
  const cookie = new Cookies()
  const [validarReglaContraseña, setvalidarReglaContraseña] = useState()
  
  const signUp = async() => {
    if (validarReglaContraseña === true) {
      let data = {
        "usaurio":{
          "correo":emailR,
          "nombre":" ",
          "paterno":" ",
          "materno":" ",
          "idSocios":(localStorage.getItem("idSocioHash") != null) ? localStorage.getItem("idSocioHash") : null
        },
        "password":{
          "password1":passwordR,
        }
      }

      let response  = await post("newUser",data) 
      actionContext({statuscode: response.statuscode === undefined ? response.status: response.statuscode })
      if(response.statuscode === 200){
        let timerInterval
        Swal.fire({
          title: 'Iniciando sesión',  
          color: '#9C36B2',
          timer: 20000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft()
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        }).then((result) => {
        })
        signIn()
      }
    } 
  }

  const signIn = async() => {
    let data = {
      "usaurio": {
        "correo": emailR
      },
      "password": {
        "password1": passwordR
      }
    }

    let response = await post("login",data)
    if(response.statuscode === 200){
      cookie.set('session',true,{path:'/'})
      cookie.set('nombre',response.nombre,{path:'/'})
      cookie.set('correo',response.correo,{path:'/'})
      cookie.set('id',response.id,{path:'/'})
      //Una vez que se completo el registro borramos el hash
      localStorage.removeItem("idSocioHash");
      //*Actualizamos el estatus de la invitacion, ya que se ha logueado y registrado correctamente
      if(localStorage.getItem("idInvitacionHash") != null){
        //let params = {idInvitacion: parseInt(localStorage.getItem("idInvitacionHash")), estatus:2};
        //let response = await put("actualizaEstatusInvitacion", params);
        //if(response.statuscode === 200){
        //  localStorage.removeItem("idInvitacionHash");
        //  console.log("estatus actualizado");
        //}
        actualizarEstatusInvitacion(localStorage.getItem("idInvitacionHash"), 3);
      }
      window.location.href='./home'
    }else{
      Swal.fire({
        confirmButtonColor: '#9C36B2',
        title: 'Usuario y/o contraseña erronea!',
        text: 'Verifica que la cuenta y la contraseña sea correcta',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      })
    }
  }

  function verPassword() {
    var x = document.getElementById("password");
    var icono = document.getElementById("iconoPassword");
    if (x.type === "password") {
      x.type = "text";
      icono.className = 'fa fa-eye-slash iconoOcultarPassword'
    } else {
      x.type = "password";
      icono.className = 'fa fa-eye iconoOcultarPassword'
    }
  }

  useEffect( () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },[])

  return (
    <>
      <div className="cont_flex">
        <div className="col6">
          <h1>Bienvenido a Saturn5</h1>
          <p>
            Crea tu cuenta y comienza a hacer uso de los beneficios de
            pertenecer a nuestra comunidad.
          </p>
          <form onSubmit={handleSubmit(signUp)}>
            {errors.apMaterno?.type === 'required' && <span class="span">Este campo es requerido</span>}
            <label htmlFor="email">Correo electrónico</label>   
            <input type="email" id="email" name="email" value={emailR} onInput={(ev) => {setEmail(ev.target.value)}} 
              {...register('email',{required:true, validate:ruleEmail})}
            />
            {errors.email?.type === 'required' && <span class="span">Este campo es requerido</span>}
            {errors.email && <span class="span">  El formato del correo no es valido</span>}
            {statuscode === 400 && <span class="span">Correo ya utilizado</span>}
            <label htmlFor="password">Contraseña</label>
            <div className='relativePosition'>
            <input type="password" id="password" name="password" value={passwordR} onInput={(ev) => {
              let tempPASSWWORD = ev.target.value
              setvalidarReglaContraseña(ruleContraseña(tempPASSWWORD))
              setPassword(tempPASSWWORD)

            }}
              {...register('password',{required:true})}
            />
              <i id="iconoPassword" className="fa fa-eye iconoOcultarPassword" onClick={() => {verPassword()}}> </i>
            </div>
            {validarReglaContraseña === false && <small class="span"> Ingresar contraseña con al menos una mayúscula, un número, un caracter especial y mínimo 8 caracteres.</small>}
            {errors.password?.type === 'required' && <span class="span">Este campo es requerido</span>}
            <div className="botones">
              <button type="submit" className="btn btn_morado" >Crear cuenta</button>
              {statuscode === 400 && <Link to="/recuperapass" className="link">Olvidé mi contraseña</Link>}
            </div>
            <small>
              Tus datos personales son muy importantes para nosotros y tu
              seguridad es nuestra prioridad.
              <br /> Consulta nuestro &nbsp;
              <Link to="/avisoDePrivacidad" className="link">
                Aviso de privacidad
              </Link>
            </small>
          </form>
        </div>
        <div className="col6 cont_img-index">
        <img src={login} alt="Login" />
        </div>
      </div>
    </>
  );
}