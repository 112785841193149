import '../../App.css'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Cookies from 'universal-cookie'
import { useEffect, useState } from 'react'
import { get } from '../../utils/http'

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------------------- Pantalla donde se muestra el aviso de privacidad --------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function AvisoPrivacidad() {
  const cookie = new Cookies()
  let loggin=false

  const [msg,setMsg] = useState()
  const obtAvisoPrivacidadCondiciones = async() =>{
    let data = await get('obtValorConfiguracionPorId', 1)
    if(data.statuscode === 200){
      setMsg(data.configuraciones.valor)
    }
  }
  
  useEffect( () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    obtAvisoPrivacidadCondiciones()
  },[])
  
  return (
    <>
      {cookie.get('session') ? loggin===true : <Header/>}
      <div id="mostrarTexto" className="cont_msgGenerales" dangerouslySetInnerHTML={{__html: msg}}>
      </div> 
      {cookie.get('session') ? loggin===true : <Footer/>}
    </>
  );
}
