import "../../App.css";
import img_olvidePass from "../../images/olvidePassword.jpg";
import { Link } from "react-router-dom";
import { useState,useEffect } from "react";
import Cookies from 'universal-cookie'
import { useDataPublic,useSetDataPublic } from "../../providers/dataPublic/PublicProvider";
import { useForm } from 'react-hook-form'
import { get, post, put } from "../../utils/http";
import { ModalLoadingCambiarContraseña } from '../../components/Modals'
import { ruleContraseña } from "../../assets/validateRules";
import Swal from "sweetalert2";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------------------- Pantalla: Validar contraseña mediante url con hash -------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function ValidarPass() {
  const {register,formState:{errors},handleSubmit} = useForm()
  const {correo} = useDataPublic()
  const actionContext = useSetDataPublic()
  const [emailR,setEmail] = useState(correo ? correo: '')
  const [passwordR,setPassword] = useState('')
  const cookie = new Cookies()
  const [isOpenModalLoadingCambiarContraseña, setIsOpenModalLoadingCambiarContraseña] = useState(true)
  const [validarReglaContraseña, setvalidarReglaContraseña] = useState()

  const validarHash = async() => {
    let URLactual = window.location.search;
    URLactual = URLactual.substring(1,URLactual.length)
    let validarHashLink = await get('validarHash',URLactual)
    if (validarHashLink.statuscode===200) {
      setIsOpenModalLoadingCambiarContraseña(false)
      setEmail(validarHashLink.correo)
    }else{
      setIsOpenModalLoadingCambiarContraseña(true)
    }   
  }

  useEffect( () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    validarHash()    
  },[])

  const signUp = async() => {
    if (validarReglaContraseña === true) {
      setIsOpenModalLoadingCambiarContraseña(true)
      let userData = {
        email: emailR,
        password: passwordR
      }    

      let response  = await put("cambiarPasswordUsuario",userData) 
      actionContext({statuscode: response.statuscode === undefined ? response.status: response.statuscode })
      if(response.statuscode === 200){
        setIsOpenModalLoadingCambiarContraseña(false)
        Swal.fire({
          confirmButtonColor: '#9C36B2',
          title: 'Su contraseña ha sido modificada con éxito.',
          icon: 'success',
          showCancelButton: true,
          cancelButtonText: 'Cerrar',
          confirmButtonText: 'Iniciar sesión'
        }).then((result) => {
          if (result.isConfirmed) {
            signIn()
          }
      })
        
      }else{
        setIsOpenModalLoadingCambiarContraseña(false)
        Swal.fire({
          confirmButtonColor: '#9C36B2',
          title: 'Algo ha salido mal, por favor intentalo más tarde.',
          icon: 'warning',
          confirmButtonText: 'Aceptar'
        })
      }
    }
  } 

  const signIn = async() => {
    let data = {
      "usaurio": {
        "correo": emailR
      },
      "password": {
        "password1": passwordR
      }
    }

    let response = await post("login",data)
    if(response.statuscode === 200){
      cookie.set('session',true,{path:'/'})
      cookie.set('nombre',response.nombre,{path:'/'})
      cookie.set('correo',response.correo,{path:'/'})//TODO: Posiblemente correo ath o jwt 
      cookie.set('id',response.id,{path:'/'})
      let timerInterval
      Swal.fire({
        title: 'Iniciando sesión',  
        color: '#9C36B2',
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
          const b = Swal.getHtmlContainer().querySelector('b')
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft()
          }, 100)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      }).then((result) => {
      })
      window.location.href='./home'
    }else{
      Swal.fire({
        confirmButtonColor: '#9C36B2',
        title: 'Usuario y/o contraseña erronea!',
        text: 'Verifica que la cuenta y la contraseña sea correcta',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      })
    }
  }

  function verPassword() {

    var x = document.getElementById("password");
    var icono = document.getElementById("iconoPassword");
    if (x.type === "password") {
      x.type = "text";
      icono.className = 'fa fa-eye-slash iconoOcultarPassword'
    } else {
      x.type = "password";
      icono.className = 'fa fa-eye iconoOcultarPassword'
    }
  }

  return (
    <>
      <div className="cont_flex">
        <div className="col6">
          <h1>Escribe tu nueva contraseña</h1>
          <p>
            Ingresa una nueva contraseña y vuelve a hacer uso de los beneficios de
            pertenecer a nuestra comunidad.
          </p>
          <form onSubmit={handleSubmit(signUp)}>         
            {errors.apMaterno?.type === 'required' && <span class="span">Este campo es requerido</span>}
            <label htmlFor="email">Correo electrónico</label>   
            <input type="email" id="email" name="email" value={emailR} readOnly onInput={(ev) => {setEmail(ev.target.value)}} 
            />
            <label htmlFor="password">Contraseña</label>
            <div className='relativePosition'>
              <input type="password" id="password" name="password" value={passwordR} onInput={(ev) => {
                let tempPASSWWORD = ev.target.value
                setvalidarReglaContraseña(ruleContraseña(tempPASSWWORD))
                setPassword(tempPASSWWORD)
              }}
                {...register('password',{required:true})}
              />
              <i id="iconoPassword" className="fa fa-eye iconoOcultarPassword" onClick={() => {verPassword()}}> </i>
             </div>
            {validarReglaContraseña === false && <small class="span"> Ingresar contraseña con al menos una mayúscula, un número, un caracter especial y mínimo 8 caracteres.</small>}
            {errors.password?.type === 'required' && <span class="span">Este campo es requerido</span>}
            <div className="botones">
              <button type="submit" className="btn btn_morado" >Cambiar contraseña</button>
            </div>
            <small>
              Tus datos personales son muy importantes para nosotros y tu
              seguridad es nuestra prioridad.
              <br /> Consulta nuestro &nbsp;
              <Link to="/avisoDePrivacidad" className="link">
                Aviso de privacidad
              </Link>
            </small>
          </form>
        </div>
        <div className="col6 cont_img-index">
        <img src={img_olvidePass} alt="Login" />
        </div>
        <ModalLoadingCambiarContraseña open = {isOpenModalLoadingCambiarContraseña} close = { () => setIsOpenModalLoadingCambiarContraseña(false)} ></ModalLoadingCambiarContraseña>
      </div>
    </>
  );
}