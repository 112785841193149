import '../../App.css'
import CalculadoraWeb from '../generales/CalculadoraWeb'
import { get,put } from '../../utils/http'
import { useState, useEffect,useRef } from 'react'
import { Cookies } from 'react-cookie'
import { Link } from "react-router-dom";
import { ruleTelefono, ruleRFC, ruleNacimiento} from "../../assets/validateRules";
import { useForm } from 'react-hook-form'
import Swal from "sweetalert2";
import { ModalLoadingGeneral } from '../../components/Modals'


// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------------------------- Pantalla con datos básicos del usuario -------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function MisDatos()
{
    const [isOpenModalLoading, setIsOpenModalLoading] = useState(true)    
    const cookies = new Cookies()
    const [infoUser,setInfoUser] = useState({})
    const [edit1,setEdit1] = useState(true)
    const [edit2,setEdit2] = useState(true)
    const [flagFecha,setFlagFecha] = useState(false)
    const inputName = useRef()
    const inputTel = useRef()
    const {register,formState:{errors}} = useForm()
    const [validarReglaRFC, setValidarReglaRFC] = useState()
    const [validarLongitudTelefono, setValidarLongitudTelefono] = useState()
    const [validarEdadLimite, setValidarEdadLimite] = useState()
    const [edadActual, setEdadActual] = useState()
    const [validarReglaNacimiento, setValidarReglaNacimento] = useState()

    localStorage.removeItem("idCuestionarioSolicitud");
    localStorage.removeItem("idResPrecalifica");



      
    const DateInput = (event) => {
        console.log("entro", event)
        let input = event;
    
        // Remover las diagonales existentes
        //input = input.replace(/\//g, '');
        input = input.replace(/\D/g, '');
      
        // Insertar las diagonales en la posición correcta
        if (input.length > 2 && input.length < 5) {
          input = input.slice(0, 2) + '/' + input.slice(2);
        } else if (input.length > 4) {
          input = input.slice(0, 2) + '/' + input.slice(2, 4) + '/' + input.slice(4, 8);
        }
      
        const regex = /^\d{0,2}(\/\d{0,2}(\/\d{0,4})?)?$/;
        
        if (regex.test(input)) {
          console.log("El formato de fecha es válido.");
          // Realizar acciones adicionales si la fecha es válida
        } else {
          console.log("El formato de fecha no es válido.");
          // Realizar acciones adicionales si la fecha no es válida
        }
        console.log("salir", input);
        console.log( "valor" , infoUser.fNacimiento)
        setInfoUser(()=> ({...infoUser,fNacimiento: input}))
      
        setValidarReglaNacimento(ruleNacimiento(input))
        
        console.log("inpt" + input);
      };
  

    let hoy = new Date();
    
    let mesMinMax 
    if((hoy.getMonth()+1).toString().length === 1){
      mesMinMax = "0"+ (hoy.getMonth()+1)
    } else {
      mesMinMax = (hoy.getMonth()+1)
    }

    let diaMinMax 

    if((hoy.getDate()).toString().length===1){
      diaMinMax = "0"+ (hoy.getDate())
    } else {
      diaMinMax = (hoy.getDate())
    }
    
    let edadMin = hoy.getFullYear()-18 + "-" + mesMinMax.toString() + "-" +diaMinMax.toString()
    let edadMax = '1935' + "-" + mesMinMax.toString() + "-" + diaMinMax.toString()


    const info = async() =>{
        let data = await get('infoUser',cookies.get('correo'))
        if(data.statuscode === 200){
            
            if(data.user.fNacimiento !== null){
                let fecha = new Date(data.user.fNacimiento)                
                if(fecha.getMonth()+1 < 10 && fecha.getDate() < 10){ 
                    data.user.fNacimiento = fecha.getFullYear()+'-0'+ (fecha.getMonth()+1)+'-0'+fecha.getDate()
                }else if(fecha.getDate() < 10){
                    data.user.fNacimiento = fecha.getFullYear()+'-'+ (fecha.getMonth()+1)+'-0'+fecha.getDate()
                } else if (fecha.getMonth()+1 < 10) {
                    data.user.fNacimiento = fecha.getFullYear()+'-0'+ (fecha.getMonth()+1)+'-'+fecha.getDate()
                } else {
                    data.user.fNacimiento = fecha.getFullYear()+'-'+ (fecha.getMonth()+1)+'-'+fecha.getDate()
                }

                let fechaNF = data.user.fNacimiento.substr(8,2)+ "/" + data.user.fNacimiento.substr(5,2) + "/" + data.user.fNacimiento.substr(0,4 );
                data.user.fNacimiento = fechaNF;
      
                console.log(fechaNF);

            }
            setIsOpenModalLoading(false)
        } else {
            setIsOpenModalLoading(false)
        }
        
        setInfoUser(() =>({
            ...infoUser,
            ...data.user
        }))
    }

    useEffect( () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        info()    
    },[])

    const editar = (op) => {
        op === 1 ? setEdit1(false) : setEdit2(false)
        !flagFecha || infoUser.fNacimiento ? setFlagFecha(true) : setFlagFecha(false)
     }

    const handleSubmit = async(ev,op) =>{
        ev.preventDefault()            
        op === 1 ? setEdit1(true) : setEdit2(true)
        // if (validarReglaRFC===true || validarLongitudTelefono===true) {
            const fechaArray = infoUser.fNacimiento.split('/');
            const fechaFormateada = `${fechaArray[2]}-${fechaArray[1]}-${fechaArray[0]}`;
            console.log(infoUser);
            infoUser.fNacimiento = fechaFormateada;

            console.log(infoUser);
            let update = await put('updateUser',infoUser)
            if (update.statuscode ===200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Datos actualizados',
                    confirmButtonColor: '#9C36B2',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar'
                })
                
                let Rfnacimiento = infoUser.fNacimiento.substr(8,2)+ "/" + infoUser.fNacimiento.substr(5,2) + "/" + infoUser.fNacimiento.substr(0,4 );
                console.log(Rfnacimiento);
                infoUser.fNacimiento = Rfnacimiento;

            }else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al actualizar',
                    text: 'Inténtelo de nuevo',
                    confirmButtonColor: '#9C36B2',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar'
                })
            }
            
        // } else {
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Error al actualizar',
        //         text: 'Inténtelo de nuevo',
        //         confirmButtonColor: '#9C36B2',
        //         showCancelButton: false,
        //         confirmButtonText: 'Aceptar'
        //     })
        // }
        
    }

    const info2 = async() =>{
        let data = await get('infoUser',cookies.get('correo'))
            if(data.statuscode === 200){
            if(data.user.fNacimiento !== null){
                let fecha = new Date(data.user.fNacimiento)            
                if(fecha.getMonth()+1 < 10 && fecha.getDate() < 10){ 
                    data.user.fNacimiento = fecha.getFullYear()+'-0'+ (fecha.getMonth()+1)+'-0'+fecha.getDate()
                }else if(fecha.getDate() < 10){
                    data.user.fNacimiento = fecha.getFullYear()+'-'+ (fecha.getMonth()+1)+'-0'+fecha.getDate()
                } else if (fecha.getMonth()+1 < 10) {
                    data.user.fNacimiento = fecha.getFullYear()+'-0'+ (fecha.getMonth()+1)+'-'+fecha.getDate()
                } else {
                    data.user.fNacimiento = fecha.getFullYear()+'-'+ (fecha.getMonth()+1)+'-'+fecha.getDate()
                }
                
                let fechaNF = data.user.fNacimiento.substr(8,2)+ "/" + data.user.fNacimiento.substr(5,2) + "/" + data.user.fNacimiento.substr(0,4 );
                data.user.fNacimiento = fechaNF;
      
                console.log(fechaNF);
            }
        }
        setInfoUser(() =>({
            ...infoUser,
            ...data.user
        }))
    }

    function CancelarMostrar() {
        setEdit2(true)
        setValidarLongitudTelefono(true)
        info2()
    }

    function CancelarMostrar2() {
        setEdit1(true)
        setValidarReglaRFC(true)
        info2()
    }

    return(
        <>
            <div className=" cont_Info">
                <Link to='/home' className="link linkGrande back">  {'<'} Regresar a Mi tablero</Link>
                <br></br>
                <h1 className="titt">Mis datos</h1>
                <p>Asegúrate de proporcionar la información correcta, tendrás la opción de regresar y editarla posteriormente.</p>

                <div className="Resum">
                     <div className="header">
                        <h3>Datos personales</h3>
                        <a href='#/' className="link" onClick={ev => {
                            inputName.current.focus()
                            editar(1)
                        }}>Editar <i className="fa fa-pencil"></i></a>
                    </div>
                    <div className="cont_flex cont-form">
                        <form onSubmit={ (ev) => {handleSubmit(ev,1)}}>
                            <div className="col3 campo-form">
                                <label>Nombre </label>
                                <input type="text"  ref={inputName} value={infoUser.nombre} readOnly={edit1} onChange={ev => {
                                    let tempNombre = ev.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "");//Reemplaza todos los numeros por vacio 
                                    setInfoUser(() => ({ ...infoUser, nombre: tempNombre }));
                                }}/>
                            </div>
                            <div className="col3 campo-form">
                                <label>Apellido Paterno</label>
                                <input type="text" value={infoUser.paterno} readOnly={edit1} onChange={ev => {
                                    let tempPaterno = ev.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "");//Reemplaza todos los numeros por vacio 
                                    setInfoUser(()=> ({...infoUser,paterno : tempPaterno}))
                                    }}/>
                            </div>
                            <div className="col3 campo-form">
                                <label>Apellido Materno</label>
                                <input type="text" value={infoUser.materno} readOnly={edit1} onChange={ev => {
                                    let tempMaterno = ev.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "");//Reemplaza todos los numeros por vacio 
                                    setInfoUser(()=> ({...infoUser,materno : tempMaterno}))
                                    }}/>
                            </div>
                            <div className="col3 campo-form">
                                <label>Fecha de nacimiento</label>
                                <input type="text" min={edadMax} max={edadMin} readOnly={edit1} value={infoUser.fNacimiento}  
                                onInput={ev => {DateInput(ev.target.value)}}

                                    // let tempFecha = ev.target.value
                                    // setInfoUser(()=> ({...infoU  ser,fNacimiento : tempFecha}))
                                    
                                //}}
                                />
                                {validarReglaNacimiento === false && <small class="span"> Ingresar en el siguiente formato dd/mm/aaaa </small>}
                            </div>
                            <div className="col3 campo-form">
                                <label>RFC</label>
                                <input type="text" style={{ textTransform: "uppercase" }} value={infoUser.rfc} readOnly={edit1} maxLength="13" onChange={ev => {
                                    let tempRFC = ev.target.value
                                    setValidarReglaRFC(ruleRFC(tempRFC))

                                    setInfoUser(()=> ({...infoUser,rfc : tempRFC}))
                                    }}/>
                                    {validarReglaRFC === false && <small class="span"> El formato del RFC no es valido</small>}
                            </div>
                           <div className='dosCuttonFormularios'>
                                {!edit1 && <button type="submit" className="btn btn_morado" >Guardar Cambios</button>}
                                {!edit1 && <button className="btn btn_bco" onClick={() => {CancelarMostrar2()}}>Cancelar</button>}
                            </div>
                        </form>
                    </div>
                </div>
                <div className="Resum">
                    <div className="header">
                        <h3>Datos de contacto</h3>
                        <a href="#/" className="link" onClick={() => {
                            inputTel.current.focus()
                            editar(2)
                        }}>Editar <i className="fa fa-pencil"></i></a>
                    </div>
                    <div className="cont_flex cont-form">
                        <form onSubmit={ ev => handleSubmit(ev,2)}>
                            <div className="col3 campo-form">
                                <label>Correo electrónico</label>
                                <input type="email" value={infoUser.correo} readOnly
                                 onChange={ev => {
                                    let tempCorreo = ev.target.value 
                                    setInfoUser(()=> ({...infoUser,correo : tempCorreo}))
                                    }}/>
                                    {!edit2  &&<small>
                                        Esta información solo es visible. No se puede editar.
                                    </small> }
                            </div>
                            <div className="col3 campo-form">
                                <label>Teléfono / Celular</label>
                                <input type="tel" size="10" maxLength={10} name="telefono" id="telefono" placeholder="Teléfono a 10 dígitos"
                                    autoComplete="off"ref={inputTel} value={infoUser.telefono} readOnly={edit2} onChange={ev => {
                                    let tempTelefono = ev.target.value 
                                    if(isNaN(tempTelefono)==false)
                                    {
                                        if (tempTelefono.length<10) {
                                            setInfoUser(()=> ({...infoUser,telefono : tempTelefono}))
                                            setValidarLongitudTelefono(false)   
                                        }else{
                                            setInfoUser(()=> ({...infoUser,telefono : tempTelefono}))
                                            setValidarLongitudTelefono(true)
                                        }
                                    }
                                    }}
                                    onFocus={ev => {
                                        let tempTelefono = ev.target.value   
                                        
                                        if(isNaN(tempTelefono)==false)
                                        {
                                            if (tempTelefono.length<10) {
                                                setInfoUser(()=> ({...infoUser,telefono : tempTelefono}))
                                                setValidarLongitudTelefono(false)   
                                            }else{
                                                setInfoUser(()=> ({...infoUser,telefono : tempTelefono}))
                                                setValidarLongitudTelefono(true)
                                            }
                                        }
                                        }}
                                    />
                                    {validarLongitudTelefono===false && <span class ="span">Ingresar  10 digitos</span>}                                             
                            </div>
                            <br />
                            <div className='dosCuttonFormularios'>
                                {!edit2 && <button type="submit" className="btn btn_morado" >Guardar Cambios</button>}
                                {!edit2 && <button className="btn btn_bco" onClick={() => {CancelarMostrar()}}>Cancelar</button>}
                            </div>
                        </form>
                    </div>
                </div>
                <ModalLoadingGeneral open = {isOpenModalLoading} ></ModalLoadingGeneral>
            </div>
        </>
    )
}

export function Simulador(){
    return(
        <>
            <div className=" cont_Info">
                <Link to='/cotizaciones' className="link linkGrande back"> {'<'} Regresar a mis ofertas hipotecarias</Link> 
                <h1 className="titt">Simula tu crédito</h1>
                <p className="col6"><b> Nota. Ten en cuenta que los cálculos se basan en el promedio más bajo del mercado y podrían variar según tu perfil individual.</b> </p>
                <CalculadoraWeb/>
            </div>
        </>
    )
}