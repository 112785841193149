import "../App.css";
import "../sass/style.css";
import isotipo_negro from "../images/isotipo-negro.svg";
import logo_saturn from "../images/logo_saturn.svg";
import logo_morado from "../images/logo_morado.svg";
import logo_remax from "../images/FINMAX.png";
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react';
import { post, get } from '../utils/http';
// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------- Header cuando el usuario no esta logueado ------------------------------------------>
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function Header({verificar}) {

  
  /*const [logo, setLogo] = useState();
  const [mostrar, setMostar] = useState(false);
    
  let idEmpresa = localStorage.getItem("idEmpresaHash");
  //!Consultar información de la empresa
  const obtInfonEmpresa = async() =>{
    if(idEmpresa > 0){
      let empresa = await get("getEmpresaSocio", idEmpresa);
      if(empresa.statuscode === 200){
        console.log(empresa);
        //Para generar la imagen
        const datosBinarios = atob(empresa.file.fileContents);
        const arrayDeBytes = new Uint8Array(datosBinarios.length);
        for (let i = 0; i < datosBinarios.length; i++) {
          arrayDeBytes[i] = datosBinarios.charCodeAt(i);
        }
        const imagenBlob = new Blob([arrayDeBytes], { type: "image/png" });
        const imagenUrl = URL.createObjectURL(imagenBlob);
        // La solicitud fue exitosa, establecer la URL de la imagen en el estado
        console.log(imagenUrl);
        setLogo(imagenUrl);
      }
      else{
        setMostar(false);
      }
    }
    else{
      setMostar(false);
    }
  }*/

  // Efecto para desplazar al inicio de la página
  //(() => {
    //if(verificar){
  //    obtInfonEmpresa();
      //setMostar(true);
    //}
  //  if (mostrar) {
  //    setLogo(logo); // Cambia el logo a original
  //  } else {
  //    setLogo(logo_saturn); // Cambia el logo a saturn si mostrar es falso
  //  }
  //}, [mostrar]);

  return (
    <>
      <div className="header_responsive">
        <Link to="/" className="isotipo"> <img src={logo_morado} alt='saturn 5'/> </Link>
        <Link to='/calculadora' className="link"> Simula tu crédito </Link> 
        <Link to='/' className="link"> Iniciar Sesión </Link>
      </div>

      <header>
        <div>
          <Link to="/" className="isotipo"> <img src={isotipo_negro} alt='saturn 5'/> </Link>
          <ul className="redes">
            <li> <a href="https://www.instagram.com/saturn5mx/" target="_blank" rel="noreferrer"> <i className="fa fa-instagram"></i> </a> </li>
            <li> <a href="https://www.facebook.com/saturn5mx" target='_blank' rel="noreferrer"> <i className="fa fa-facebook"></i> </a> </li>
            <li> <a href="https://www.linkedin.com/company/saturn5/" target='_blank' rel="noreferrer"> <i className="fa fa-linkedin"></i> </a> </li>
          </ul>
        </div>

        {<Link to="/" > <img src={logo_saturn} alt='saturn 5'/> </Link>}
        {/*mostrar ? (
        <Link to="/" > <img src={logo} alt='saturn 5'/> </Link>
        ) : (
          <Link to="/">
            <img src={logo_saturn} alt="saturn 5" />
          </Link>
        )*/}
        <ul className="menu">
          <li> <a  href="https://saturn5.mx/#section-101-17" target='_blank' rel="noreferrer">Contacto</a> </li>
          <li> <Link to='/calculadora' className="btn btn_morado"> Simula tu crédito </Link> </li>
          <li> <Link to='/' className="btn btn_bco"> Login </Link> </li>
        </ul>
      </header>
    </>
  );
}
