import '../App.css'
import { Link } from 'react-router-dom'

// <-------------------------------------------------------------------------------------------------------------------------------->
// <----------------------------------------- Menú lateral cuando el usuario esta logueado ----------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function NavHome({active}){
    return(
        <>
            <div className=" menu_lateral">
                <ul>
                    <li><Link to='/home'  className={active === '01'  ? 'active':'' }><i className="fa fa-th-large"></i> Mi Tablero</Link></li>
                    <li><Link to='/misDatos'  className={active === '02'  ? 'active':'' }><i className="fa  fa-user"></i> Mis Datos</Link></li>
                    <li><Link to='/cotizaciones'  className={active === '03'  ? 'active':'' }><i className="fa  fa-file"></i> Mis Cotizaciones</Link></li>
                </ul>
                <div className="copyright">
                    <p>En caso de que tengas preguntas, dudas o necesites asesoría no dudes en contactarnos. Nos encanta atenderte a la hora que lo necesites</p>
                    <li> <Link to="/AvisoPrivacidadLogueado" className="link">Aviso de privacidad</Link></li>
                    <li> <Link to="/TerminosCondicionesLogueado" className="link">Términos y condiciones</Link></li>
                    <p>© 2023. Saturn5 SAPI de CV</p>
                </div>
            </div>
        </>
    )
}