import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import "../../App.css";
import img_registro from "../../images/registro.jpg";
import { useDataPublic,useSetDataPublic } from "../../providers/dataPublic/PublicProvider";
import { ruleCP, ruleEmail, ruleNacimiento, ruleRFC, ruleTelefono} from "../../assets/validateRules";
import { get, post, getCP} from "../../utils/http";
import { ModalCodigoSms, ModalCasiListos, ModalSabiasQue } from '../../components/Modals'
import Cookies from 'universal-cookie'

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------------- Pantalla: Paso 1 no logueado ------------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function Paso1() {
  const cookie = new Cookies()
  const {register,formState:{errors},handleSubmit} = useForm()
  const [nombre,setNombre] =useState("")
  const [apellidoP,setApellidoP] = useState("")
  const [apellidoM,setApellidoM] = useState('')
  const [rfc, setRFC] = useState("");
  const [correo,setCorreo] =useState('')
  const [nacimiento, setNacimiento] =useState()
  const [telefono, setTelefono] = useState("");
  const [ingreso,setIngreso] = useState('')
  const [direccion,setDireccion] = useState('')
  const [exterior,setExterior] = useState('')
  const [interior,setInterior] = useState('')
  const [estado,setEstado] = useState()
  const [estadoAux,setEstadoAux] = useState()
  const [municipio,setMunicipio] = useState()
  const [colonia,setColonia] =useState('')
  const [cp,setCp] = useState('')
  const {monto} = useDataPublic()
  const {plazoVal} = useDataPublic()
  const {montoPrestar} = useDataPublic()
  const {mensualidad} = useDataPublic();
  const {enganche} = useDataPublic()
  const [isOpenModalSMS, setIsOpenModalSMS] = useState(false)
  const [isOpenModalCasiListos, setIsOpenModalCasiListos] = useState(false)
  const [isOpenModalModalSabiasQue, setIsOpenModalModalSabiasQue] = useState(false)
  const {tasa} = useDataPublic()
  const actionSetDataPublic = useSetDataPublic()
  const [listColonias,setListColonias] = useState()
  const [statusCorreoExist, setstatusCorreoExist] = useState(0)
  const [rfcGenerico, setRfcGenerico] = useState(false)
  const [validarReglaRFC, setValidarReglaRFC] = useState()
  const [validarLongitudTelefono, setValidarLongitudTelefono] = useState()
  const [validarLongitudCP, setValidarLongitudCP] = useState()
  const [validarReglaCorreo, setValidarReglaCorreo] = useState()
  const [validarEdadLimite, setValidarEdadLimite] = useState()
  const [validarReglaNacimiento, setValidarReglaNacimento] = useState()
  const [edadActual, setEdadActual] = useState()
  const [estadoDisabled, setEstadoDisabled] = useState(true);
  const [delegacionDisabled, setDelegacionDisabled] = useState(true);
  const [error, setError] = useState(null);
  const cookies = new Cookies()


  const DateInput = (event) => {
      console.log(event)
      let input = event;
  
      // Remover las diagonales existentes
      //input = input.replace(/\//g, '');
      input = input.replace(/\D/g, '');
    
      // Insertar las diagonales en la posición correcta
      if (input.length > 2 && input.length < 5) {
        input = input.slice(0, 2) + '/' + input.slice(2);
      } else if (input.length > 4) {
        input = input.slice(0, 2) + '/' + input.slice(2, 4) + '/' + input.slice(4, 8);
      }
    
      const regex = /^\d{0,2}(\/\d{0,2}(\/\d{0,4})?)?$/;
      
      if (regex.test(input)) {
        console.log("El formato de fecha es válido.");
        // Realizar acciones adicionales si la fecha es válida
      } else {
        console.log("El formato de fecha no es válido.");
        // Realizar acciones adicionales si la fecha no es válida
      }
      console.log(input);
      setNacimiento(input);
      setValidarReglaNacimento(ruleNacimiento(input))
      validarEndeudamiento(input)
      console.log("inpt" + input);
    };
  


  if(cookie.get('s5_enganche') !== undefined && cookie.get('s5_mensualidad') !== undefined && cookie.get('s5_monto') !== undefined &&
  cookie.get('s5_montoPrestar') !== undefined && cookie.get('s5_plazoVal') !==undefined && cookie.get('s5_tasa') !== undefined ){
    console.log("Desde saturn5")
    localStorage.setItem("monto", cookie.get('s5_monto'))
    localStorage.setItem("plazoVal", cookie.get('s5_plazoVal'))
    localStorage.setItem("montoPrestar", cookie.get('s5_montoPrestar'))
    localStorage.setItem("enganche", cookie.get('s5_enganche'))
    localStorage.setItem("tasa", cookie.get('s5_tasa'))
    localStorage.setItem("mensualidad", cookie.get('s5_mensualidad'))

    cookies.remove('s5_monto')
    cookies.remove('s5_plazoVal')
    cookies.remove('s5_montoPrestar')
    cookies.remove('s5_enganche')
    cookies.remove('s5_tasa')
    cookies.remove('s5_mensualidad')
  } else {
    console.log("Desde webapp")
    if(monto!==null){
      if (monto!==undefined) {
        localStorage.setItem("monto", monto)
      }  
    }
    if(plazoVal!==null){
      if (plazoVal!==undefined) {
        localStorage.setItem("plazoVal", plazoVal)
      }
    }
    if(montoPrestar!==null){
      if (montoPrestar!==undefined) {
        localStorage.setItem("montoPrestar", montoPrestar)
      }
    }
    if(enganche!==null){
      if (enganche!==undefined) {
        localStorage.setItem("enganche", enganche)
      }
    }
    if(tasa!==null){
      if (tasa!==undefined) {
        localStorage.setItem("tasa", tasa)
      }
    }
    if(mensualidad!==null){
      if (mensualidad!==undefined) {
        localStorage.setItem("mensualidad", mensualidad)
      }
    }
  }

  const[modalBuroID, setModalBuroID] = useState(0)
  const[modalIdPaso1, setModalIdPaso1] = useState(0)

  function fechaCreacion() {
    let date = new Date();
    date.setHours(date.getHours() - 6);
    let isodate = date.toISOString();
    return isodate;
  }
  
  let fechCre=(fechaCreacion());
    
  let hoy = new Date();


 let mesMinMax 
  if((hoy.getMonth()+1).toString().length === 1){
    mesMinMax = "0"+ (hoy.getMonth()+1)
  } else {
    mesMinMax = (hoy.getMonth()+1)
  }

  let diaMinMax 

  if((hoy.getDate()).toString().length===1){
    diaMinMax = "0"+ (hoy.getDate())
  } else {
    diaMinMax = (hoy.getDate())
  }
  
  let edadMin = hoy.getFullYear()-18 + "-" + mesMinMax.toString() + "-" +diaMinMax.toString()
  let edadMax = '1935' + "-" + mesMinMax.toString() + "-" + diaMinMax.toString()


  const obtenerDataCp = async(cp) =>{
    try {
      let respuestaPorCP = await getCP('info_cp',cp);
      obtenerClaveIso(respuestaPorCP.response.estado);
      setEstadoAux(respuestaPorCP.response.estado);
      setMunicipio(respuestaPorCP.response.municipio)
      listColoniasCP(respuestaPorCP.response.asentamiento);
      setError(false);
      setEstadoDisabled(true);
      setDelegacionDisabled(true);
    }catch (error) {
      // Captura y maneja el error aquí
      console.error("Error al obtener datos por CP:", error);
      // Habilitar los inputs en caso de error
      setEstadoDisabled(false);
      setDelegacionDisabled(false);
      setError(true);
      setColonia(""); // Esto borrará el contenido del campo "colonia"
      setEstadoAux("");
      setMunicipio("");
    }
  }


  const obtenerClaveIso=async(estadoCP)=>{
    
    let obtenerClave = await get('obtClaveISOPorEstado', estadoCP)
    if (obtenerClave.statuscode === 200) {
      setEstado(obtenerClave.iso)  
      console.log(obtenerClave.iso);    
    }
  }

  const listColoniasCP = (colonias) =>{
    let opcionDefault = <option value='' selected="selected" disabled>Seleccione una opción... </option>

    colonias = colonias.map( (colonia) => (
      <option value={colonia} key={colonia}>{colonia}</option>
    ))
    colonias.unshift(opcionDefault)

    setListColonias(colonias)
  }

  function calcularEdad(fecha) {
    let fechaAuxFormat = fecha.substr(6,4) + "-" + fecha.substr(3,2) + "-" + fecha.substr(0,2) 

    let hoy = new Date();
    let cumpleanos = new Date(fechaAuxFormat);
    let edad = hoy.getFullYear() - cumpleanos.getFullYear();
    let m = hoy.getMonth()+1 - cumpleanos.getMonth()+1;
    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }

    console.log("edad final" + edad)
    return edad;  
  }
  

  function validarEndeudamiento(fecha) {
    console.log("dentro" + fecha);
    let edadActual = parseInt(calcularEdad(fecha))

    let validarEdad = edadActual  + parseInt(localStorage.getItem('plazoVal'));
    setEdadActual(edadActual)

    if (validarEdad>=84) {
      setValidarEdadLimite(false)
    }else{
      setValidarEdadLimite(true)
    }

  }
  

  
  async function confirmarTerminos () {
    if (validarReglaRFC ===true && validarLongitudTelefono===true && validarLongitudCP=== true && validarReglaNacimiento===true) {
      // let correoValidate = await get("infoUser",correo)
      // setstatusCorreoExist(correoValidate.statuscode)
      // if (correoValidate.statuscode !== 200) {
      setIsOpenModalCasiListos(true)
      // }  
    }
    
  }


  let data = {}
  const sendPaso1 = async () => {  
    const fechaArray = nacimiento.split('/');
    const fechaFormateada = `${fechaArray[2]}-${fechaArray[1]}-${fechaArray[0]}`;

    data.user = {
      nombre: nombre,
      paterno:apellidoP,
      materno:apellidoM,
      correo:correo,
      telefono:telefono.replaceAll('-',''),
      rfc:rfc,
      fNacimiento:fechaFormateada+'T00:00:00.047Z',
      ingresoB:parseInt(ingreso.replaceAll('$ ','').replaceAll(' MXN','').replaceAll('.','').replaceAll(',','')),
      idSocios:(localStorage.getItem("idSocioHash") != null) ? localStorage.getItem("idSocioHash") : null
    }

    data.domicilio = {
      direccion: direccion,
      nExteriors: exterior,
      nInterior: interior,
      colonia: colonia,
      ciudad: municipio,
      estado: estado,
      cp: cp,
    }

    data.cuestionario ={
      edad: calcularEdad(nacimiento),
      brutoMensual: parseInt(ingreso.replaceAll('$ ','').replaceAll(' MXN','').replaceAll('.','').replaceAll(',','')),
      proyecto: "Solictud Saturn 5: "+nombre +' '+apellidoP+' '+apellidoM,
      etapa: "Pre-Califica paso 1",
      valorCredito: parseInt(localStorage.getItem("monto"))>0 ? localStorage.getItem("monto").toString() :''
    }
  
    data.calculadora ={
      fechaCrecion: fechCre,
      valorPropiedad: parseInt(localStorage.getItem("monto")),
      plazo:parseInt(localStorage.getItem("plazoVal")),
      montoSolicitado: parseInt(localStorage.getItem("montoPrestar")),
      enganche:parseInt(localStorage.getItem("enganche")),
      tasaPromedio:parseInt(localStorage.getItem("tasa")),
      mensualidad: parseInt(localStorage.getItem("mensualidad"))
    }

    let prepaso1 = await post("prePaso1", data)
    actionSetDataPublic({nombre: nombre,ap:apellidoP, am:apellidoM, correo: correo,buroId:prepaso1.buroId, statuscode:prepaso1.statuscode })
    localStorage.setItem("nombreCalculadora", nombre)
    localStorage.setItem("apellidoPCalculadora", apellidoP)
    localStorage.setItem("apellidoMCalculadora", apellidoM)
    localStorage.setItem("correoCalculadora", correo)

    if(prepaso1.statuscode === 200){
      setIsOpenModalModalSabiasQue(false)
      setIsOpenModalSMS(true);
      setModalBuroID(prepaso1.buroId)
      setModalIdPaso1(prepaso1.idResPaso1)
      //Una vez que se completo el registro borramos el hash
      localStorage.removeItem("idSocioHash");
    } else {
        Swal.fire({
        confirmButtonColor: '#9C36B2' ,
        title: 'Algo salió mal.',
        text: 'Comunicate con nosotros o inténtalo más tarde.',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      })
      setIsOpenModalModalSabiasQue(false)
    }
  }

  function miFuncion (){
    setIsOpenModalCasiListos(false)
    setIsOpenModalModalSabiasQue(true)
    sendPaso1()
  }

  useEffect( () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },[])
  
  return (
    <>
      <div className="cont_flex wrapper margin100">
        <div className="col3">
          <h5 className="link">Te queremos conocer</h5>
          <h1>Aquí inicia el camino a tu nuevo hogar</h1>
          <p>
            Para conseguirte el mejor crédito es necesario ver tu capacidad de
            crédito
          </p>
        </div>
        <div className="col7 cont_img">
          <img src={img_registro} alt="registro" />
        </div>
      </div>
      <div className="cont_datos_registro wrapper">
        <div className="Resum">
          <ModalCasiListos open = {isOpenModalCasiListos} close = { () => miFuncion()} ></ModalCasiListos>
          <div className="header">
            <h3>Datos básicos</h3>
          </div>
          <div className="cont_flex cont-form">
            <form onSubmit={handleSubmit(confirmarTerminos)}>
              <div className="col3 campo-form">
                <label htmlFor="nombre">Nombre(s)</label>
                <input
                  type="text"
                  {...register('nombre',{required:true})}
                  autoComplete="off"
                  id="nombre"
                  name="nombre"
                  value={nombre}
                  onInput={(ev) => {setNombre(ev.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, ""))}}
                />
                {errors.nombre?.type === 'required' && <span className="span">Este campo es requerido</span>}
              </div>
              <div className="col3 campo-form">
                <label htmlFor="aPaterno">Apellido Paterno</label>
                <input
                  type="text"
                  id="aPaterno"
                  name="aPaterno"
                  {...register('aPaterno',{required:true})}
                  autoComplete="off"
                  value={apellidoP}
                  onInput={ (ev) => {setApellidoP(ev.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "")) } }
                />
                {errors.aPaterno?.type === 'required' && <span className="span">Este campo es requerido</span>}
              </div>
              <div className="col3 campo-form">
                <label htmlFor="aMaterno">Apellido Materno</label>
                <input
                  type="text"
                  id="aMaterno"
                  name="aMaterno"
                  {...register('aMaterno',{required:true})}
                  autoComplete="off"
                  value={apellidoM}
                  onInput={(ev) => {setApellidoM(ev.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, ""))}}
                />
                {errors.aMaterno?.type === 'required' && <span className="span">Este campo es requerido</span>}
              </div>
              <div className="col3 campo-form">
                <label htmlFor="rfc">RFC (con homoclave)</label>
                <input
                  type="text"
                  {...register('rfc',{required:true,  validate:ruleRFC})}
                  autoComplete="off"
                  name="rfc"
                  id="rfc"
                  size={13}
                  minLength="13"
                  maxLength="13"
                  value={rfc}
                  style={{ textTransform: "uppercase" }}
                  onInput={(ev) => {
                    setValidarReglaRFC(ruleRFC(ev.target.value))
                    if (ev.target.value === '' || ev.target.value.length < 13){
                      setRFC(ev.target.value)
                      setRfcGenerico(false)
                    }else if(ev.target.value === 'XAXX010101000' ||ev.target.value ==='XEXX010101000' ||ev.target.value ==='xexx010101000' ||ev.target.value ==='xaxx010101000'){
                      setRfcGenerico(true)                              
                    }else if(ev.target.value.length === 13){
                      setRfcGenerico(false)
                      setRFC(ev.target.value)
                    }
                    setRFC(ev.target.value)
                  }}
                />
                {/* {(rfc === ''|| rfc===null) && <small class="span">Este campo es requerido</small>} */}
                {validarReglaRFC === false && <small className="span"> El formato del RFC no es valido</small>}
                {rfcGenerico === true && <small className = "span">No usar RFC generico</small>} 
                {errors.rfc?.type === 'required' && <small className="span">Este campo es requerido</small>}

                {/* {errors.rfc && <span class="span">  <br />El formato del RFC no es valido</span>}
                {rfcGenerico === true && <span class = "span">No usar RFC generico</span>} */}
              </div>
              <div className="col3 campo-form">
                <label htmlFor="correo">Correo electrónico</label>
                <input
                  type="mail"
                  {...register('correo',{required:true, validate:ruleEmail})}
                  autoComplete="off"
                  name="correo"
                  id="correo"
                  value={correo}
                  onInput={(ev) => {
                    let tempCorreo = ev.target.value
                    //setValidarReglaCorreo(ruleEmail(tempCorreo))
                    setCorreo(tempCorreo)}}
                />
                {/* {validarReglaCorreo===false && <span class ="span">El formato del correo no es valido</span>} */}
                {errors.correo?.type === 'required' && <span className ="span"> <br />Este campo es requerido</span>}
                {statusCorreoExist === 200 && <span className="span">Correo ya utilizado</span>}
              </div>
              <div className="col3 campo-form">
                <label>Fecha de nacimiento</label>
                <input type='text' id="fechaNac" placeholder="dd/mm/aaaa" min={edadMax} max={edadMin} {...register('nacimiento',{required:true, validate:ruleNacimiento})} value={nacimiento}                
                onChange={ev => { DateInput(ev.target.value)}}
               // onChange={ev => {
                //   setValidarReglaNacimento(ruleNacimiento(ev.target.value))
                //   //setNacimiento (ev.target.value) 
                //   validarEndeudamiento(ev.target.value)
                // }}
                />
                {validarReglaNacimiento === false && <small className="span"> Ingresar en el siguiente formato dd/mm/aaaa </small>}
                {(errors.nacimiento?.type === 'required' && nacimiento=== undefined) && <small className="span">Este campo es requerido</small>}
                {/* {validarEdadLimite===false && <small class="span">El plazo que seleccionaste "{localStorage.getItem('plazoVal')} años" mas tu edad "{edadActual} años" no debe ser mayor a 84 años."</small>} */}

              </div>
              <div className="col3 campo-form">
                <label htmlFor="telefono">Teléfono</label>
                <input
                  type="tel"
                  size="10"
                  maxLength={10}
                  name="telefono"
                  id="telefono"
                  placeholder="Teléfono a 10 dígitos"
                  autoComplete="off"
                  value={telefono}
                  {...register('telefono',{required:true, validate:ruleTelefono})}
                  onInput={(ev) => {
                    let tel = ev.target.value;
                    if (isNaN(tel)==false) {
                      setTelefono(tel);   
                      if(tel.length<10){
                        setValidarLongitudTelefono(false)
                      }else{setValidarLongitudTelefono(true)}

                    } 
                  }}
                />
                {errors.telefono?.type === 'required' && <span className ="span">Este campo es requerido</span>}
                {errors.telefono && <span className ="span"> <br />Ingresar solo numeros</span>}
                {validarLongitudTelefono===false && <span className ="span">Ingresar 10 digitos</span>}

                <small>
                  Se utiliza únicamente para enviarte un código y puedas
                  continuar con el proceso
                </small>
              </div>
              <div className="col3 campo-form">
                <label htmlFor="ingresos">Ingresos mensuales</label>
                <input align='right' type='tel' name='ingresos' id="ingresos"  autoComplete="off"
                  style={{textAlign:'right'}}
                  value={ingreso}
                  {...register('ingresos',{required:true})}
                  onKeyDown={(ev) => {                  
                      if (ev.key === 'Backspace') {
                        let sueldo = ev.target.value;
                        sueldo = sueldo.replaceAll(',','')
                        sueldo = sueldo.replace(' MXN','')
                        sueldo = sueldo.replace(' MX','')
                        sueldo = sueldo.replace(' M','')
                        sueldo = sueldo.replaceAll(' ','')
                        sueldo = sueldo.replace('$','')
                        sueldo = sueldo.slice(0,sueldo.length-1)
                        setIngreso('$ '+sueldo+' MXN')
                      }
                  }}
                  onInput={(ev)=>{
                    let sueldo = ev.target.value;
                    sueldo = sueldo.replaceAll(',','')
                    sueldo = sueldo.replace(' MXN','')
                    sueldo = sueldo.replace(' MX','')
                    sueldo = sueldo.replace(' M','')
                    sueldo = sueldo.replaceAll(' ','')
                    sueldo = sueldo.replace('$','')
                    if(sueldo === 0 || sueldo === ''){
                      setIngreso('')  
                    }else{
                      sueldo = new Intl.NumberFormat('es-MX').format(sueldo)
                      if(sueldo !== 'NaN'){
                        setIngreso('$ '+sueldo+' MXN')
                      }
                    }
                  }}
                />
                {errors.ingresos?.type === 'required' && <span className ="span">Este campo es requerido</span>}
              </div>
              <hr />
              <p className="link">Domicilio</p>
              <div className="col3 campo-form">
                <label htmlFor="cp">Código postal</label>
                <input type="tel" name='cp' id='cp'  size={5} maxLength='5' autoComplete="off"  {...register('cp',{required:true, validate:ruleCP})}
                  value={cp}
                  onInput={(ev) => {
                    let tempCP = ev.target.value
                    if(isNaN(tempCP)==false)
                    {
                      if (tempCP.length<5) {
                        setValidarLongitudCP(false)
                        setCp(tempCP)
                      }else{
                        if(tempCP.length === 5){
                          obtenerDataCp(ev.target.value)
                          setCp(tempCP)
                          setValidarLongitudCP(true)
                        }
                      }
                    }
                  }}
                />
                <small>
                  Al ingresar su código postal correcto, en automático se registrará su estado y municipio.
                </small>
                {errors.cp?.type === 'required' &&  <span className ="span"> <br />Este campo es requerdio </span> }
                {validarLongitudCP==false && <span className ="span" > Ingresa 5 dígitos</span>}
              </div>
              <div className="col3 campo-form">
                <label htmlFor="direccion">Dirección</label>
                <input type="text" name='direccion' id='direccion'  autoComplete="off" {...register('direccion',{required:true})}
                  value={direccion}
                  onInput={(ev)=> {setDireccion(ev.target.value)}}
                />
                {errors.direccion?.type === 'required' && <span className ="span">Este campo es requerido</span>}
              </div>
              <div className="col3 campo-form">
                <label htmlFor="numExt">Número exterior</label>
                <input type="tel" name="numExt" id="numExt"  autoComplete="off" {...register('numExt',{required:true})}
                    value={exterior}
                    onInput={(ev)=> {setExterior(ev.target.value)}}
                  />
                  {errors.numExt?.type === 'required' && <span className ="span">Este campo es requerido</span>}
              </div>
              <div className="col3 campo-form">
                <label htmlFor="numInt">Número interior</label>
                <input type="tel" name="numInt" id="numInt" autoComplete="off"
                    value={interior}
                    onInput={(ev)=>{setInterior(ev.target.value)}}
                  />
              </div>
              <div className="col3 campo-form estado">
                <label htmlFor="estado">Estado</label>
                <input 
                  type="text" 
                  required 
                  name="estado"  
                  id="estado" 
                  disabled={estadoDisabled} 
                  value={estadoAux}
                  onInput={(ev)=>{setEstadoAux(ev.target.value)}}
                />
              </div>
              <div className="col3 campo-form colonia">
                <label htmlFor="municipio">Delegación / Municipio</label>
                <input 
                  required 
                  name="municipio" 
                  disabled={delegacionDisabled} 
                  id="municipio"  
                  value={municipio}
                  onInput={(ev)=>{setMunicipio(ev.target.value)}}
                />
                
              </div>
              <div className="col3 campo-form colonia">
                <label htmlFor="colonia">Colonia</label>
                  {error ? (
                    <input
                     type="text"
                     id="colonia"
                     value={colonia}
                     name="colonia"
                     onChange={(ev) => {
                       setColonia(ev.target.value);
                     }}
                    />
                  ) : (
                    <select type="text" id='colonia'  value={colonia}  name='colonia'
                    onInput={(ev) => {setColonia(ev.target.value)}} >  
                      {listColonias}
                    </select>
                  )}
              </div>
              <br />
              <br />
              <div className="col3 campo-form">
                <button type="submit" className="btn btn_morado"  >Continuar</button>
              </div>
            </form>
          </div>
          <ModalSabiasQue open = {isOpenModalModalSabiasQue} ></ModalSabiasQue>
          <ModalCodigoSms open = {isOpenModalSMS} modalBuroId={modalBuroID} modalResPaso1={modalIdPaso1} close = { () => setIsOpenModalSMS(false)} ></ModalCodigoSms>
        </div>
      </div>
    </>
  );
}