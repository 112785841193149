import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ruleEmail } from '../assets/validateRules';
import { post, get } from '../utils/http';
import '../App.css';
//import Header from '../components/Header';
import HeadHome from '../components/HeaderSocio';

import Footer from '../components/Footer';
import CrearCuenta from '../images/CrearCuenta.jpg';


// Componente para envolver las páginas de socios
export function RaizSocios({ children , verificar}) {
  return (
    <>
      <HeadHome verificar = {verificar}/>
      {children}
      <Footer />
    </>
  );
}


// Página de inicio de sesión de socios
export function InicioSocio() {
  const [logo, setLogo] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [mostrarLogo, setMostrarLogo] = useState();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const cookie = new Cookies();
  const { register, formState: { errors }, handleSubmit } = useForm();


  //!En caso de que exista un parametro en la url, se desencripta el id de la empresa
  const obtIdEmpresa = async() =>{
      //Sino entonces lo obtenemos
    const queryString = window.location.search.substr(1);
    if (queryString !== "") {
      let desencriptar = await get("desencriptarLink", queryString);
      if(desencriptar.statuscode === 200){
        const cadena = desencriptar.link;
        // Busca el primer número
        const primerNumero = cadena.match(/empresa=(\d+)/);
        if (primerNumero) {
          const idEmpresa = primerNumero[1];
          localStorage.setItem("idEmpresaHash", idEmpresa);
          obtInfonEmpresa(idEmpresa);
          setMostrarLogo(true);
        } else {
          console.log("Número de empresa no encontrado");
        }
      }
    }
    else{
      setMostrarLogo(false);
      showDivLogo(false, CrearCuenta);
    }
  
  }

  //!Consultar información de la empresa
  const obtInfonEmpresa = async(idEmpresa = 0) =>{
    if(idEmpresa > 0){
      let empresa = await get("getEmpresaSocio", idEmpresa);
      if(empresa.statuscode === 200){
        console.log(empresa);
        //Para generar la imagen
        const datosBinarios = atob(empresa.file.fileContents);
        const arrayDeBytes = new Uint8Array(datosBinarios.length);
        for (let i = 0; i < datosBinarios.length; i++) {
          arrayDeBytes[i] = datosBinarios.charCodeAt(i);
        }
        const imagenBlob = new Blob([arrayDeBytes], { type: "image/png" });
        const imagenUrl = URL.createObjectURL(imagenBlob);
        // La solicitud fue exitosa, establecer la URL de la imagen en el estado
        setLogo(imagenUrl);
        setNombreEmpresa(empresa.empresa.nombre);
        showDivLogo(true, imagenUrl);
      }
      else{
        setLogo(CrearCuenta);
        showDivLogo(false, CrearCuenta);
      }
    }
  }

  const showDivLogo = async  (show, logo) => {
    const divLogoMostrar = document.getElementById('divLogoMostrar'); // Obtén la referencia al div
    const divLogoMostrar2 = document.getElementById('divLogoMostrar2');
    
    if(show){
      divLogoMostrar.innerHTML = `
      

          <img src="${logo}" alt="Crear Cuenta Socios" class="logo_client" />
  
      
    `;
    }
    else{
      divLogoMostrar2.innerHTML = `

        <img src="${logo}" alt="Crear Cuenta Socios" />

    `;
    }
  }

  // Función para manejar el envío del formulario
  const onSubmit = () => 
  {
    let timerInterval;
    Swal.fire({
      title: 'Iniciando sesión',
      color: '#9C36B2',
      timer: 20000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const b = Swal.getHtmlContainer().querySelector('b');
        timerInterval = setInterval(() => {
          b.textContent = Swal.getTimerLeft();
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) 
      {
        Swal.fire({
          title: 'Upss...',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#9C36B2',
          icon: 'info',
          iconColor: '#9C36B2',
          html: '<b>El tiempo de espera ha sido superado, intentalo mas tarde...</b>',
        });
      }
    });
    signIn();
  };

  // Función para realizar la autenticación
  const signIn = async () => 
  {
    let data = {
      correo: email,
      password: password,
    };

    let response = await post('loginSocio', data);

    if (response.statuscode === 200) 
    {
      //console.log(response);
      cookie.remove('sessionSocio', { path: '/' });
      cookie.remove('nombreSocio', { path: '/' });
      cookie.remove('correoSocio', { path: '/' });
      cookie.remove('idSocio', { path: '/' });


      //console.log(response);
      cookie.set('sessionSocio', true, { path: '/' });
      cookie.set('nombreSocio', response.datosSocio.nombre + " " + response.datosSocio.paterno, { path: '/' });
      cookie.set('correoSocio', response.datosSocio.correo, { path: '/' });
      cookie.set('idSocio', response.datosSocio.idSocios, { path: '/' });
      window.location.href = '/panelSocios';

      //Eliminamos el hash de la empresa una vez que se registre o inicie sesión
      localStorage.removeItem('idEmpresaHash');
    } 
    else 
    {
      Swal.fire({
        confirmButtonColor: '#9C36B2',
        title: 'Usuario y/o contraseña erronea!',
        text: 'Verifica que la cuenta y la contraseña sea correcta',
        icon: 'error',
        confirmButtonText: 'Aceptar',
      });
    }
  };

  // Función para mostrar/ocultar la contraseña
  function verPassword() {
    var x = document.getElementById('password');
    var icono = document.getElementById('iconoPassword');
    if (x.type === 'password') 
    {
      x.type = 'text';
      icono.className = 'fa fa-eye-slash iconoOcultarPassword';
    } 
    else 
    {
      x.type = 'password';
      icono.className = 'fa fa-eye iconoOcultarPassword';
    }
  }

  // Efecto para desplazar al inicio de la página
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    obtIdEmpresa();
  }, []);

  return (
    <>
      <div className="cont_flex">
        <div className="col6">
          <h1>Acceso a socios {mostrarLogo && <><br />{nombreEmpresa}</>}</h1>
          <p>Accede para ver el estatus de tus clientes.</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="email">Correo electrónico </label>
            <input
              type="email"
              name="email"
              id="email"
              autoComplete="off"
              value={email}
              onInput={(ev) => setEmail(ev.target.value)}
              {...register('email', { required: true, validate: ruleEmail })}
            />
            {errors.email?.type === 'required' && <span className="span">Este campo es requerido</span>}
            {errors.email && <span className="span">  El formato del correo no es valido</span>}
            <label htmlFor="password">Contraseña </label>
            <div className="relativePosition">
              <input
                type="password"
                name="password"
                id="password"
                value={password}
                {...register('password', { required: true })}
                onInput={(ev) => setPassword(ev.target.value)}
              />
              <i
                id="iconoPassword"
                className="fa fa-eye iconoOcultarPassword"
                onClick={() => {
                  verPassword();
                }}
              >
              </i>
            </div>
            {errors.password?.type === 'required' && <span className="span">Este campo es requerido</span>}
            <div className="botones">
              <button type='submit' className="btn btn_morado" id="btnIniciar">Iniciar sesión</button>
              <Link className="btn btn_bco" to="/CrearCuentaSocios">
                Crear cuenta
              </Link>
            </div>
            <Link to="/recuperapasssocios" className="link">
              Olvidé mi contraseña
            </Link>
          </form>
        </div>
        
       { mostrarLogo ? (
        <div class="col6"><div id='divLogoMostrar'></div></div>)
        :
      (
        <div class="col6 cont_img-index"><div id='divLogoMostrar2'></div></div>)
        }
        {
          
        /*mostrarLogo ? (
              <div className="col6">
                <div>
                    <img src={logo} alt="Crear Cuenta Socios" className='logo_client'/>
                </div>
              </div>
              )
              :
            (
              <div className="col6 cont_img-index">
                <img src={CrearCuenta} alt="Crear Cuenta Socios" />
              </div>
            )*/
          }
      </div>
    </>
  );
}
