import {  Routes, Route } from "react-router-dom";
import { Raiz, Index } from "./pages/Index";
import { RaizSocios, InicioSocio } from "./pages/InicioSocios"; //JPB 07-09-2023 Nuevo login para Socios
import CrearCuentaSocios from "./pages/noAuth/CrearCuentaSinSimulacionSocios";
import { PreInformacion, InfoLaborar,InfoPersonal,InfoMedica,InfoInmueble , DocumentosEnviados} from "./pages/auth/Informacion";
import { Home } from './pages/auth/Home'
import { HomeSocios } from './pages/auth/socios/screenSocios'
import Resumen from './pages/auth/Resumen'
import {MisDatos, Simulador} from "./pages/auth/MisDatos"
import Cotizaciones from "./pages/auth/Cotizaciones"
import Bancos from "./pages/auth/Bancos"
import BancosSeleccionados from "./pages/auth/BancosSeleccionados"
import Paso1Logeado from "./pages/auth/Paso1Logueado"
import RespuestaBuro from "./pages/auth/RespuestaBuro"

import CrearCuenta from "./pages/noAuth/CrearCuenta"
import CrearCuentaSinSimulacion from "./pages/noAuth/CrearCuentaSinSimulacion"
import Paso1 from "./pages/noAuth/Paso1"
import ValidarPass from "./pages/noAuth/ValidarPass"
import RecuperaPass from "./pages/noAuth/RecuperaPass"

import CalculadoraWeb from "./pages/generales/CalculadoraWeb"
import AvisoPrivacidad from "./pages/generales/AvisoDePrivacidad"
import TerminosCondiciones from "./pages/generales/TerminosCondiciones"

import {InformacionGeneral} from "./pages/auth/InformacionGeneral"
import {Auth,NoAuth, AuthSocio, NoAuthSocios} from './components/Auth'
import Cookies from 'universal-cookie'
import {PublicProvider} from './providers/dataPublic/PublicProvider'

import RecuperarPassSocio from "./pages/noAuth/RecuperarPassSocio"
import PanelSocios from "./pages/auth/socios/PanelSocios";
import InvitacionesSocios from "./pages/auth/socios/invitacionesSocios";
import NuevoCliente from "./pages/auth/socios/frmNuevoCliente";
// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------- Declaración de rutas para mostrar las diferentes pantallas  --------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
function App() {
  const cookie = new Cookies()
  return (
    <PublicProvider>
      <Routes>
          <Route element={ <NoAuth session={cookie.get('session')} />}>
            <Route path="/" element={<Raiz><Index/></Raiz>} />
            <Route path="/crearcuenta" element={<Raiz><CrearCuenta /></Raiz>} />
            <Route path="/crearcuentaSinSimulacion" element={<Raiz><CrearCuentaSinSimulacion /></Raiz>} />
            <Route path="/recuperapass" element={<Raiz><RecuperaPass /></Raiz>} />
            <Route path="/validarPass" element={<Raiz><ValidarPass /></Raiz>} />
            
            <Route path='/calculadora' element={
              <Raiz>
                <div class= "cont_flex flex_center">
                  <div className=" cont_Info"> 
                    <CalculadoraWeb/>
                  </div> 
                </div>
              </Raiz> }/>
            <Route path="/pre_registro" element={<Raiz><Paso1/></Raiz> }/>
          </Route>
          {/* <---------- Rutas protegidas (Usuario logueado) ----------> */}
          <Route element={<Auth session={cookie.get('session')}/>}>
            <Route path="/home" element={<Home active='01'><Resumen/></Home> }/>
            <Route path="/misDatos" element={<Home active='02'><MisDatos/></Home> }/>
            <Route path="/simulacion" element={<Home active='02'><Simulador/></Home> }/>
            <Route path="/cotizaciones" element={<Home active='03'><Cotizaciones/></Home> }/>
            <Route path="/informacion" element={<Home active='03'><PreInformacion/></Home> }/>
            <Route path="/informacion/inf-laboral" element={<Home active='03'><InfoLaborar/></Home> }/>
            <Route path="/informacion/inf-personal" element={<Home active='03'><InfoPersonal/></Home> }/>
            <Route path="/informacion/inf-medica" element={<Home active='03'><InfoMedica/></Home> }/>
            <Route path="/informacion/inf-inmueble" element={<Home active='03'><InfoInmueble/></Home> }/>
            <Route path="/informacionEnviada" element={<Home active='03'><DocumentosEnviados/></Home> }/>
            <Route path="/informacionGeneral" element={<Home active='03'><InformacionGeneral/></Home> }/>
            <Route path="/observarRegistro" element={<Home active='03'><Paso1Logeado/></Home> }/>
            <Route path="/AvisoPrivacidadLogueado" element={<Home active=''><AvisoPrivacidad/></Home> }/>
            <Route path="/TerminosCondicionesLogueado" element={<Home active=''><TerminosCondiciones/></Home> }/>
            <Route path="/Bancos" element={<Home active='03'><Bancos/></Home> }/>
            <Route path="/BancosSeleccionados" element={<Home active='03'><BancosSeleccionados/></Home> }/>
            <Route path="/respuestaBuro" element={<Home active='03'><RespuestaBuro/></Home> }/>
          </Route>

          {/* <---------- Rutas protegidas socios (socio logueado) ----------> */}
          <Route element={<AuthSocio session={cookie.get('sessionSocio')}/>}>
            <Route path="/panelSocios" element={<HomeSocios active='01'><PanelSocios/></HomeSocios> }/>
            <Route path="/invitacionesSocios" element={<HomeSocios active='02'><InvitacionesSocios/></HomeSocios> }/>
            <Route path="/frmNuevoCliente" element={<HomeSocios active='02'><NuevoCliente/></HomeSocios> }/>
          </Route>

          {/* <---------- Rutas no protegidas socios  ----------> */}
          <Route element={<NoAuthSocios session={cookie.get('sessionSocio')}/>}>
           
              <Route path="/InicioSocios" element={<RaizSocios><InicioSocio/></RaizSocios>}  />
              <Route path="/CrearCuentaSocios" element={<RaizSocios verificar="true" ><CrearCuentaSocios/></RaizSocios>}  />
              <Route path="/recuperapassSocios" element={<RaizSocios><RecuperarPassSocio /></RaizSocios>} />
            
          </Route>

          <Route path="/avisoDePrivacidad" element={<AvisoPrivacidad/> }/>
          <Route path="/terminosCondiciones" element={<TerminosCondiciones/>}/>
          
          {/* <---------- Pantalla mostrada cuando la URL no se encuentra ----------> */}
          <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </PublicProvider>
  );
}

export default App;
