
import * as ReactDOM from 'react-dom';
import SCOTIABANK from "../images/SCOTIABANK.png";
import BANAMEX from "../images/BANAMEX.png";
import HSBC from "../images/HSBC.png";
import SANTANDER from "../images/SANTANDER.png";
import BANORTE from "../images/BANORTE.png";
import CITIBANAMEX from "../images/CITIBANAMEX.png";
import YAVE from "../images/YAVE.png";

import { useState, useEffect} from "react";
import { ModalMasInfoBancos, ModalBuroDeCredito, ModalRespuestaPreAutorizaSinBancosTarjetas } from '../components/Modals'
import Swal from "sweetalert2";
import Cookies from 'universal-cookie'
import { get, postFile, deleteDocEndpoint,  post, put, getCP} from '../utils/http'
import { Link } from 'react-router-dom'
import { async } from 'validate.js';
import { useNavigate } from "react-router-dom";


// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------------------- Tarjetas de la cotización de la pantalla "Resumen" -------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function InfoCotizacion({nombre,valorPropiedad,plazo,fecha}){
    const GetNF = (value) => {
        return value.toLocaleString("es-MX", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    return(
        <div className="cont_loop">
            <h3>{nombre}</h3>
            <p className="descripcion">Valor de propiedad</p>
            <p className="precio">${GetNF(valorPropiedad)} MXN</p>
            <div className="plazo plazoCardResumen">
                <div className="datosCardResumen">
                    <p className="widthCompleto">Plazo <span>{plazo} años</span></p>
                </div>
                <div>
                    <p className="widthCompleto">Creado <span>{fecha.substr(0,10)}</span></p>
                </div>
            </div>
            <a href="cotizaciones" className="btn btn_morado">Ver detalle</a>
        </div>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <----------------------------------- Tarjeta mostrada cuando el usuario no tiene cotizaciones ----------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function InfoDefault({nombre}){
    return(
        <div className="cont_loop">
            <p className="avisosinDatos">
                Aun no tienes {nombre} iniciadas.
                Elige una de tus cotizaciones e inicia
                el viaje a tu nuevo hogar
            </p>
        </div>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------------------------- Tarjetas de la ventana "Mis cotizaciones" ----------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function InfoCotizacionXL({nombre,valorPropeidad,plazo,fecha,montoSolicitado,enganche,cuestionarioId,longitudBancos,idResPaso1,statusBuro,tasa}){
    const [isOpenModalBuro, setIsOpenModalBuro] = useState(false)
    const [pintarScore,setPintarScore] = useState()
    const [isOpenModalSinBancos, setIsOpenModalSinBancos] = useState(false)
    const [sinBancoId,setSinBancoId] = useState()

    const GetNF = (value) => {
        return value.toLocaleString("es-MX", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    function redireccionarCard(){
        if(longitudBancos === 0){
            verAplicacionPreviaCotizacion()
        } else {
            
            localStorage.setItem("idResPaso1Cuestionario", idResPaso1);
            
            localStorage.setItem("verBancos", cuestionarioId);
            localStorage.setItem("plazoParaBancosSeleccionados", plazo)
            window.location.href='./BancosSeleccionados'
        }
    }

    const verAplicacionPreviaCotizacion = async() =>{
        if(cuestionarioId !== 0){
            let aplicadoPrevio = await get('obtResPrecalificaSinBancosPorIdCuestionario', parseInt(cuestionarioId)); 
            if(aplicadoPrevio.statucode === 200){
                setSinBancoId(aplicadoPrevio.msg)
                setIsOpenModalSinBancos(true)
            } else {
                let responseBtBuro = await get('obtDatosPaso2PorIdResPaso1',parseInt(idResPaso1))
                if(responseBtBuro.statuscode === 200){
                    if(parseInt(responseBtBuro.paso2.endeudamiento) >= 40){
                        Swal.fire({
                            title: 'Lo sentimos',
                            text: "Por tu nivel de endeudamiento no puedes continuar con el proceso, pero un agente se pondrá en contacto contigo.",
                            icon: 'error',
                            showCancelButton: true,
                            showConfirmButton: false,
                            cancelButtonColor: '#9C36B2',
                            cancelButtonText: 'Aceptar'
                        }).then((result) => {
                        })
                    } else {
                        localStorage.setItem("idResPaso1Cuestionario",idResPaso1)
                        window.location.href='./InformacionGeneral'
                    }
                } else {
                    localStorage.setItem("idResPaso1Cuestionario",idResPaso1)
                    window.location.href='./InformacionGeneral'
                }
            }
        } else {
            let responseBtBuro = await get('obtDatosPaso2PorIdResPaso1',parseInt(idResPaso1))
            if(responseBtBuro.statuscode === 200){
                if(parseInt(responseBtBuro.paso2.endeudamiento) >= 40){
                    Swal.fire({
                        title: 'Lo sentimos',
                        text: "Por tu nivel de endeudamiento no puedes continuar con el proceso, pero un agente se pondrá en contacto contigo.",
                        icon: 'error',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonColor: '#9C36B2',
                        cancelButtonText: 'Aceptar'
                    }).then((result) => {
                    })
                } else {
                    localStorage.setItem("idResPaso1Cuestionario",idResPaso1)
                    window.location.href='./InformacionGeneral'
                }
            } else {
                localStorage.setItem("idResPaso1Cuestionario",idResPaso1)
                window.location.href='./InformacionGeneral'
            }
        }
    }

    const nombreBanco= async() =>{
        if(cuestionarioId !== 0){
            let aplicadoPrevio = await get('obtResPrecalificaSinBancosPorIdCuestionario', parseInt(cuestionarioId)); 
            if(aplicadoPrevio.statucode === 200){
                const btnBank = document.getElementById("nombreBtnAccion" +idResPaso1);
                btnBank.innerHTML  = 'Ver resultado'
            } 
        }
    }
    
    useEffect( () => {
        nombreBanco()   
    },[])

    const verModalburo = async() => {   
        let responseBtBuro = await get('obtDatosPaso2PorIdResPaso1',parseInt(idResPaso1))

        if(responseBtBuro.statuscode === 200){
            let infoGetScore = [{
                pagoMensual: responseBtBuro.paso2.pagoMensual,
                capacidadPago: responseBtBuro.paso2.capacidadPago,
                deuda: responseBtBuro.paso2.deuda,
                endeudamiento: responseBtBuro.paso2.endeudamiento,
                comportamiento: responseBtBuro.paso2.comportamiento,
                mensualidadSinDeuda: responseBtBuro.paso2.mensualidadSinDeuda,
                montoSinDeuda: responseBtBuro.paso2.montoSinDeuda,
                mensualidadConDeuda: responseBtBuro.paso2.mensualidadConDeuda,
                montoConDeuda: responseBtBuro.paso2.montoConDeuda
            }]

            let cardScore = infoGetScore.map((infoScore) => <InfoScore
                                                                pagoMensual={infoScore.pagoMensual}
                                                                capacidadPago={infoScore.capacidadPago}
                                                                deuda={infoScore.deuda}
                                                                endeudamiento={infoScore.endeudamiento}
                                                                comportamiento={infoScore.comportamiento}
                                                                mensualidadSinDeuda={infoScore.mensualidadSinDeuda}
                                                                montoSinDeuda={infoScore.montoSinDeuda}
                                                                mensualidadConDeuda={infoScore.mensualidadConDeuda}
                                                                montoConDeuda={infoScore.montoConDeuda}            
                                                            />)
            setPintarScore(cardScore)
            setTimeout(function(){
                setIsOpenModalBuro(true)
            }, 1000) 
        } else {
            Swal.fire({
                title: 'Error en la consulta al buro de crédito',
                text: "Inténtelo de nuevo",
                icon: 'error',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#9C36B2',
                cancelButtonText: 'Aceptar'
            }).then((result) => {
            })
        }
    }  

    return(
        <div className="cont_loop">                          
            <h3>{nombre}</h3>
            <p className="descripcion">Creado el <span>{fecha.substr(0,10) }</span></p>
            <p className="descripcion">Valor de propiedad</p>
            <p className="precio">${GetNF(valorPropeidad)} MXN</p>
            <p className="descripcion">Plazo </p>
            <p>{plazo} años</p>
            <p className="descripcion">Monto solicitado </p>
            <p>${GetNF(montoSolicitado)} MXN</p>
            <p className="descripcion">Enganche </p>
            <p>${GetNF(enganche)} MXN</p>
            <p className="tasa">Tasa promedio: <span className="monto">{tasa}%</span></p>
            {statusBuro === 200 && <button  className="btn btn_bco marginEspacioArribaAbajo" id={"verburo" +idResPaso1} onClick={verModalburo}>Consulta los detalles de tu buró</button>}
            <ModalBuroDeCredito open = {isOpenModalBuro} cardScoreModal = {pintarScore} close = { () => setIsOpenModalBuro(false)} ></ModalBuroDeCredito>
            <ModalRespuestaPreAutorizaSinBancosTarjetas open = {isOpenModalSinBancos} msg = {sinBancoId} close = { () => setIsOpenModalSinBancos(false)} ></ModalRespuestaPreAutorizaSinBancosTarjetas>
            <div id="leyendaBoton">
                <button className="btn btn_morado" id={"nombreBtnAccion" +idResPaso1} onClick={redireccionarCard}> {longitudBancos === 0 ? "Aplicar" : "Ver opciones de crédito"}</button>  
            </div>
        </div>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------ Tarjeta mostrada en el modal con resultado del paso 2 ------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function InfoScore({pagoMensual, capacidadPago, deuda, endeudamiento, comportamiento, mensualidadSinDeuda, montoSinDeuda, mensualidadConDeuda, montoConDeuda}){
    const GetNF = (value) => {
        return value.toLocaleString("es-MX", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };
      
    return(
        <div className="cont_score altoAjustado">
            <h3>Los resultados de tu buró  de crédito son:</h3>
            <div className="scoreDatos">
                <p className="descripcion"> Los pagos mensuales de tus creditos actuales limitan tu capacidad de crédito y ascienden a:</p>
                <span className="valor">${GetNF(pagoMensual)}</span>
            </div>
            <div className="scoreDatos">
                <p className="descripcion">Tu capacidad de pago actual es de:  </p>
                <span className="valor">${GetNF(capacidadPago)} </span>
            </div>
            <div className="scoreDatos2">
                <h4>Nivel de endeudamiento</h4>
                <p className="descripcion2">El pago de tus deudas mensuales respecto a tu ingreso no deben de exceder de un 40%, al liquidar tus deudas aumentas tu linea de crédito.</p>  
                <div className="rangos">
                    <input className="barra1 barra2" type="range" min="0" max="100" value={endeudamiento}></input>    
                    <span className="valor valorRango"> {endeudamiento}% </span>
                </div> 
            </div>
            <div className="scoreDatos2">
                <h4>Comportamiento créditicio</h4>
                <p className="descripcion2">Pagar tus créditos de forma puntual es fundamental, ya que aumenta las posibilidades de obtener la aprobación de tu crédito hipotecario.</p>
                <div className="rangos">
                    <input className="barra1 barra3" type="range" min="0" max="10" value={comportamiento}></input>    
                    {/* <span className="valor valorRango"> {comportamiento}% </span> */}
                </div> 
            </div>
            <div className="scoreDatos">
                <p className="descripcion">Deudas vencidas: </p>
                <span className="valor">${GetNF(parseInt(deuda))} </span>
            </div>
            <div className="scoreDatos">
                <p className="descripcion">Mensualidad que pudieras pagar si pagas tus deudas actuales: </p>
                <span className="valor">${GetNF(mensualidadSinDeuda)} </span>
            </div>
            <div className="scoreDatos">
                <p className="descripcion">Crédito al que puedes acceder si pagas tus deudas: </p>
                <span className="valor">${GetNF(montoSinDeuda)} </span>
            </div>
            <div className="scoreDatos">
                <p className="descripcion">Mensualidad que pudieras pagar con tus deudas actuales: </p>
                <span className="valor">${GetNF(mensualidadConDeuda)} </span>
            </div>
            <div className="scoreDatos">
                <p className="descripcion">Crédito al que puedes acceder con tus deudas actuales: </p>
                <span className="valor">${GetNF((montoConDeuda))} </span>
            </div>
        </div>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------- Tarjetas mostradas cuando se seleccionan las 2 ofertas (Selección de bancos) ------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function TarjetaBancos ({idTarjeta, banco, creditoOfertado, montoSolicitado, Plazo, Mensualidad, Tasa, descripcion, PorcentajeTasa}){
    const GetNF = (value) => {
        return value.toLocaleString("es-MX", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    const [estadoBoton,setEstadoBoton] = useState(true)

    function seleccionar() {
        if (estadoBoton===true) {
            if(localStorage.getItem("BancoLS1") === null){
                localStorage.setItem("BancoLS1",idTarjeta)
                const intro = document.getElementById("TarjetaBanco:"+idTarjeta);
                intro.className = 'bancoSeleccionadoTarjeta cont_loop';
               
                const btnBank = document.getElementById("BtnBanco:"+idTarjeta);
                btnBank.className = 'botonSeleccionadoBancosDeshabilitado btn btn_morado'
                btnBank.innerHTML  = 'Cancelar selección'
                setEstadoBoton(false)
            } else if(localStorage.getItem("BancoLS2") === null){
                localStorage.setItem("BancoLS2",idTarjeta)  
                const intro = document.getElementById("TarjetaBanco:"+idTarjeta);
                intro.className = 'bancoSeleccionadoTarjeta cont_loop';

                const btnBank = document.getElementById("BtnBanco:"+idTarjeta);
                btnBank.className = 'botonSeleccionadoBancosDeshabilitado btn btn_morado'
                btnBank.innerHTML  = 'Cancelar selección'
                setEstadoBoton(false)  

                //Scroll para bajar en automatico
                window.scrollTo(0, document.body.scrollHeight);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Solo puede seleccionar un máximo de 2 ofertas',
                    text: 'Inténtelo de nuevo.',
                    confirmButtonColor: '#9C36B2',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar'
                }).then((result) => {
                })
            }
        }else{
            if(parseInt(localStorage.getItem("BancoLS1")) === idTarjeta){
                localStorage.removeItem("BancoLS1")
                const intro = document.getElementById("TarjetaBanco:"+idTarjeta);
                intro.className = 'cont_loop';
                const btnBank = document.getElementById("BtnBanco:"+idTarjeta);
                btnBank.className = 'btn btn_morado'
                btnBank.innerHTML  = 'Aplicar'
                setEstadoBoton(true)
            } else if(parseInt(localStorage.getItem("BancoLS2")) === idTarjeta){
                localStorage.removeItem("BancoLS2")  
                const intro = document.getElementById("TarjetaBanco:"+idTarjeta);
                intro.className = 'cont_loop';
                const btnBank = document.getElementById("BtnBanco:"+idTarjeta);
                btnBank.className = 'btn btn_morado'
                btnBank.innerHTML  = 'Aplicar'
                setEstadoBoton(true)  
            }
        }
    }
    /*return(
        <div className="cont_loop" id={"TarjetaBanco:"+idTarjeta} >                 
            <div className="imagenBancoCard">
                <img className="imgBancos" alt="Banco" src={require(`../images/${banco}.png`)}/>
            </div>
            <p className="precio">{descripcion}</p><hr />
            <div className="datoBanco">
                <p className="descripcion">Credito máximo ofertado por el banco</p>
                <p className="precio">${GetNF(creditoOfertado)} MXN</p>
            </div>
            <div className="datoBanco">
                <p className="descripcion">Monto solicitado</p>
                <p className="precio">${GetNF(montoSolicitado)} MXN</p>
            </div>
            <p className="descripcion">Plazo</p>
            <p className="precio">{Plazo} años</p>
            <p className="descripcion">Mensualidad</p>
            <p className="precio">${GetNF(Mensualidad)} MXN</p>
            <p className="descripcion">Tipo tasa</p>
            <p className="precio">{Tasa} </p>   
            <p className="descripcion">Tasa</p>
            <p className="precio">{PorcentajeTasa} </p> <br></br>   
            <button className="btn btn_morado" id={"BtnBanco:"+idTarjeta} onClick={seleccionar}> Aplicar </button>  
        </div>
    )*/
    
    return(
        <div className="cont_loop" id={"TarjetaBanco:"+idTarjeta} >                 
            <div className="imagenBancoCard">
                <img className="imgBancos" alt="Banco" src={require(`../images/${banco.toUpperCase()}.png`)}/>
            </div>
            <p className="precio">{descripcion}</p><hr />
            <div className="datoBanco">
                <p className="descripcion">Monto m&aacute;ximo ofertado por el banco</p>
                <p className="precio">${GetNF(creditoOfertado)} MXN</p>
            </div>
            <div className="datoBanco">
                <p className="descripcion">Monto solicitado</p>
                <p className="precio">${GetNF(montoSolicitado)} MXN</p>
            </div>
            <p className="descripcion">Plazo</p>
            <p className="precio">{Plazo} años</p>
            <p className="descripcion">Mensualidad</p>
            <p className="precio">${GetNF(Mensualidad)} MXN</p>
            <p className="descripcion">Tipo tasa</p>
            <p className="precio">{Tasa} </p>   
            <p className="descripcion">{PorcentajeTasa != 0 ? "Tasa" : ""}</p>
            <p className="precio">{PorcentajeTasa != 0 ? PorcentajeTasa : ""} </p> <br></br>   
            <button className="btn btn_morado" id={"BtnBanco:"+idTarjeta} onClick={seleccionar}> Aplicar </button>  
        </div>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <----------------------- Tabla inferior mostrada cuando se seleccionan las 2 ofertas (Selección de bancos) ---------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function TarjetaFijos({
  idTarjeta,
  banco,
  creditoOfertado,
  montoSolicitado,
  Plazo,
  Mensualidad,
  Tasa,
  descripcion,
  PorcentajeTasa,
}) {
  const [isOpenModalMasInfo, setIsOpenModalMasInfo] = useState(false);
  const [estadoBoton, setEstadoBoton] = useState(true);

  const GetNF = (value) => {
    return value.toLocaleString("es-MX", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  function seleccionar() {
    if (estadoBoton === true) {
      if (localStorage.getItem("BancoLS1") === null) {
        localStorage.setItem("BancoLS1", idTarjeta);
        const intro = document.getElementById("TarjetaBanco:" + idTarjeta);
        intro.className = "bancoSeleccionadoTarjeta";

        const btnBank = document.getElementById("BtnBanco:" + idTarjeta);
        btnBank.className =
          "botonSeleccionadoBancosDeshabilitado btnOfertasTabla";
        btnBank.innerHTML = "Cancelar selección";
        setEstadoBoton(false);
      } else if (localStorage.getItem("BancoLS2") === null) {
        localStorage.setItem("BancoLS2", idTarjeta);
        const intro = document.getElementById("TarjetaBanco:" + idTarjeta);
        intro.className = "bancoSeleccionadoTarjeta ";

        const btnBank = document.getElementById("BtnBanco:" + idTarjeta);
        btnBank.className =
          "botonSeleccionadoBancosDeshabilitado btnOfertasTabla";
        btnBank.innerHTML = "Cancelar selección";
        setEstadoBoton(false);

        //Scroll para bajar en automatico
        window.scrollTo(0, document.body.scrollHeight);
      } else {
        Swal.fire({
          icon: "error",
          title: "Solo puede seleccionar un máximo de 2 ofertas",
          text: "Inténtelo de nuevo.",
          confirmButtonColor: "#9C36B2",
          showCancelButton: false,
          confirmButtonText: "Aceptar",
        }).then((result) => {});
      }
    } else {
      if (parseInt(localStorage.getItem("BancoLS1")) === idTarjeta) {
        localStorage.removeItem("BancoLS1");
        const intro = document.getElementById("TarjetaBanco:" + idTarjeta);
        intro.className = "";
        const btnBank = document.getElementById("BtnBanco:" + idTarjeta);
        btnBank.className = "btnOfertasTabla";
        btnBank.innerHTML = "Aplicar";
        setEstadoBoton(true);
      } else if (parseInt(localStorage.getItem("BancoLS2")) === idTarjeta) {
        localStorage.removeItem("BancoLS2");
        const intro = document.getElementById("TarjetaBanco:" + idTarjeta);
        intro.className = "";
        const btnBank = document.getElementById("BtnBanco:" + idTarjeta);
        btnBank.className = "btnOfertasTabla";
        btnBank.innerHTML = "Aplicar";
        setEstadoBoton(true);
      }
    }
  }

  return (
    <div id={"TarjetaBanco:" + idTarjeta}>
      <div className="tablaFijosCeldasDatos">
        <div className="etiquetaTablaBancos">{banco}</div>
        <div className="etiquetaTablaBancos">
          ${GetNF(montoSolicitado)}&nbsp;MXN
        </div>
        <div className="etiquetaTablaBancos">{Plazo + " "}años</div>
        <div className="etiquetaTablaBancos">${GetNF(Mensualidad)}&nbsp;MXN</div>
        <div className="etiquetaTablaBancos">{Tasa}</div>
        {PorcentajeTasa != 0 ? (
          <div className="etiquetaTablaBancos">{PorcentajeTasa} % </div>
        ) : (
          ""
        )}
        <div className="botonTablaBancos">
          {" "}
          <button
            href="#"
            className="btnOfertasTabla"
            onClick={() => {
              setIsOpenModalMasInfo(true);
            }}
          >
            Más info
          </button>{" "}
        </div>
        <div className="botonTablaBancos">
          {" "}
          <button
            className="btnOfertasTabla"
            id={"BtnBanco:" + idTarjeta}
            onClick={seleccionar}
          >
            {" "}
            Aplicar{" "}
          </button>{" "}
        </div>

        <ModalMasInfoBancos
          open={isOpenModalMasInfo}
          close={() => setIsOpenModalMasInfo(false)}
          ModalBanco={banco}
          ModalCreditoOfertado={creditoOfertado}
          ModalMontoSolicitado={montoSolicitado}
          ModalPlazo={Plazo}
          ModalMensualidad={Mensualidad}
          ModalTasa={Tasa}
          descripcion={descripcion}
          PorcentajeTasa={PorcentajeTasa}
        >
          {" "}
        </ModalMasInfoBancos>
      </div>
    </div>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------- Tabla inferior en responsivo mostrada cuando se seleccionan las 2 ofertas (Selección de bancos) --------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function TarjetaFijosResponsivo({
  idTarjeta,
  banco,
  creditoOfertado,
  Plazo,
  Mensualidad,
  Tasa,
  descripcion,
  PorcentajeTasa,
}) {
  const [isOpenModalMasInfo, setIsOpenModalMasInfo] = useState(false);
  const [estadoBoton, setEstadoBoton] = useState(true);

  const GetNF = (value) => {
    return value.toLocaleString("es-MX", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  function seleccionar() {
    if (estadoBoton === true) {
      if (localStorage.getItem("BancoLS1") === null) {
        localStorage.setItem("BancoLS1", idTarjeta);
        const intro = document.getElementById(
          "TarjetaBancoResponsivo:" + idTarjeta
        );
        intro.className = "bancoSeleccionadoTarjeta";

        const btnBank = document.getElementById(
          "BtnBancoResponsivo:" + idTarjeta
        );
        btnBank.className =
          "botonSeleccionadoBancosDeshabilitado btnOfertasTabla";
        btnBank.innerHTML = "Cancelar selección";
        setEstadoBoton(false);
      } else if (localStorage.getItem("BancoLS2") === null) {
        localStorage.setItem("BancoLS2", idTarjeta);
        const intro = document.getElementById(
          "TarjetaBancoResponsivo:" + idTarjeta
        );
        intro.className = "bancoSeleccionadoTarjeta ";

        const btnBank = document.getElementById(
          "BtnBancoResponsivo:" + idTarjeta
        );
        btnBank.className =
          "botonSeleccionadoBancosDeshabilitado btnOfertasTabla";
        btnBank.innerHTML = "Cancelar selección";
        setEstadoBoton(false);
        //Scroll para bajar en automatico
        window.scrollTo(0, document.body.scrollHeight);
      } else {
        Swal.fire({
          icon: "error",
          title: "Solo puede seleccionar un máximo de 2 ofertas",
          text: "Inténtelo de nuevo.",
          confirmButtonColor: "#9C36B2",
          showCancelButton: false,
          confirmButtonText: "Aceptar",
        }).then((result) => {});
      }
    } else {
      if (parseInt(localStorage.getItem("BancoLS1")) === idTarjeta) {
        localStorage.removeItem("BancoLS1");
        const intro = document.getElementById(
          "TarjetaBancoResponsivo:" + idTarjeta
        );
        intro.className = "";
        const btnBank = document.getElementById(
          "BtnBancoResponsivo:" + idTarjeta
        );
        btnBank.className = "btnOfertasTabla";
        btnBank.innerHTML = "Aplicar";
        setEstadoBoton(true);
      } else if (parseInt(localStorage.getItem("BancoLS2")) === idTarjeta) {
        localStorage.removeItem("BancoLS2");
        const intro = document.getElementById(
          "TarjetaBancoResponsivo:" + idTarjeta
        );
        intro.className = "";
        const btnBank = document.getElementById(
          "BtnBancoResponsivo:" + idTarjeta
        );
        btnBank.className = "btnOfertasTabla";
        btnBank.innerHTML = "Aplicar";
        setEstadoBoton(true);
      }
    }
  }

  return (
    <div id={"TarjetaBancoResponsivo:" + idTarjeta}>
      <div className="tablaFijosCeldasDatosResponsivo">
        <div className="elementoFlex">
          <div className="negritas tituloTablaBancosSeleccionar">Nombre</div>
          <div className="etiquetaTablaBancosResponsivo">{banco}</div>
        </div>
        <div className="elementoFlex">
          <div className="negritas tituloTablaBancosSeleccionar">Oferta</div>
          <div className="etiquetaTablaBancosResponsivo">
            ${GetNF(creditoOfertado)}&nbsp;MXN
          </div>
        </div>
        <div className="elementoFlex">
          <div className="negritas tituloTablaBancosSeleccionar">Plazo</div>
          <div className="etiquetaTablaBancosResponsivo">{Plazo + " "}años</div>
        </div>
        <div className="elementoFlex">
          <div className="negritas tituloTablaBancosSeleccionar">
            Mensualidad
          </div>
          <div className="etiquetaTablaBancosResponsivo">
            ${GetNF(Mensualidad)}&nbsp;MXN
          </div>
        </div>
        <div className="elementoFlex">
          <div className="negritas tituloTablaBancosSeleccionar">Tipo tasa</div>
          <div className="etiquetaTablaBancosResponsivo">{Tasa}</div>
        </div>
        <div className="elementoFlex">
          <div className="negritas tituloTablaBancosSeleccionar">
            {PorcentajeTasa != 0 ? "Tasa" : ""}
          </div>
          <div className="etiquetaTablaBancosResponsivo">
            {PorcentajeTasa != 0 ? PorcentajeTasa : ""}
          </div>
        </div>
        <br></br> <br />
        <div className="elementoFlex">
          <div className="botonTablaBancosResponsivo">
            {" "}
            <button
              href="#"
              className="btnOfertasTabla"
              onClick={() => {
                setIsOpenModalMasInfo(true);
              }}
            >
              Más info
            </button>{" "}
          </div>
          <div className="botonTablaBancosResponsivo">
            {" "}
            <button
              className="btnOfertasTabla"
              id={"BtnBancoResponsivo:" + idTarjeta}
              onClick={seleccionar}
            >
              {" "}
              Aplicar{" "}
            </button>{" "}
          </div>
        </div>
        <ModalMasInfoBancos
          open={isOpenModalMasInfo}
          close={() => setIsOpenModalMasInfo(false)}
          ModalBanco={banco}
          ModalCreditoOfertado={creditoOfertado}
          ModalPlazo={Plazo}
          ModalMensualidad={Mensualidad}
          ModalTasa={Tasa}
          descripcion={descripcion}
          PorcentajeTasa={PorcentajeTasa}
        >
          {" "}
        </ModalMasInfoBancos>
      </div>
    </div>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------------- Tarjeta en el modal "Más info" al mostrar ofertas disponibles --------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function InfoBancos({banco,creditoOfertado,montoSolicitado, plazo ,mensualidad,tasa, descripcion, PorcentajeTasa}){
    const GetNF = (value) => {
        return value.toLocaleString("es-MX", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
      };


    return(
        <div className="cont_score ">
            <p  className="masInfoBancos centradoParrafo">
                <b>Consejo. Antes de aplicar te recomendamos ya tener un inmueble identificado
                debido a que la aprobación de los bancos vence en pocos meses</b>
            </p>
            <br />
            <div className="imagenBancoCard">
                <img className="imgBancos" alt="Banco" src={require(`../images/${banco.toUpperCase()}.png`)}/>
            </div>
            <p className="masInfoBancos centradoParrafo"> 
                <b>{descripcion}</b>
            </p>
            <br />
            <div className="scoreDatos">
                <p  className="masInfoBancos centradoParrafo"> Crédito máximo ofertado por el banco</p>
                <span className="masInfoBancos">${GetNF(creditoOfertado)} MXN</span>
            </div>  
            <div className="scoreDatos">
                <p  className="masInfoBancos centradoParrafo"> Monto solicitado </p>
                <span className="masInfoBancos">${GetNF(montoSolicitado)} MXN</span>
            </div>  
            <div className="scoreDatos">
                <p  className="masInfoBancos"> Plazo </p>
                {/* Plazo={localStorage.getItem("plazoVal") === null ? localStorage.getItem("plazoParaBancosSeleccionados"): localStorage.getItem("plazoVal")} */}
                <span className="masInfoBancos">{plazo } años</span>
                {/* <span className="masInfoBancos">{localStorage.getItem("plazoParaBancosSeleccionados")} años</span> */}
            </div>
            <div className="scoreDatos">
                <p  className="masInfoBancos"> Mensualidad </p>
                <span className="masInfoBancos">${GetNF(mensualidad)} MXN</span>
            </div>
            <div className="scoreDatos">
                <p  className="masInfoBancos"> Tipo tasa </p>
                <span className="masInfoBancos">{tasa}</span>
            </div>

            <div className="scoreDatos">
                <p  className="masInfoBancos"> {PorcentajeTasa != 0 ? "Tasa" : ""} </p>
                <span className="masInfoBancos">{PorcentajeTasa != 0 ? PorcentajeTasa : ""}</span>
            </div>
            
        </div>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------- Tarjetas mostrando las dos ofertas(bancos) seleccionados y no seleccionados ------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function TarjetaBancosGuardados({
  idTarjeta,
  banco,
  creditoOfertado,
  Plazo,
  Mensualidad,
  Tasa,
  bancoSeleccionado,
  descripcion,
  idResPrecalifica,
  idCuestionario,
  PorcentajeTasa,
}) {
  const GetNF = (value) => {
    return value.toLocaleString("es-MX", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const infoPantalla = async () => {
    localStorage.setItem("idResPrecalifica", idResPrecalifica);
    localStorage.setItem("idCuestionarioSolicitud", idCuestionario);

    let dataPantalla = await get(
      "getPantallaActual",
      localStorage.getItem("idResPrecalifica")
    );
    //console.log(dataPantalla.status);
    //console.log(dataPantalla);
    if (dataPantalla.statuscode === 200) {
      if (
        dataPantalla.infoProcesoPantalla.idCatPantallas != null ||
        dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
      ) {
        let idPantallaActual = dataPantalla.infoProcesoPantalla.idCatPantallas;
        //console.log("Pantalla: "+idPantallaActual);
        switch (idPantallaActual) {
          case 1:
            window.location.href = "../../descargarSolicitud";
            break;

          case 2:
            window.location.href = "../../SolicitudAceptada";
            break;

          case 3:
            window.location.href = "../../documentosVivienda";
            break;

          case 4:
            window.location.href = "../../notariaAsignada";
            break;

          case 5:
            window.location.href = "../../institucionExterna";
            break;

          case 6:
            window.location.href = "../../formalizacionFirma";
            break;

          case 7:
            window.location.href = "../../felicidades";
            break;

          default:
            break;
        }
      }
    } else {
      Swal.fire({
        title: "Upss...",
        html: "<b>No hay tramites iniciados con ese banco <br> ¿Deseas iniciar el proceso?</b>",
        icon: "warning",
        showCancelButton: true,
        iconColor: "#9C36B2",
        confirmButtonColor: "#9C36B2",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Iniciar",
        cancelButtonText: "No",
      }).then((result) => {
        /*if (result.isConfirmed) 
                {
                    iniciarProceso();
                }*/
      });
    }
  };

  return (
    <div
      className={
        bancoSeleccionado === true
          ? "cont_loop bancoSeleccionadoTarjeta"
          : "cont_loop"
      }
      id={"TarjetaBanco:" + idTarjeta}
      onClick={infoPantalla}
    >
      <div className="imagenBancoCard">
        <img
          className="imgBancos"
          alt="Nombre del banco"
          src={require(`../images/${banco.toUpperCase()}.png`)}
        />
      </div>
      <p className="precio"> {descripcion}</p>
      <hr />
      <div className="datoBanco">
        <p className="descripcion">Crédito ofertado</p>
        <p className="precio">${GetNF(creditoOfertado)} MXN</p>
      </div>
      <p className="descripcion">Plazo </p>
      <p className="precio">{Plazo} años</p>
      <p className="descripcion">Mensualidad </p>
      <p className="precio">${GetNF(Mensualidad)} MXN</p>
      <p className="descripcion">Tipo tasa </p>
      <p className="precio">{Tasa} </p>
      <p className="descripcion">{PorcentajeTasa != 0 ? "Tasa" : ""} </p>
      <p className="precio">{PorcentajeTasa != 0 ? PorcentajeTasa : ""} </p>

      <div className="msgBancoAplicado">
        <h4>
          {bancoSeleccionado === true && (
            <div className="btn_validandoBanco">
              <p> Oferta seleccionada </p>
              <i class="fa fa-check-circle"></i>
            </div>
          )}
        </h4>
      </div>
    </div>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------- Tabla inferior mostrando las ofertas(bancos) no seleccionados ------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function TarjetaFijosGuardados ({idTarjeta, banco, creditoOfertado, montoSolicitado, Plazo, Mensualidad, Tasa, descripcion, PorcentajeTasa}){
    const [isOpenModalMasInfo, setIsOpenModalMasInfo] = useState(false)
    
    const GetNF = (value) => {
        return value.toLocaleString("es-MX", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
      };

    return(
        <div className='tablaFijosCeldasDatos width100'>
            <div class="etiquetaTablaBancos">{banco}</div>
            <div class="etiquetaTablaBancos">${GetNF(creditoOfertado)}&nbsp;MXN</div>
            <div class="etiquetaTablaBancos">{Plazo+" "}años</div>
            <div class="etiquetaTablaBancos">${GetNF(Mensualidad)}&nbsp;MXN</div>
            <div class="etiquetaTablaBancos">{Tasa}</div>
            {/*<div class="etiquetaTablaBancos">{PorcentajeTasa != 0 ? PorcentajeTasa + "%": ""}</div>*/}
            {PorcentajeTasa != 0 ? <div class='etiquetaTablaBancos'>{PorcentajeTasa} % </div>: ""}
            <div class="botonTablaBancos"> <button href="#" className="btnOfertasTabla" onClick={() => {setIsOpenModalMasInfo(true) }}>Más info</button> </div> 
            
            <ModalMasInfoBancos open = {isOpenModalMasInfo} close = { () => setIsOpenModalMasInfo(false)}
            ModalBanco= {banco} ModalCreditoOfertado={creditoOfertado} ModalMontoSolicitado={montoSolicitado}  ModalPlazo= {Plazo} ModalMensualidad={Mensualidad}
            ModalTasa= {Tasa} descripcion={descripcion} PorcentajeTasa={PorcentajeTasa}> </ModalMasInfoBancos>
        </div>  
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------- Tabla inferior en responsivo mostrando las ofertas(bancos) no seleccionados ------------------------>
// <-------------------------------------------------------------------------------------------------------------------------------->
export function TarjetaFijosSeleccionadosResponsivo ({idTarjeta, banco, creditoOfertado, montoSolicitado, Plazo, Mensualidad, Tasa, descripcion, PorcentajeTasa}){
    const [isOpenModalMasInfo, setIsOpenModalMasInfo] = useState(false)

    const GetNF = (value) => {
        return value.toLocaleString("es-MX", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    return(
        <div id={"TarjetaBancoResponsivo:"+idTarjeta}> 
            <div className='tablaFijosCeldasDatosResponsivo'>
                <div className="elementoFlex"> 
                    <div className="negritas tituloTablaBancosSeleccionar">Nombre</div> 
                    <div class="etiquetaTablaBancosResponsivo">{banco}</div>
                </div>
                <div className="elementoFlex"> 
                    <div className="negritas tituloTablaBancosSeleccionar">Oferta</div>
                    <div class="etiquetaTablaBancosResponsivo">${GetNF(creditoOfertado)}&nbsp;MXN</div>
                </div>
                <div className="elementoFlex"> 
                    <div className="negritas tituloTablaBancosSeleccionar">Plazo</div>
                    <div class="etiquetaTablaBancosResponsivo">{Plazo+" "}años</div>
                </div>
                <div className="elementoFlex"> 
                    <div className="negritas tituloTablaBancosSeleccionar">Mensualidad</div>
                    <div class="etiquetaTablaBancosResponsivo">${GetNF(Mensualidad)}&nbsp;MXN</div>
                </div>
                <div className="elementoFlex"> 
                    <div className="negritas tituloTablaBancosSeleccionar">Tipo tasa</div>
                    <div class="etiquetaTablaBancosResponsivo">{Tasa}</div>
                </div>
                <div className="elementoFlex"> 
                    <div className="negritas tituloTablaBancosSeleccionar">Tasa</div>
                    <div class="etiquetaTablaBancosResponsivo">{PorcentajeTasa}</div>
                </div>
                
                <br></br> <br />
                <div class="botonTablaBancosResponsivo width100"> <button href="#" className="btnOfertasTabla" onClick={() => {setIsOpenModalMasInfo(true) }}>Más info</button> </div>
                <ModalMasInfoBancos open = {isOpenModalMasInfo} close = { () => setIsOpenModalMasInfo(false)} 
                ModalBanco= {banco} ModalCreditoOfertado={creditoOfertado} ModalMontoSolicitado={montoSolicitado} ModalPlazo= {Plazo} ModalMensualidad={Mensualidad}
                ModalTasa= {Tasa} descripcion={descripcion} PorcentajeTasa={PorcentajeTasa}> </ModalMasInfoBancos>
            </div>
        </div>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------------------ Tarjetas pendientes ----------------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function InfoSolicitudes(){
    return(
        <>
        {/**Definir los parametros y el diseño para esta card */}
        </>
    )
}

export function InfoHipotecas(){
    return(
        <>
        {/**Definir los parametros y el diseño para esta card */}
        </>
    )
}