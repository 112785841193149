import '../../../App.css'
import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Cookies } from 'react-cookie'
import { get, post, put, getCP, postDocs } from '../../../utils/http';
import * as ReactDOM from "react-dom/client";
import { Link  } from 'react-router-dom';
import Swal from "sweetalert2";

export default function NuevoCliente(){
    const cookies = new Cookies();

    // Estado para almacenar los datos del formulario
    const [formData, setFormData] = useState({
        nombre: '',
        paterno: '',
        materno: '',
        telefono: '',
        correo: ''
    });

    // Estado para almacenar los errores de validación
    const [errors, setErrors] = useState({});
    const navigate = useNavigate(); // Obtener el objeto navigate



    // Controlador para actualizar el estado cuando cambian los campos del formulario
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Controlador para manejar el envío del formulario
    const handleSubmit = (e) => {
        e.preventDefault();

        // Lógica de validación: verifica si los campos obligatorios están vacíos
        const newErrors = {};
        if (formData.nombre.trim() === '') newErrors.nombre = 'Campo obligatorio';
        if (formData.paterno.trim() === '')newErrors.paterno = 'Campo obligatorio';
        if (formData.materno.trim() === '')newErrors.materno = 'Campo obligatorio';
        if (formData.telefono.trim() === '')newErrors.telefono = 'Campo obligatorio';
        if (formData.correo.trim() === '')newErrors.correo = 'Campo obligatorio';
        // Agrega más condiciones de validación si es necesario

        if (Object.keys(newErrors).length > 0) {
            // Si hay errores, actualiza el estado de errores y no envíes el formulario
            setErrors(newErrors);
            return;
        }

        // Si pasa la validación, puedes enviar los datos al servidor aquí
        enviarCliente();
    };

    const enviarCliente = async () => {
        let dataInvitacionCliente = {
            idSocios : (cookies.get('idSocio')),
            nombre : formData.nombre,
            paterno : formData.paterno,
            materno : formData.materno,
            telefono : formData.telefono,
            correo : formData.correo,
            estatus : 1,
        }
        let insertData = await post("newInvitacion", dataInvitacionCliente);

        if(insertData.statuscode === 200){
            Swal.fire({
                confirmButtonColor: '#9C36B2',
                title: 'Invitación envidada correctamente',  
                icon: 'success',
                confirmButtonText: 'Aceptar'
            }).then(() => {

                // Redirigir a la página deseada
                navigate('/invitacionesSocios'); // Reemplaza '/nueva-pagina' con la URL de destino

            });
        }
        else{
            Swal.fire({
                confirmButtonColor: '#9C36B2',
                title: 'Datos ingresados erroneos',
                text: 'Verifica que tus datos sean correctos ',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            })
        }
    }


    return(
        <>
            <div className=" cont_Info">

                <h1 className="titt">Ingresa los datos de tu cliente​</h1>
                <p>Ingresa los datos de tu cliente y enviaremos una notificaci&oacute;n para que inicie 
                    su proceso de preautorizaci&oacute;n de cr&eacute;dito.</p>
                
                <form onSubmit={handleSubmit} >
                    <div className="Resum">
                        <div className="cont_flex cont-form formInfoGeneral">
                            
                                {/* NOMBRE */}
                                <div className="col3 campo-form ">
                                    <label>Nombre(s)</label>
                                    <input 
                                        type="text" 
                                        id="nombre"
                                        name="nombre"  
                                        value={formData.nombre}
                                        onChange={handleChange}
                                    />
                                    {/* Mostrar mensaje de error si existe un error en el campo */}
                                    {errors.nombre && <small style={{ color: 'red' }}>{errors.nombre}</small>}
                                </div>
                                
                                {/* APELLIDO PATERNO */}
                                <div className="col3 campo-form">
                                    <label>Apellido Paterno</label>
                                    <input 
                                        type="text" 
                                        id="paterno"
                                        name="paterno"  
                                        value={formData.paterno}
                                        onChange={handleChange}
                                    />
                                    {/* Mostrar mensaje de error si existe un error en el campo */}
                                    {errors.paterno && <small style={{ color: 'red' }}>{errors.paterno}</small>}
                                </div>

                                {/* APELLIDO MATERNO */}
                                <div className="col3 campo-form">
                                    <label>Apellido Materno</label>
                                    <input 
                                        type="text" 
                                        id="materno"
                                        name="materno"  
                                        value={formData.materno}
                                        onChange={handleChange}
                                    />
                                    {/* Mostrar mensaje de error si existe un error en el campo */}
                                    {errors.materno && <small style={{ color: 'red' }}>{errors.materno}</small>}
                                </div>

                                {/* TEL*/}
                                <div className="col3 campo-form">
                                    <label>Teléfono</label>
                                    <input 
                                        type="tel" 
                                        id="telefono"
                                        name="telefono"  
                                        value={formData.telefono}
                                        onChange={handleChange}
                                    />
                                    {/* Mostrar mensaje de error si existe un error en el campo */}
                                    {errors.telefono && <small style={{ color: 'red' }}>{errors.telefono}</small>}
                                </div>

                                {/* CORREO*/}
                                <div className="col3 campo-form">
                                    <label>Correo electrónico</label>
                                    <input 
                                        type="email" 
                                        id="correo"
                                        name="correo"
                                        value={formData.correo}
                                        onChange={handleChange}  
                                    />
                                    {/* Mostrar mensaje de error si existe un error en el campo */}
                                    {errors.correo && <small style={{ color: 'red' }}>{errors.correo}</small>}
                                </div>
                        </div>
                    </div>

                    <div className='cont_flex cont-form'>
                        <div className='dosCuttonFormularios'>
                            <Link className="btn btn_bco col3 campo-form "  to="/invitacionesSocios">Regresar</Link>
                            <button className="btn btn_morado col3 campo-form" type="submit">Enviar</button>
                        </div>
                    </div>
                </form>

            </div>
        </>
    )
}