import React, { useEffect } from "react";
import { get } from "../utils/http";
import Cookies from "universal-cookie";

/** ----------------------------------------------------------- **/
/** ------Lógica para cambiar desde BD----------- **/
/** ----------------------------------------------------------- **/

const cookies = new Cookies();

const ObtUsuarioRemaxComponent = ({ idSocios }) => {
  useEffect(() => {
    const aplicarEstilos = (altColors, configuracion, confiAdicional) => {
      const root = document.documentElement;

      root.style.setProperty(
        "--ColorBoton",
        altColors ? configuracion.colorPrimario : ""
      );
      root.style.setProperty(
        "--ColorTexto",
        altColors ? configuracion.colorSecundario : ""
      );
      root.style.setProperty(
        "--FuentePrincipal",
        altColors ? confiAdicional.fuentePrimaria : ""
      );
      root.style.setProperty(
        "--FuenteSecundaria",
        altColors ? confiAdicional.fuenteSecundaria : ""
      );
    };

    const mostrarOcultarElementos = (elemento, condicion) => {
      const elementoDOM = document.querySelector(elemento);

      if (elementoDOM) {
        elementoDOM.style.display = condicion ? "block" : "none";
      } else {
        console.log(`Elemento ${elemento} NO encontrado`);
      }
    };

    const modificarEstiloMenuLateral = (mostrar) => {
      const menuLateral = document.querySelector(".menu_lateral");
      if (menuLateral) {
        console.log("Aqui estoy");
        menuLateral.style.display = mostrar ? "80vh" : "60vh";
      } else {
        console.log("La condición no se cumple");
      }
    };

    const condicionesParaCambiarDatos = (configuracion) => {
      if (configuracion.avisoPrivacidad === 1) {
        const copyrightElement = document.querySelector(".copyright");

        if (copyrightElement) {
          copyrightElement.style.display = "block";
        } else {
          console.log("Elemento NO encontrado");
        }
      } else {
        const copyrightElement = document.querySelector(".copyright");
        if (copyrightElement) {
          copyrightElement.style.display = "none";
        } else {
          console.log("Elemento NO encontrado");
        }
      }
    };

    const obtenerConfiguraciones = async () => {
      let remax = cookies.get("remax");
      if (remax) {
        let configuraciones = await get("obtConfiguraciones", idSocios);
        const altColors = true;

        aplicarEstilos(
          altColors,
          configuraciones.configuracion,
          configuraciones.confiAdicional
        );
        mostrarOcultarElementos(
          ".titt",
          configuraciones.confiAdicional.tituloClientes === 1
        );
        mostrarOcultarElementos(
          ".descripcionClientes",
          configuraciones.confiAdicional.descripcionClientes === 1
        );
        mostrarOcultarElementos(
          ".isotipo_remax",
          configuraciones.confiAdicional.logoEmpresa === 1
        );
        mostrarOcultarElementos(
          ".menu",
          configuraciones.confiAdicional.login === 1
        );

        // Modificar el estilo del menú lateral según las condiciones
        modificarEstiloMenuLateral(
          configuraciones.confiAdicional.logoEmpresa !== 1 &&
            configuraciones.confiAdicional.login !== 1
        );

        condicionesParaCambiarDatos(configuraciones.confiAdicional);
      }
    };

    obtenerConfiguraciones();
  }, [idSocios]);

  return null;
};

/** ----------------------------------------------------------- **/
/** ------Lógica para realizar la consulta para clientes ----------- **/
/** ----------------------------------------------------------- **/

    //Obtenemos información general

  export const obtenerClientes = async (idSocios, setDataClientes) => {
    try {
      const data = await get("getClientes", idSocios);
      if (data.statuscode === 200) {
        setDataClientes("");
        setDataClientes(prevData => [...prevData, ...data.clientes, ...data.bancosNoSelect]);
      } else {
        console.error("Error en la consulta");
      }
    } catch (error) {
      console.error("Error en la consulta", error);
    }
  };


export default ObtUsuarioRemaxComponent;
