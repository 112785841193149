import React, { Component } from 'react';


class BotonCopiar extends Component {
    handleClick = () => {
        const { invitacion } = this.props;
        // Copia el contenido de la invitación al portapapeles
        navigator.clipboard.writeText(invitacion).then(() => {
            alert('Contenido de la invitación copiado al portapapeles.');
        });
    };

    render() {
        console.log(this.props.invitacion);
        console.log("prueba");
        return (
            <button onClick={this.handleClick}>Copiar Invitación</button>
        );
    }
}

export default BotonCopiar;


export function btnCopiar (props){
    const { invitacion } = props;

    const handleClick = () => {
        // Copia el contenido de la invitación al portapapeles
        navigator.clipboard.writeText(invitacion).then(() => {
            alert('Contenido de la invitación copiado al portapapeles.');
        });
    };
    console.log("aqui");
    return (
        <button onClick={handleClick}>Copiar Invitación</button>
    );
}
