import ReactDOM from 'react-dom/client';
import { useState,useEffect, useRef } from "react";
import Cookies from 'universal-cookie'
import { useForm } from 'react-hook-form'
import Swal from "sweetalert2";

import "../../App.css";
import { ModalCodigoSms, ModalCasiListos, ModalLoadingGeneral } from '../../components/Modals'
import { useDataPublic } from "../../providers/dataPublic/PublicProvider";
import { ruleRFC, ruleTelefono, ruleNacimiento } from "../../assets/validateRules";
import { get, post, put, getCP} from "../../utils/http";
import img_registro from "../../images/registro.jpg";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------------------------------- Pantalla: Paso 1 Logueado  -------------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function Paso1Logeado(){
    const cookies = new Cookies()
    const {formState:{errors},handleSubmit} = useForm()

    const [direccion,setDireccion] = useState('')
    const [exterior,setExterior] = useState('')
    const [interior,setInterior] = useState('')
    const [estado,setEstado] = useState('')
    const [estadoAux,setEstadoAux] = useState('')
    const [municipio,setMunicipio] = useState('')
    const [colonia,setColonia] =useState('')
    const [cp,setCp] = useState('')

    const {monto} = useDataPublic()
    const {plazoVal} = useDataPublic()
    const {montoPrestar} = useDataPublic()
    const {mensualidad} = useDataPublic();
    const {enganche} = useDataPublic()
    const {tasa} = useDataPublic()
    
    const [isOpenModalLoading, setIsOpenModalLoading] = useState(false)
    const [isOpenModalSMS, setIsOpenModalSMS] = useState(false)
    const [isOpenModalCasiListos, setIsOpenModalCasiListos] = useState(false)
    const [rfcGenerico, setRfcGenerico] = useState(false)
    const [validarReglaRFC, setValidarReglaRFC] = useState()
    const [validarReglaTelefono, setValidarReglaTelefono] = useState()

    const [infoUser,setInfoUser] = useState({})
    const [edit1,setEdit1] = useState(true)
    const inputCorreo = useRef()
    const[modalBuroID, setModalBuroID] = useState(0)
    const[modalIdPaso1, setModalIdPaso1] = useState(0)

    const [validarEdadLimite, setValidarEdadLimite] = useState(false)
    const [edadActual, setEdadActual] = useState()

    const [validarReglaNacimiento, setValidarReglaNacimento] = useState()
    const [nacimiento, setNacimiento] =useState()
    const [mayoriaEdad, setMayoriaEdad] =useState()


    const DateInput = (event) => {
      console.log("entro", event)
      let input = event;
  
      // Remover las diagonales existentes
      //input = input.replace(/\//g, '');
      input = input.replace(/\D/g, '');
    
      // Insertar las diagonales en la posición correcta
      if (input.length > 2 && input.length < 5) {
        input = input.slice(0, 2) + '/' + input.slice(2);
      } else if (input.length > 4) {
        input = input.slice(0, 2) + '/' + input.slice(2, 4) + '/' + input.slice(4, 8);
      }
    
      const regex = /^\d{0,2}(\/\d{0,2}(\/\d{0,4})?)?$/;
      
      if (regex.test(input)) {
        console.log("El formato de fecha es válido.");
        // Realizar acciones adicionales si la fecha es válida
      } else {
        console.log("El formato de fecha no es válido.");
        // Realizar acciones adicionales si la fecha no es válida
      }
      console.log("salir", input);
      console.log( "valor" , infoUser.fNacimiento)
      setInfoUser(()=> ({...infoUser,fNacimiento: input}))
    
      setValidarReglaNacimento(ruleNacimiento(input))
      //validarEndeudamiento(input)
      console.log(input);
      let fechaNF = input.substr(6,4)+ "-" + input.substr(3,2) + "-" + input.substr(0,2 );
      console.log(fechaNF)
     
        validarEndeudamiento(fechaNF)
        console.log("inpt" + input);
      
    
    };

  
    if(monto!==null){
      if (monto!==undefined) {
        localStorage.setItem("monto", monto)
      }
    }
    if(plazoVal!==null){
      if (plazoVal!==undefined) {
        localStorage.setItem("plazoVal", plazoVal)
      }
    }
    if(montoPrestar!==null){
      if (montoPrestar!==undefined) {
        localStorage.setItem("montoPrestar", montoPrestar)
      }
    }
    if(enganche!==null){
      if (enganche!==undefined) {
        localStorage.setItem("enganche", enganche)
      }
    }
    if(tasa!==null){
      if (tasa!==undefined) {
        localStorage.setItem("tasa", tasa)
      }
    }
    if(mensualidad!==null){
      if (mensualidad!==undefined) {
        localStorage.setItem("mensualidad", mensualidad)
      }
    }
  
    function fechaCreacion() {
      let date = new Date();
      date.setHours(date.getHours() - 6);
      let isodate = date.toISOString();
      return isodate;
    }
      
    let fechCre= fechaCreacion() ;
  
    let hoy = new Date();
    
    let mesMinMax 
    if((hoy.getMonth()+1).toString().length === 1){
      mesMinMax = "0"+ (hoy.getMonth()+1)
    } else {
      mesMinMax = (hoy.getMonth()+1)
    }

    let diaMinMax 

    if((hoy.getDate()).toString().length===1){
      diaMinMax = "0"+ (hoy.getDate())
    } else {
      diaMinMax = (hoy.getDate())
    }
    
    let edadMin = hoy.getFullYear()-18 + "-" + mesMinMax.toString() + "-" +diaMinMax.toString()
    let edadMax = '1935' + "-" + mesMinMax.toString() + "-" + diaMinMax.toString()

    const info = async() =>{
      let data = await get('infoUser',cookies.get('correo'))
      if(data.statuscode === 200){
        if(data.user.fNacimiento !== null){
          let fecha = new Date(data.user.fNacimiento)
          if(fecha.getMonth()+1 < 10 && fecha.getDate() < 10){ 
            data.user.fNacimiento = fecha.getFullYear()+'-0'+ (fecha.getMonth()+1)+'-0'+fecha.getDate()
          }else if(fecha.getDate() < 10){
            data.user.fNacimiento = fecha.getFullYear()+'-'+ (fecha.getMonth()+1)+'-0'+fecha.getDate()
          } else if (fecha.getMonth()+1 < 10) {
            data.user.fNacimiento = fecha.getFullYear()+'-0'+ (fecha.getMonth()+1)+'-'+fecha.getDate()
          } else {
            data.user.fNacimiento = fecha.getFullYear()+'-'+ (fecha.getMonth()+1)+'-'+fecha.getDate()
          }
          validarEndeudamiento(data.user.fNacimiento)
          console.log(data.user.fNacimiento)

          
          let fechaNF = data.user.fNacimiento.substr(8,2)+ "/" + data.user.fNacimiento.substr(5,2) + "/" + data.user.fNacimiento.substr(0,4 );
          data.user.fNacimiento = fechaNF;

          console.log(fechaNF);


        } 
       
        if(data.user.ingresoB!==null){
          data.user.ingresoB='$ '+GetNF(data.user.ingresoB)+' MXN'
        }
  
        if (data.user.domicilios.length !== 0) {
          let posDom = data.user.domicilios.length - 1
          setEstado(data.user.domicilios[posDom].estado)
          setMunicipio(data.user.domicilios[posDom].ciudad)
          setColonia(data.user.domicilios[posDom].colonia)
          setCp(data.user.domicilios[posDom].cp)
          setDireccion(data.user.domicilios[posDom].direccion)
          setExterior(data.user.domicilios[posDom].nExteriors)
          setInterior(data.user.domicilios[posDom].nInterior)
        }
        
        setInfoUser(() =>({
          ...infoUser,
          ...data.user,
        }))
      }
    }
  
    useEffect( () => {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      info()    
    }, [])

    const listColoniasCP = (colonias) =>{
      let opcionDefault = <option value='' selected="selected" disabled>Seleccione una opción... </option>

      colonias = colonias.map( (colonia) => (
        <option value={colonia} key={colonia}>{colonia}</option>
      ))
      colonias.unshift(opcionDefault)
  
      const selectcol = document.getElementById("coloniaSelect");
      selectcol.className = ''
      const inputcol = document.getElementById("coloniaInput");
      inputcol.className = 'ocultarDiv'
  
      let root = ReactDOM.createRoot(document.getElementById('coloniaSelect'));
      root.render(colonias);
    }

    function calcularEdad(fecha) {
      let hoy = new Date();
      let cumpleanos = new Date(fecha);
      let edad = hoy.getFullYear() - cumpleanos.getFullYear();
      let m = hoy.getMonth()+1 - cumpleanos.getMonth()+1;
      if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
      }
      if (edad < 18) {
        setMayoriaEdad(false);
      }else{
        setMayoriaEdad(true)
      }
      return edad;
    }

    function validarEndeudamiento(fecha) {
      let edadActual = parseInt(calcularEdad(fecha))
  
      let validarEdad = edadActual  + parseInt(localStorage.getItem('plazoVal'));
      setEdadActual(edadActual)
  
      if (validarEdad>=84) {
        setValidarEdadLimite(false)
      }else{
        setValidarEdadLimite(true)
      }
  
    }
  
    async function confirmarTerminos () {
      if (infoUser.nombre === '' || infoUser.paterno === '' || infoUser.materno === '' || infoUser.correo === '' ||
        infoUser.telefono === '' ||infoUser.rfc===''|| infoUser.fNacimiento === '' || infoUser.ingresoB === '' || 
        direccion === '' || exterior === '' || colonia === ''|| cp === '' || validarReglaTelefono === false || validarReglaRFC === false || validarEdadLimite===false || validarReglaNacimiento == false || mayoriaEdad== false){
          Swal.fire({
          confirmButtonColor: '#9C36B2',
          title: 'Por favor llena todos los campos',
          text: '',
          icon: 'warning',
          confirmButtonText: 'Aceptar'
        })
      }else{
        setIsOpenModalCasiListos(true)
      }
    }
    
    const obtenerDataCp = async(cp) =>{
      let respuestaPorCP = await getCP('info_cp',cp)
      // let respuestaPorCP = {
      //   code_error: 0,
      //   error: false,
      //   error_message: null,
      //   response:{
      //     cp: "72587", tipo_asentamiento:"Colonia", municipio:"Puebla",
      //     asentamiento:[
      //       "Granjas San Isidro",
      //       "La mora",
      //       "INFONAVIT San Jorge"
      //     ],
      //     ciudad:"heroica Puebla de Zaragoza",
      //     cp: "72587",
      //     estado: "Puebla",
      //     municipio: "Puebla",
      //     pais: "Mexico",
      //     tipo_asentamiento:"Colonia"
      //   }
      // }
      obtenerClaveIso(respuestaPorCP.response.estado)
      listColoniasCP(respuestaPorCP.response.asentamiento)
      setMunicipio(respuestaPorCP.response.municipio)
    }
  
    const obtenerClaveIso=async(estadoCP)=>{
      let obtenerClave = await get('obtClaveISOPorEstado', estadoCP)
      setEstadoAux(estadoCP)
      if (obtenerClave.statuscode === 200) {
        setEstado(obtenerClave.iso)
      }
    }
  
    function CancelarMostrar() {
      setEdit1(true)  
    }
  
    let data = {}
    let datauUpdate = {}
    const sendPaso1 = async () => {
      const fechaArray = infoUser.fNacimiento.split('/');
      const fechaFormateada = `${fechaArray[2]}-${fechaArray[1]}-${fechaArray[0]}`;
      datauUpdate ={
        id: cookies.get('id'),
        correo: infoUser.correo,
        nombre: infoUser.nombre,
        paterno:infoUser.paterno,
        materno:infoUser.materno,
        fNacimiento:fechaFormateada+'T00:00:00.047Z',
        ingresoB:parseInt(infoUser.ingresoB.replaceAll('$ ','').replaceAll(' MXN','').replaceAll('.','').replaceAll(',','')),
        telefono:(infoUser.telefono).replaceAll('-',''),
        rfc:infoUser.rfc,
      }

      data.user = {
        nombre: infoUser.nombre,
        paterno:infoUser.paterno,
        materno:infoUser.materno,
        correo: infoUser.correo,
        telefono:(infoUser.telefono).replaceAll('-',''),
        rfc:infoUser.rfc,
        fNacimiento:fechaFormateada+'T00:00:00.047Z',
        ingresoB:parseInt(infoUser.ingresoB.replaceAll('$ ','').replaceAll(' MXN','').replaceAll('.','').replaceAll(',','')),
      }

      data.domicilio = {
        direccion: direccion,
        nExteriors: exterior,
        nInterior: interior,
        colonia: colonia,
        ciudad: municipio,
        estado:  estado,
        cp: cp
      }

      data.cuestionario ={
        edad: calcularEdad(fechaFormateada),
        brutoMensual: parseInt(infoUser.ingresoB.replaceAll('$ ','').replaceAll(' MXN','').replaceAll('.','').replaceAll(',','')),
        proyecto: "Solictud Saturn 5: "+infoUser.nombre +' '+infoUser.paterno+' '+infoUser.materno,
        etapa: "Pre-Califica paso 1",
        valorCredito: monto ? monto.toString() :''//TODO VALIDAD QUE EL MONTO N O ESTE VACIO O SINO ENVIAR MENSAJE 
      }

      data.calculadora ={
        fechaCrecion: fechCre,
        valorPropiedad: parseInt(localStorage.getItem("monto")),
        plazo:parseInt(localStorage.getItem("plazoVal")),
        montoSolicitado: parseInt(localStorage.getItem("montoPrestar")),
        enganche:parseInt(localStorage.getItem("enganche")),
        tasaPromedio:parseInt(localStorage.getItem("tasa")),
        mensualidad: parseInt(localStorage.getItem("mensualidad"))
      }

      let prepaso1 = await post("prePaso1", data)

      await put('updateUser',datauUpdate)
      if(prepaso1.statuscode === 200){
        setIsOpenModalLoading(false)
        setIsOpenModalSMS(true);
        setModalBuroID(prepaso1.buroId)
        setModalIdPaso1(prepaso1.idResPaso1)
      } else {
        Swal.fire({
          confirmButtonColor: '#9C36B2',
          title: 'Datos ingresados erroneos',
          text: 'Verifica que tus datos sean correctos ',
          icon: 'error',
          confirmButtonText: 'Aceptar'
        })
        setIsOpenModalLoading(false)
      }
    }
  
    const GetNF = (value) => {
      return value.toLocaleString("es-MX", {
        maximumFractionDigits: 2,
      });
    };
    
    function miFuncion (){
      setIsOpenModalCasiListos(false)
      setIsOpenModalLoading(true)
      sendPaso1()
    }
  
    return(
      <>
      <div className="widthCompleto">   
      <div className="cont_flex wrapper margin100">
          <div className="col3">
            <h5 className="link">Te queremos conocer</h5>
            <h1>Aquí inicia el camino a tu nuevo hogar</h1>
            <p>
              Para conseguirte el mejor crédito es necesario ver tu capacidad de
              crédito
            </p>
          </div>
          <div className="col6 cont_img-index-border">
            <img src={img_registro} alt="registro" />
          </div>
        </div>
        <div className="cont_datos_registro wrapper">
          <div className="Resum">
            <ModalCasiListos open = {isOpenModalCasiListos} close = { () => miFuncion()} ></ModalCasiListos>
            <div className="header">
              <h3>Datos básicos</h3>
            </div>
            <div className="cont_flex cont-form">
            <form onSubmit={handleSubmit(confirmarTerminos)}>
                <div className="col3 campo-form">
                  <label htmlFor="nombre">Nombre(s)</label>
                  <input type="text" id="nombre"name="nombre" value={infoUser.nombre}  onInput={(ev) => {
                    setInfoUser(()=> ({...infoUser,nombre:ev.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "")}))
                  }}/>
                  {(infoUser.nombre === '' || infoUser.nombre===' ') && <small class="span">Este campo es requerido</small>}
                </div>
                <div className="col3 campo-form">
                  <label htmlFor="aPaterno">Apellido Paterno</label>
                  <input type="text" value={infoUser.paterno} id="aPaterno"
                    name="aPaterno" onChange={ev => {
                                      let tempPaterno = ev.target.value
                                      setInfoUser(()=> ({...infoUser,paterno:tempPaterno.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "")}))
                                      }}/>
                  {(infoUser.paterno === ''|| infoUser.paterno===' ') && <small class="span">Este campo es requerido</small>}
                </div>
                <div className="col3 campo-form">
                  <label htmlFor="aMaterno">Apellido Materno</label>
                  <input type="text" value={infoUser.materno} id="aMaterno"
                    name="aMaterno" onChange={ev => {
                                      let tempMaterno = ev.target.value
                                      setInfoUser(()=> ({...infoUser,materno : tempMaterno.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "")}))
                                      }}/>
                  {(infoUser.materno === ''|| infoUser.materno===' ') && <small class="span">Este campo es requerido</small>}
                </div>
                <div className="col3 campo-form">
                  <label htmlFor="rfc">RFC (con homoclave)</label>
                  <input 
                    type="text"  
                    autoComplete="off" 
                    name="rfc" 
                    style={{ textTransform: "uppercase" }}
                    value={infoUser.rfc} 
                    id="rfc" size={13}
                    minLength="12"
                    maxLength="13"
                    onInput={(ev) => {
                      setValidarReglaRFC(ruleRFC(ev.target.value))
                      if (ev.target.value === '' || ev.target.value.length < 13){
                        setInfoUser(()=> ({...infoUser,rfc : ev.target.value}))
                        setRfcGenerico(false)
                      }else if(ev.target.value === 'XAXX010101000' || ev.target.value ==='XEXX010101000' ||ev.target.value ==='xexx010101000' || ev.target.value ==='xaxx010101000'){
                        setRfcGenerico(true)
                      }else if(ev.target.value.length === 13){
                        setRfcGenerico(false)
                        setInfoUser(()=> ({...infoUser,rfc : ev.target.value}))
                      } 
                    }}
                  />  
                  {(infoUser.rfc === ''|| infoUser.rfc===null) && <small class="span">Este campo es requerido</small>}
                  {validarReglaRFC === false && <small class="span"> El formato del RFC no es valido</small>}
                  {rfcGenerico === true && <small class = "span">No usar RFC generico</small>} 
                </div>
                <div className="col3 campo-form">
                  <label htmlFor="correo">Correo electrónico</label>
                  <input type="email" ref={inputCorreo} value={infoUser.correo} disabled autoComplete="off"
                    name="correo" id="correo" 
                    onChange={ev => {
                      let tempCorreo = ev.target.value
                      setInfoUser(()=> ({...infoUser,correo : tempCorreo}))
                    }}/>
                  {(infoUser.correo === ''|| infoUser.correo===' ') && <small class="span">Este campo es requerido</small>}
                </div>
                <div className="col3 campo-form">
                  <label>Fecha de nacimiento</label>
                  <input type="text" min={edadMax} max={edadMin} value={infoUser.fNacimiento}  
                     onInput={ev => {DateInput(ev.target.value)}}
                    />
                    {validarReglaNacimiento === false && <small class="span"> Ingresar en el siguiente formato dd/mm/aaaa <br /></small>}
                    {mayoriaEdad === false && <small class="span"> Ingresar una edad mayor a 18 años </small>}
                  {(infoUser.fNacimiento === ''|| infoUser.fNacimiento=== null) && <small class="span">Este campo es requerido</small>}
                  {validarEdadLimite===false  && <small class="span">El plazo que seleccionaste "{localStorage.getItem('plazoVal')} años" mas tu edad "{edadActual} años" no debe ser mayor a 84 años."</small>}
                </div>
                <div className="col3 campo-form">
                  <label htmlFor="telefono">Teléfono</label>
                  <input type="tel" size="14" maxLength={10} name="telefono" id="telefono" placeholder="Teléfono a 10 dígitos"
                    autoComplete="off" value={infoUser.telefono}  
                    onInput={ev => {
                      setValidarReglaTelefono(ruleTelefono(ev.target.value))
                      let tempTelefono = ev.target.value 
                      setInfoUser(()=> ({...infoUser,telefono : tempTelefono}))
                  }}/>
                  {(infoUser.telefono === ''|| infoUser.telefono===null) && <small class="span">Este campo es requerido</small>}
                  {(validarReglaTelefono === false) && <small class="span"> Ingresar solo números</small>}
                  {errors.telefono?.type === 'required' && <span class ="span">Este campo es requerido</span>}
                  {errors.telefono && <span class ="span"> <br />Ingresar solo números</span>}    
                </div>
                <div className="col3 campo-form">
                  <label htmlFor="ingresos">Ingresos mensuales</label>
                  <input align='right' type='tel' name='ingresos' id="ingresos"   autoComplete="off "value={infoUser.ingresoB}  
                    onKeyDown={(ev) => {                  
                        if (ev.key === 'Backspace') {
                          let sueldo = ev.target.value;
                          sueldo = sueldo.replaceAll(',','')
                          sueldo = sueldo.replace(' MXN','')
                          sueldo = sueldo.replace(' MX','')
                          sueldo = sueldo.replace(' M','')
                          sueldo = sueldo.replaceAll(' ','')
                          sueldo = sueldo.replace('$','')
                          sueldo = sueldo.slice(0,sueldo.length-1)
                          setInfoUser(()=> ({...infoUser,ingresoB : '$ '+sueldo+' MXN'}))
                        }
                    }}
                    onInput={(ev)=>{
                      let sueldo = ev.target.value;
                      sueldo = sueldo.replaceAll(',','')
                      sueldo = sueldo.replace(' MXN','')
                      sueldo = sueldo.replace(' MX','')
                      sueldo = sueldo.replace(' M','')
                      sueldo = sueldo.replaceAll(' ','')
                      sueldo = sueldo.replace('$','')
                      if(sueldo === 0 || sueldo === ''){
                        setInfoUser(()=> ({...infoUser,ingresoB : ''}))
                      }else{
                        sueldo = new Intl.NumberFormat('es-MX').format(sueldo)
                        if (sueldo !== 'NaN') {
                          setInfoUser(()=> ({...infoUser,ingresoB : '$ '+GetNF(sueldo)+' MXN'}))
                        }
                      }
                    }}
                  />
                  {(infoUser.ingresoB === ''||infoUser.ingresoB === null) && <small class="span">Este campo es requerido</small>}
                </div>
                <hr />

                <p className="link">Domicilio</p>
                <div className="col3 campo-form">
                  <label htmlFor="cp">Código postal</label>
                  <input type="tel" name='cp' id='cp'  size={5} maxLength='5' autoComplete="off"
                    value={cp}
                    onInput={(ev) => {
                      if (isNaN(ev.target.value) === true) {
                       setCp(" ")
                      }else{
                        if(ev.target.value.length === 5){
                          obtenerDataCp(ev.target.value)
                        }
                        setCp(ev.target.value)
                      }
                    }}
                  />
                  <small>
                    Al ingresar su código postal correcto, en automático se registrará su estado y municipio.
                  </small>
                  {cp==='' &&  <small class ="span">Este campo es requerido </small> }
                </div>
                <div className="col3 campo-form">
                  <label htmlFor="direccion">Dirección</label>
                  <input type="text" name='direccion' id='direccion' autoComplete="off"
                    value={direccion}
                    onInput={(ev)=> {
                      setDireccion(ev.target.value)
                    }}
                  />
                  {direccion === '' &&  <small class ="span">Este campo es requerido </small> }
                </div>
                <div className="col3 campo-form">
                  <label htmlFor="nExteriors">Número exterior</label>
                  <input type="tel" name="nExteriors" id="nExteriors"  autoComplete="off"
                    value={exterior}
                    onInput={(ev)=> {
                      setExterior(ev.target.value)
                    }}
                    />
                  {exterior === '' &&  <small class ="span">Este campo es requerido </small> }
                </div>
                <div className="col3 campo-form">
                  <label htmlFor="numInt">Número interior</label>
                  <input type="tel" name="numInt" id="numInt" autoComplete="off"
                    value={interior}
                    onInput={(ev)=> {
                      setInterior(ev.target.value)
                    }} />
                </div>
                <div className="col3 campo-form estado">
                  <label htmlFor="estado">Estado</label>
                  <input name="estado" id="estado" disabled
                    value={estado !== '' ? estado : estadoAux}>
                  </input>
                </div>
                <div className="col3 campo-form colonia">
                  <label htmlFor="ciudad">Delegación / Municipio</label>
                  <input name="ciudad" id="ciudad" disabled
                    value={municipio}>
                  </input>
                </div>
                <div className="col3 campo-form colonia">
                  <label htmlFor="coloniaSelect">Colonia</label>
                    <select type="text" className="ocultarDiv" id='coloniaSelect' name='coloniaSelect'
                    onChange={(ev)=> {
                      setColonia(ev.target.value)
                    }} />
                    <input type="text"  id='coloniaInput' name='coloniaInput' disabled
                    value={colonia} />
                    {colonia === '' &&  <small class ="span">Este campo es requerido </small> }
                </div>
                <br />
                <br />
                <div className="col3 campo-form ">
                  {edit1===true && <button type="submit" name="btnContinuarP1L" id="btnContinuarP1L" className="btn btn_morado" >Continuar</button>}
                  {!edit1 && <button type="submit" name="btnGuardarP1L" id="btnGuardarP1L" className="btn btn_morado marginEspacio">Guardar Cambios</button>}
                  {!edit1 && <button  className="btn btn_bco marginEspacio" onClick={() => {CancelarMostrar()}}>Cancelar</button>}
                </div>
              </form>
            </div>
            <ModalLoadingGeneral open = {isOpenModalLoading} ></ModalLoadingGeneral>
            <ModalCodigoSms open = {isOpenModalSMS} modalBuroId={modalBuroID} modalResPaso1={modalIdPaso1} close = { () => setIsOpenModalSMS(false)} ></ModalCodigoSms>
           </div>
        </div>
      </div>  
    </>
  )
} 