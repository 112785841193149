import '../../App.css'
import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Cookies } from 'react-cookie'
import { get } from '../../utils/http'
import { InfoCotizacion } from '../../components/Cards'
import * as ReactDOM from 'react-dom';
import { ConCotizacionP13} from '../../components/Msjs'
import { ModalLoadingGeneral } from '../../components/Modals'

// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------- Pantalla inicial con las tarjetas de todas las cotizaciones --------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export  default function Resumen(){
    const [isOpenModalLoading, setIsOpenModalLoading] = useState(true)
    const cookies = new Cookies()
    const navigate = useNavigate()         
 
    localStorage.removeItem("idResPaso1Cuestionario")
    localStorage.removeItem("BancoLS1")
    localStorage.removeItem("BancoLS2")
    localStorage.removeItem("idResPaso1SeleccionarBancos")
    localStorage.removeItem("verBancos")
    localStorage.removeItem("plazoParaBancosSeleccionados")

    localStorage.removeItem("monto")
    localStorage.removeItem("plazoVal")
    localStorage.removeItem("montoPrestar")
    localStorage.removeItem("enganche")
    localStorage.removeItem("tasa")
    localStorage.removeItem("mensualidad")

    localStorage.removeItem("nombreCalculadora")
    localStorage.removeItem("apellidoPCalculadora")
    localStorage.removeItem("apellidoMCalculadora")
    localStorage.removeItem("correoCalculadora")
    localStorage.removeItem("idResPaso1Buro")
    localStorage.removeItem("sinRespuestaBuro")

    localStorage.removeItem("statuscodepaso2")

    localStorage.removeItem("idCuestionarioSolicitud");
    localStorage.removeItem("idResPrecalifica");

    let cotizaciones
    const info = async() =>{
        let response3 = await get("verificarUsuarioCuestionarioPreAutorizar",cookies.get('id') )
        if (response3.statuscode===200 && response3.cuestionarioLlenos===0 && response3.cotizacionesRealizadas===1) {
            let dataCotizacionUno = await get('userCotizaciones', cookies.get('id'))
            localStorage.setItem("idResPaso1Cuestionario", dataCotizacionUno.cotizaciones[0].id)
            navigate("/informacionGeneral")
        }else{
            let data = await get('userCotizaciones', cookies.get('id'))
            if(data.statucode === 200){
                let infoCotizaciones = []
                 for(let num=0; num<data.cotizaciones.length; num++){
                    infoCotizaciones.push(
                    {nombre: 'Cotización '+[num+1],
                    fecha : data.cotizaciones[num].fechaCrecion,
                    valorPropeidad : data.cotizaciones[num].valorPropiedad,
                    plazo : data.cotizaciones[num].plazo,})
                }    
                if(infoCotizaciones.length === 0 ){
                    const rootb = ReactDOM.createRoot(
                    document.getElementById('SinCotizacionResumenb'),
                    );
                    rootb.render(<ConCotizacionP13/>);
                    setIsOpenModalLoading(false)
                }else{
                    cotizaciones = infoCotizaciones.map((contizacion) => <InfoCotizacion
                                                                            nombre={contizacion.nombre} 
                                                                            valorPropiedad={contizacion.valorPropeidad}
                                                                            plazo={contizacion.plazo}
                                                                            fecha={contizacion.fecha}
                                                                            key={contizacion.nombre} />)

                    const root = ReactDOM.createRoot(
                    document.getElementById('todasCotizaciones'),
                    );
                    root.render(cotizaciones);
                    setIsOpenModalLoading(false)
                }
            }
        } 
    }
   
    useEffect( () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        info()    
    },[])
    
    return(
        <>
            <div className=" cont_Info">
                <h1>Mi tablero</h1>

                <h3> Mis cotizaciones de crédito</h3>
                <div className="cont_flex con_flex_wrap" id="todasCotizaciones"></div>
                <div className="cont-Cotizaciones" id="SinCotizacionResumenb"></div> 

                {/* <---------- Tarjetas para poner "Mis solicitudes" ----------> */}
                <div className="Resum">
                    {/* <div className="header">
                        <h3>Mis Solicitudes</h3>
                        <a href="#" className="link">Ir a mis solicitudes</a>
                    </div>
                    <div className="cont_flex">
                        {solicitudes}
                    </div> */}
                </div>

                {/* <---------- Tarjetas para poner "Mis hipotecas" ----------> */}
                <div className="Resum">
                    {/* <div className="header">
                        <h3>Mis hipotécas</h3>
                        <a href="#" className="link">Ir a mis hipotécas</a>
                    </div>
                    <div className="cont_flex">
                        {hipotecas}
                    </div> */}
                </div>
                <ModalLoadingGeneral open = {isOpenModalLoading} ></ModalLoadingGeneral>
            </div>
        </>
    )
}
