import { Link } from "react-router-dom";
import "../App.css";
import "../sass/style.css";
import isotipo_morado from "../images/isotipo_morado.svg";
import logo_morado from "../images/logo_morado.svg";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------- Footer cuando el usuario no esta logueado ------------------------------------------>
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function Footer() {
  return (
    <>
      <footer>
        <div className="wrapper">
          <div>
            <div>
              <Link to="/" className="isotipo" >
                <img className="isotipo" src={isotipo_morado} alt='saturn 5' />
              </Link>
            </div>
            <div className="menu-footer">
              <ul>
                <li>
                  <Link to='/'>Home</Link>
                </li>
                <li>
                  <Link to="/calculadora">Solicita tu crédito</Link>
                </li>
                <li>
                  <a  href="https://saturn5.mx/#section-101-17" target='_blank' rel="noreferrer">Contacto</a >
                </li>
              </ul>
              <ul>
                <li>
                  <Link to='/'>Login</Link>
                </li>
                <li>
                  <Link to='/crearcuentasinSimulacion'>Crear cuenta</Link>
                </li>
              </ul>
            </div>

            <ul className="redes">
              <li>
                <a href="https://www.instagram.com/saturn5mx/" target='_blank' rel="noreferrer">
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/saturn5mx" target='_blank' rel="noreferrer">
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/saturn5/" target='_blank' rel="noreferrer">
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="copyright">
            <p>© 2032 Copyright 2023 Saturn5 Science SAPI de CV</p>
            <img src={logo_morado} alt='saturn 5'/>
          </div>
        </div>
      </footer>
    </>
  );
}
