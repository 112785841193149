import React from 'react'
import "../sass/style.css";
import "../App.css";
import logotipo_negro from '../images/logotipo-negro.png'
import { useState } from "react";
import { useForm } from 'react-hook-form'
import { Link,useNavigate } from "react-router-dom";
import { InfoBancos} from '../components/Cards'
import logoMorado from '../images/logo_morado.svg'
import Cookies from 'universal-cookie'
import loadingicon from "../images/loadingicon.gif";
import loadingicon2 from "../images/loadingicon2.gif";
import {get, post} from "../utils/http";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { useSetDataPublic } from "../providers/dataPublic/PublicProvider";

import NavSocio from "./NavSocio";
// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------------------- Modal para ingresar el NIP del sms entre paso 1 y 2 ------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalCodigoSms({children, open, modalBuroId, modalResPaso1, close}) {
    const [digito1, setDigito1] = useState();
    const [digito2, setDigito2] = useState();
    const [digito3, setDigito3] = useState();
    const [digito4, setDigito4] = useState();
    const [digito5, setDigito5] = useState();
    const [digito6, setDigito6] = useState();
    const actionContext = useSetDataPublic()
    const [isOpenModalLoading, setIsOpenModalLoading] = useState(false)
    const {register,formState:{errors},handleSubmit} =useForm()
    const navigate = useNavigate()

    const onSubmit = () => {
        let nip = digito1+digito2+digito3+digito4+digito5+digito6   
        sendpaso2(nip)
    }

    let location = useLocation();
        
    const sendpaso2 = async (nip) => {
        setIsOpenModalLoading(true)

        let data = {'buroId': parseInt(modalBuroId), 'nip': parseInt(nip), 'idResPaso1':parseInt(modalResPaso1)}
        let response = await post('prePaso2',data)
        
        if(response.statuscode === 200){
            setIsOpenModalLoading(false)
            localStorage.setItem("statuscodepaso2", response.statuscode)
            localStorage.setItem("sinRespuestaBuro", "false") 
            if(location.pathname === "/pre_registro"){
	    	    actionContext({idResPaso1: parseInt(modalResPaso1) })
                localStorage.setItem("idResPaso1Buro",modalResPaso1)
                navigate('/crearcuenta')
            } else {
                localStorage.setItem("idResPaso1Cuestionario",modalResPaso1)
                // navigate('/informacionGeneral')

                let responseBtBuro = await get('obtDatosPaso2PorIdResPaso1',parseInt(modalResPaso1))
                 if(responseBtBuro.statuscode === 200){
                    if(parseInt(responseBtBuro.paso2.endeudamiento) >= 40){
                        navigate('/respuestaBuro')
                    }
                    else{ navigate('/informacionGeneral')} 
                   
                }else{
                    navigate('/respuestaBuro')
                }
            }
        } else {
            setIsOpenModalLoading(false)
            localStorage.setItem("idResPaso1Buro",modalResPaso1)
            localStorage.setItem("statuscodepaso2", response.statuscode)

            Swal.fire({
                title: 'No fue posible consultar tu buró',
                text: "Con la información que nos proporcionaste no fue posible consultar tu buró, sin embargo continua para obtener opciones de creditos disponibles para ti.",
                icon: 'error',
                showCancelButton: false,
                showConfirmButton: true,
                cancelButtonColor: '#9C36B2',
                confirmButtonText: 'Continuar sin resultado del buró'
            }).then((result) => {
                if (result.isConfirmed) {
                    if(location.pathname === "/pre_registro"){
                        actionContext({idResPaso1: parseInt(modalResPaso1) })
                        localStorage.setItem("idResPaso1Buro",modalResPaso1)
                        localStorage.setItem("sinRespuestaBuro", "true")
                        navigate('/crearcuenta')
                    } else {
                        localStorage.setItem("idResPaso1Buro",modalResPaso1)
                        localStorage.setItem("idResPaso1Cuestionario",modalResPaso1)
                        navigate('/respuestaBuro')
                        localStorage.setItem("sinRespuestaBuro", "true")//lo que apenas agregue
     
                    }
                }
            })
        }
    }

    if(!open) return null
    return (
        <>
            <div className='modal_back' />
            <div className='modal_pop anchoGrandePop'>
                <div className='closeModal'>
                    <span onClick={close}> <i className="fa fa-times-circle" aria-hidden="true"></i></span>
                </div>
                {children}
                <img src={logotipo_negro} alt="logo" />
                <div className="cont_text">
                    <h1>Solo para estar seguros que eres tú</h1>
                    <p>Te enviamos por SMS un código de autenticación al celular que registraste  </p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='divCodigoSms' >
                            <input type="tel" name="num1" id="num1" maxLength="1" autoFocus
                                onInput={(ev) => { 
                                    let tempNum1 = ev.target.value
                                    if (!isNaN(tempNum1) && tempNum1.length > 0) {
                                        tempNum1 = ev.target.value
                                        ev.target.nextElementSibling.focus()
                                    }
                                    else if( tempNum1.length === 0){
                                        ev.target.previousElementSibling.focus()
                                    }
                                    setDigito1(tempNum1)
                                }}
                                {...register('num1',{required:true})}
                            /> 
                            {errors.num1?.type === 'required'}

                            <input type="tel" name="num2" id="num2"  maxLength="1"
                                onInput={(ev) => { 
                                    let tempNum2 = ev.target.value
                                    if (!isNaN(tempNum2) && tempNum2.length > 0) {
                                        tempNum2 = ev.target.value
                                        ev.target.nextElementSibling.focus()
                                    }
                                    else if( tempNum2.length === 0){
                                        ev.target.previousElementSibling.focus()
                                    }
                                    setDigito2(tempNum2)
                                }}
                                {...register('num2',{required:true})}
                            /> 
                            {errors.num2?.type === 'required'}
                            
                            <input type="tel" name="num3" id="num3"  maxLength="1"
                                onInput={(ev) => { 
                                    let tempNum3 = ev.target.value
                                    if (!isNaN(tempNum3) && tempNum3.length > 0) {
                                        tempNum3 = ev.target.value
                                        ev.target.nextElementSibling.focus()
                                    }
                                    else if( tempNum3.length === 0){
                                        ev.target.previousElementSibling.focus()
                                    }
                                    setDigito3(tempNum3)
                                }}
                                {...register('num3',{required:true})}
                            /> 
                            {errors.num3?.type === 'required'} 

                            <input type="tel"name="num4" id="num4"  maxLength="1"
                                onInput={(ev) => { 
                                    let tempNum4 = ev.target.value
                                    if (!isNaN(tempNum4) && tempNum4.length > 0) {
                                        tempNum4 = ev.target.value
                                        ev.target.nextElementSibling.focus()
                                    }
                                    else if( tempNum4.length === 0){
                                        ev.target.previousElementSibling.focus()
                                    }
                                    setDigito4(tempNum4)
                                }}
                                {...register('num4',{required:true})}
                            /> 
                            {errors.num4?.type === 'required'}

                            <input type="tel" name="num5" id="num5"  maxLength="1"
                                onInput={(ev) => {
                                    let tempNum5 = ev.target.value
                                    if (!isNaN(tempNum5) && tempNum5.length > 0) {
                                        tempNum5 = ev.target.value
                                        ev.target.nextElementSibling.focus()
                                    }
                                    else if(tempNum5.length === 0){
                                        ev.target.previousElementSibling.focus()
                                    }
                                    setDigito5(tempNum5)
                                }}
                                {...register('num5',{required:true})}
                            /> 
                            {errors.num5?.type === 'required'}

                            <input type="tel" name="num6" id="num6"  maxLength="1"
                                onInput={(ev) => { 
                                    let tempNum6 = ev.target.value
                                    if(tempNum6.length === 0){
                                        ev.target.previousElementSibling.focus()
                                    }
                                    setDigito6(tempNum6)
                                }}
                                {...register('num6',{required:true})}
                            />
                            {errors.num6?.type === 'required'}
                        </div>

                        <div className="botonesModal">
                            <button type='submit' className="btn btn_morado_modal">Validar NIP</button>
                        </div>
                    </form>
                </div> 
            </div>
            <ModalLoadingGeneral open = {isOpenModalLoading} ></ModalLoadingGeneral> 

        </>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------- Modal para aceptar términos y condiciones ------------------------------------------>
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalCasiListos({children, open, close}) {
    const [aceptarAviso, setAceptarAviso] = useState(0);
    const [aceptarTerminos, setAceptarTerminos] = useState(0);

    if(!open) return null
    return (
        <>
            <div className='modal_back' />
            <div className='modal_pop'>
                {children}
                <div className="cont_text">
                    <h1>Casi listos</h1>
                    <p>Antes de continuar, ¿estas de acuerdo con los <a className="link" href="terminosCondiciones" target="_blank">términos y condiciones </a> y con <b>consultar tu buro de crédito?</b></p>
                    <form>
                        <div>
                            <div className='aceptarTerminos'>
                                <input type="radio" value={aceptarAviso}
                                onChange={() => {
                                    setAceptarAviso(1);
                                }} /> <label> Acepto términos y condiciones </label>
                            </div>
                            <div className='aceptarTerminos'>
                                <input type="radio" value={aceptarTerminos}
                                onChange={() => {
                                    setAceptarTerminos(1);
                                }} /> <label> Estoy de acuerdo con la consulta a mi buro de crédito </label>
                            </div>
                        </div>
                        {aceptarAviso !== 0 && aceptarTerminos !== 0 && 
                        <div className="botonesModal">
                            <button className="btn btn_morado_modal" onClick={close} >Continuar</button>
                        </div>
                        }
                    </form>
                </div> 
            </div>
        </>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------------------ Modal Sabias que... ----------------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalSabiasQue({children, open, close}) {
    if(!open) return null
    return (
        <>
            <div className='modal_back' />
            <div className='modal_pop'>
                {children}
                <div className="cont_text">
                    <img src={loadingicon2} alt="Login" />
                    <h1>Estamos <br/>analizando tu perfil</h1>
                    <p><b>En lo que revisamos tus datos sabías que...</b> </p>
                    <p>Más de la mitad de la población no tiene acceso a hipotecas?. Con <span className="link">Saturn5 </span>, 7 de cada 10 solicitantes obtienen el crédito que buscaban</p>
                </div> 
            </div>
        </>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------------- Modal en donde se muestran los resultados del buró de crédito -------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalBuroDeCredito({children, open, cardScoreModal, close}) {  
    if(!open) return null
    return (
        <>
            <div className='modal_back' />
            <div className='modal_pop_buro anchoGrandePop30'>
                <div className='closeModal_buro'>
                    <span onClick={close}> <i className="fa fa-times-circle" aria-hidden="true"></i></span>
                </div>
                {children}
                <div className="cont_flex" id="buroResultados">{cardScoreModal}</div>  
            </div>
        </>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <----------------------------- Modal en donde se muestra el deatlle de las ofertas al seleccionarlas ---------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalMasInfoBancos({children, open, close, ModalBanco, ModalCreditoOfertado, ModalMontoSolicitado, ModalPlazo, ModalMensualidad, ModalTasa, descripcion, PorcentajeTasa}) {
    let cardBancos = <InfoBancos
                        banco={ModalBanco} creditoOfertado={ModalCreditoOfertado} montoSolicitado={ModalMontoSolicitado} plazo ={ModalPlazo}
                        mensualidad={ModalMensualidad} tasa={ModalTasa} descripcion={descripcion} PorcentajeTasa={PorcentajeTasa}>
                    </InfoBancos>
    
    if(!open) return null
    return (
        <>
            <div className='modal_back' />
            <div className='modal_pop_buro anchoGrandePop'>
                <div className='closeModal'>
                    <span onClick={close}> <i className="fa fa-times-circle" aria-hidden="true"></i></span>
                </div>
                {children}
                <div className="cont_flex">{cardBancos}</div>
            </div>
        </>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------------- Modal que se muestra cuando no estas registrado en crediteka  -------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalVerificarRegistroCrediteka({children, open, close}) {
    const navigate = useNavigate()

    function regresarHome(){
        navigate("/cotizaciones")
    }

    if(!open) return null
    return (
        <>
            <div className='modal_back' />
            <div className='modal_pop'>
                {children}
                <div className="cont_text">
                    <h1>Por favor <br/> verifica tu registro</h1>
                    <p><b>Te hemos enviado un correo para comprobar que eres tú</b></p>
                    <p>Si no encuentras el correo, buscalo en la carpeta de spam </p>
                </div> 
                <div className="botonesModal">
                    <button className="btn btn_morado_modal" onClick={close}>Aceptar</button>                            
                </div>
                <div className="botonesModal">
                    <button className="btn btn_morado_modal" onClick={regresarHome} >Regresar</button>
                </div>
            </div>
        </>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------------- Modal cuando no se puede procesar una solicitud --------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalErrorVerificar({children, open, close}) {
    if(!open) return null
    return (
        <>
            <div className='modal_back' />
            <div className='modal_pop'>
                {children}
                <div className="cont_text">
                    <p><b>Error al procesar su solicitud</b> </p>
                    <p>Inténtelo más tarde</p>
                </div> 
                <div className="botonesModal">
                    <button className="btn btn_morado_modal" onClick={close} >Aceptar</button>
                </div>
            </div>
        </>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------ Modal utilizado como menú lateral reponsivo ----------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function MenuLateralResponsivo({children, open, close}) {
    const cookies = new Cookies()

    const logOut =() =>{
        localStorage.removeItem("idResPaso1Cuestionario")
        localStorage.removeItem("BancoLS1")
        localStorage.removeItem("BancoLS2")
        localStorage.removeItem("idResPaso1SeleccionarBancos")
        localStorage.removeItem("verBancos")

        localStorage.removeItem("monto")
        localStorage.removeItem("plazoVal")
        localStorage.removeItem("montoPrestar")
        localStorage.removeItem("enganche")
        localStorage.removeItem("tasa")
        localStorage.removeItem("mensualidad")
        localStorage.removeItem("sinRespuestaBuro")
        
        cookies.remove('session')
        cookies.remove('nombre')
        cookies.remove('correo')
        cookies.remove('id')
        window.location.href='./'
    }

    if(!open) return null
    return (
        <>
            <div className='modal_back' />
            <div className='modal_pop_menuLateral anchoGrandePopMenuLateral'>
                <div className='closeModal_menu'>
                    <div>
                        <Link to='/home'> <img src={logoMorado} alt="logo"/></Link>
                    </div>
                    <button type="button" aria-label="Toggle Navigation" className="lines-button x" onClick={close}>
                        <span className="lines"></span>
                    </button>
                </div>
                {children}
                <div className=" menu_lateral_pop">
                    <div className='widthCompleto'>
                    <ul>
                        <li onClick={close} ><Link to='/home' ><i className="fa fa-th-large"></i> Resumen </Link></li>
                        <li onClick={close}><Link to='/misDatos' ><i className="fa  fa-user"></i> Mis Datos </Link></li>
                        <li onClick={close}><Link to='/cotizaciones' ><i className="fa  fa-file"></i> Mis Cotizaciones </Link></li>
                        <li onClick={close}><Link to='/simulacion' > <i class="fa fa-plus-square" aria-hidden="true"></i> Nueva Cotización </Link></li>
                    </ul>    
                    </div>
                    <div className="botonesModal">
                            <button className="btn btn_morado_modal" onClick={() => {logOut()}} >Cerrar sesión</button>
                    </div>
                    <div className="copyright">
                        <p>En caso de que tengas preguntas, dudas o necesites asesoría no dudes en contactarnos. Nos encanta atenderte a la hora que lo necesites</p>
                        <li onClick={close}> <Link to="/AvisoPrivacidadLogueado" className="link">Aviso de privacidad</Link></li>
                        <li onClick={close}> <Link to="/TerminosCondicionesLogueado" className="link">Términos y condiciones</Link></li>
                        <p>© 2023. Saturn5 SAPI de CV</p>
                    </div>
                </div>
            </div>
        </>
    )
}


export function MenuLateralResponsivoSocios({children, open, close}) {
    const cookies = new Cookies()

    const logOut =() =>{
        localStorage.removeItem("idResPaso1Cuestionario")
        localStorage.removeItem("BancoLS1")
        localStorage.removeItem("BancoLS2")
        localStorage.removeItem("idResPaso1SeleccionarBancos")
        localStorage.removeItem("verBancos")

        localStorage.removeItem("monto")
        localStorage.removeItem("plazoVal")
        localStorage.removeItem("montoPrestar")
        localStorage.removeItem("enganche")
        localStorage.removeItem("tasa")
        localStorage.removeItem("mensualidad")
        
        cookies.remove('sessionSocio')
        cookies.remove('nombreSocio')
        cookies.remove('correoSocio')
        cookies.remove('idSocios')
        cookies.remove('idSocio')
        window.location.href='./inicioSocios'
    }

    if(!open) return null
    return (
        <>
        {cookies.get('idSocio') ? (
        <div>
            <div className='modal_back' />
            <div className='modal_pop_menuLateral anchoGrandePopMenuLateral'>
            <div className='closeModal_menu'>
                <div>
                <Link to='/inicioSocios'> <img src={logoMorado} alt="logo" /></Link>
                </div>
                <button type="button" aria-label="Toggle Navigation" className="lines-button x" onClick={close}>
                <span className="lines"></span>
                </button>
            </div>
            {children}
            <div className="menu_lateral_pop">
                <div className='widthCompleto'>
                <ul>
                    <li onClick={close}><Link to='/panelSocios'><i className="fa fa-th-large"></i> Clientes </Link></li>
                    <li onClick={close}><Link to='/invitacionesSocios'><i className="fa fa-user"></i> Invitaciones </Link></li>
                </ul>
                </div>
                <div className="botonesModal">
                <button className="btn btn_morado_modal" onClick={() => { logOut() }}>Cerrar sesión</button>
                </div>
                <div className="copyright">
                <p>En caso de que tengas preguntas, dudas o necesites asesoría no dudes en contactarnos. Nos encanta atenderte a la hora que lo necesites</p>
                <li onClick={close}> <Link to="/AvisoPrivacidadLogueado" className="link">Aviso de privacidad</Link></li>
                <li onClick={close}> <Link to="/TerminosCondicionesLogueado" className="link">Términos y condiciones</Link></li>
                <p>© 2023. Saturn5 SAPI de CV</p>
                </div>
            </div>
            </div>
        </div>
        ) : 
            <div>
                <div className='modal_back' />
                <div className='modal_pop_menuLateral anchoGrandePopMenuLateral'>
                <div className='closeModal_menu'>
                    <div>
                    <Link to='/inicioSocios'> <img src={logoMorado} alt="logo" /></Link>
                    </div>
                    <button type="button" aria-label="Toggle Navigation" className="lines-button x" onClick={close}>
                    <span className="lines"></span>
                    </button>
                </div>
                {children}
                <div className="menu_lateral_pop">
                    <div className='widthCompleto'>
                    <ul>
                        <li onClick={close}><Link to='/inicioSocios'><i className="fa fa-th-large"></i> Iniciar sesión </Link></li>
                        <li onClick={close}><Link to='/CrearCuentaSocios'><i className="fa fa-user"></i> Crear cuenta </Link></li>
                    </ul>
                    </div>
                    <div className="copyright">
                    <p>En caso de que tengas preguntas, dudas o necesites asesoría no dudes en contactarnos. Nos encanta atenderte a la hora que lo necesites</p>
                    <li onClick={close}> <Link to="/AvisoPrivacidadLogueado" className="link">Aviso de privacidad</Link></li>
                    <li onClick={close}> <Link to="/TerminosCondicionesLogueado" className="link">Términos y condiciones</Link></li>
                    <p>© 2023. Saturn5 SAPI de CV</p>
                    </div>
                </div>
                </div>
            </div>
        }

        </>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------- Modal 'loading' cuando se envia un cuestionario preAutoriza --------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalLoading({children, open, close}) {
    if(!open) return null
    return (
        <>
            <div className='modal_back' />
            <div className='modal_pop'>
                
                {children}
                <div className="cont_text">
                    <img src={loadingicon} alt="Login" />
                    <h1>Estamos <br/>analizando tu solicitud</h1>
                    <p><b>Analizando información...</b> </p>
                    <p>Favor de no cerrar la ventana, en un momento podrá observar los resultados obtenidos</p>
                </div> 
            </div>
        </>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------- Modal que aparece cuando se valida el hash al cambiar contraseña ------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalLoadingCambiarContraseña({children, open, close}) {
    if(!open) return null
    return (
        <>
            <div className='modal_back' />
            <div className='modal_pop'>
                {children}
                <div className="cont_text">
                    <img src={loadingicon} alt="Login" />
                    <h1>Estamos <br/> asegurandonos que eres tú</h1>
                    <p><b>Favor de no cerrar la ventana...</b> </p>
                </div> 
            </div>
        </>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <----------------------------------- Modal que muestra mensaje si es que no se retornan bancos ---------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalRespuestaPreAutorizaSinBancos({children, open, msg, close}) {
    function regresarHome(){
        window.location.href='./Cotizaciones'
    }

    if(!open) return null
    return (
        <>
            <div className='modal_back' />
            <div className='modal_pop'>   
                {children}
                <div className="cont_text">
                    <h1>Resultado de su consulta</h1>
                    {/* <p> {msg} </p>                     */}
                    <div id="txtSinBanco" className="sinbancosClass" dangerouslySetInnerHTML={{__html: msg}}>
                    </div> 
                </div> 
                <div className="botonesModal">
                    <button className="btn btn_morado_modal" onClick={close} >Editar información</button>
                    <button className="btn btn_morado_modal" onClick={regresarHome} >Aceptar</button>
                </div>
            </div>
        </>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------- Modal que muestra mensaje si es que no se retornan bancos en la pantalla "Mis cotizaciones" ------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalRespuestaPreAutorizaSinBancosTarjetas({children, open, msg, close}) {
    if(!open) return null
    return (
        <>
            <div className='modal_back' />
            <div className='modal_pop'>
                <div className='closeModal'>
                    <span onClick={close}> <i className="fa fa-times-circle" aria-hidden="true"></i></span>
                </div>
                {children}
                <div className="cont_text">
                    <h1>Resultado de su consulta</h1>
                    {/* <p> {msg} </p>         */}
                    <div id="txtSinBanco" className="sinbancosClass" dangerouslySetInnerHTML={{__html: msg}}>
                    </div>             
                </div> 
                <div className="botonesModal">
                    <button className="btn btn_morado_modal" onClick={close} >Aceptar</button>
                </div>
            </div>
        </>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <----------------------------------- Modal "loading" generico esperando una respuesta del API  ---------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalLoadingGeneral({children, open, close}) {
    if(!open) return null
    return (
        <>
            <div className='modal_back' />
            <div className='modal_pop'>
                {children}
                <div className="cont_text">
                    <img src={loadingicon2} alt="Login" />
                    <h1>Espere un momento</h1>
                    <p>Cargando información</p>
                </div> 
            </div>
        </>
    )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------------- Modal que se muestra cuando tienes una edad >= 84 años  -------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalVerificarEdad({children, open, close, plazo, edad}) {
    const navigate = useNavigate()

    function regresarHome(){
        navigate("/cotizaciones")
    }

    if(!open) return null
    return (
        <>
            <div className='modal_back' />
            <div className='modal_pop'>
                {children}
                <div className="cont_text">
                    <h1>Inicia tu simulación <br/>  nuevamente </h1>
                    <p><b>El plazo que seleccionaste '{plazo} años' más tu edad '{edad} años' no debe ser mayor o igual a 84 años</b></p>
                </div> 
                <div className="botonesModal">
                    <button className="btn btn_morado_modal" onClick={regresarHome}> Aceptar y regresar</button>
                </div>
            </div>
        </>
    )
}