import "../App.css";
import "../sass/style.css";
import logo_negro from "../images/logo_saturn.svg";
import isotipo_negro from "../images/isotipo-negro.svg";
import subtrack from "../images/Subtract.svg";
import logoMorado from '../images/logo_morado.svg'
import logo_remax from "../images/FINMAX.png";
import Cookies from 'universal-cookie'
import { Link } from "react-router-dom";
import {MenuLateralResponsivo} from '../components/Modals'
import { useState } from "react";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------- Footer cuando el usuario sí esta logueado ------------------------------------------>
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function HeadHome() {
    const [isOpenModalMenuLateral, setIsOpenModalMenuLateral] = useState(false)
    const cookies = new Cookies()

    const logOut =() =>{
        localStorage.removeItem("idResPaso1Cuestionario")
        localStorage.removeItem("BancoLS1")
        localStorage.removeItem("BancoLS2")
        localStorage.removeItem("idResPaso1SeleccionarBancos")
        localStorage.removeItem("verBancos")

        localStorage.removeItem("monto")
        localStorage.removeItem("plazoVal")
        localStorage.removeItem("montoPrestar")
        localStorage.removeItem("enganche")
        localStorage.removeItem("tasa")
        localStorage.removeItem("mensualidad")
        
        cookies.remove('session')
        cookies.remove('nombre')
        cookies.remove('correo')
        cookies.remove('id')
        window.location.href='./'
    }
    
    return (
    <>
        <header>
            <div>
                <Link to='/home' className="isotipo" ><img src={isotipo_negro} alt="logo" /></Link>
                <Link to='/home' > <img src={logo_negro} alt="logo" /></Link>
            </div>
            <ul className="menu">
                <li><Link to='/simulacion' className="btn btn_nuevo"> <img src={subtrack} alt="Cotiza ahora"/> Nueva cotización</Link> </li>
                <li className="logoSesionIniciada">
                    <i className="fa  fa-user iconoUsuarioLoggueado"></i> 
                    <select id="select_login" onChange={() => {logOut()}}>
                        <option> {cookies.get('nombre')}</option>
                        <option> Cerrar Sesion</option>
                    </select>               
                </li>
            </ul>
        </header>
        <div className="header_responsive">
            <div>
                <Link to='/home' ><img src={logoMorado} alt="logo"/> </Link>
            </div>
         	<button type="button" aria-label="Toggle Navigation" className="lines-button x" onClick={() =>setIsOpenModalMenuLateral(true)}> 
			    <span className="lines"></span>
			</button>
            <MenuLateralResponsivo open = {isOpenModalMenuLateral} close = { () => setIsOpenModalMenuLateral(false)} ></MenuLateralResponsivo>

            <ul className="menu_responsive">
                <li><a href="#/" className="btn btn_nuevo" > 
                    <img src={subtrack} alt="Cotiza ahora"/> Nueva cotización</a>
                </li>
                <li>
                    <select id="select_login">
                        <option>Hola, Nombre Usuario</option>
                        <option>Datos</option>
                        <option>Otros</option>
                        <option onClick={() => {logOut()}}>Cerrar Sesion</option>
                    </select>
                </li>
            
                <li><a href="resumen.html" className="active"><i className="fa fa-grid-2"></i> Resumen</a></li>
                <li><a href="datos.html"><i className="fa  fa-user"></i> Mis Datos</a></li>
                <li><a href="cotizaciones.html"><i className="fa  fa-file"></i> Mis Cotizaciones</a></li>
                <li><a href="solicitudes.html"><i className="fa fa-memo"></i> Mis Solicitudes</a></li>
                <li><a href="hipotecas.html"><i className="fa fa-house"></i> Mis hipotécas</a></li>           
            </ul>
        </div>
    </>
  );
}
