import '../../../App.css'
import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Cookies } from 'react-cookie'
import { get } from '../../../utils/http'
import * as ReactDOM from "react-dom/client";
import { Link } from 'react-router-dom';
import { TablaInvitaciones  } from '../../../components/Tablas';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export default function InvitacionesSocios() 
{
    const cookies = new Cookies();
    const navigate = useNavigate();
    const idSocios = cookies.get('idSocio');
    const [dataInvitaciones, setDataInvitaciones] = useState("");
    //Obtenemos información general
    const obtInformacion = async() =>{
        let data = await get('getInvitaciones', idSocios);
        if(data.statuscode === 200){
            setDataInvitaciones(data.invitaciones);
        }
        else{

        }
    }

    useEffect( () => {
        obtInformacion();
    },[])

    return(
    <>
        <div className=" cont_Info"> 
            <h1 className="titt">Invitaciones​</h1>
            <p>Aquí puedes visualizar y crear invitaciones para tus clientes.</p>

            <div className='cont_flex cont-form'>
                <div className='dosCuttonFormularios'>
                    <Link className="btn btn_bco col3 campo-form "  to="/frmNuevoCliente">Enviar nueva invitación a cliente</Link>
                </div>
            </div>
            <TablaInvitaciones arrData = {dataInvitaciones}></TablaInvitaciones>
        </div>
    </>
    );
}