// <---------- URL del servidor ---------->
const url = "https://saturn5.somee.com/"
//const url = "https://localhost:7079/"
//const url = "https://api.saturn5.mx/";
// <---------- Datos API COPOMEX ---------->
const urlCP =  "https://api.copomex.com/query/"
const tokenCP = "a0c243a5-e523-4f5b-be13-c2b24b38e7ac"

// <-------------------------------------------------------------------------------------------------------------------------------->
// <----------------------------------------- Función para enviar y obtener respuesta POST ----------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export async function post(endpoint,data){
    return await fetch(url+endpoint,{
        method: "POST",
        body: JSON.stringify(data),
        headers: {"Content-type": "application/json; charset=UTF-8"}, 
    })
    .then(response => {return response.json()}) 
    .catch(err => console.log(err)  )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <----------------------------------------- Función para enviar y obtener respuesta GET ------------------------------------------>
// <-------------------------------------------------------------------------------------------------------------------------------->
export async function get(endpoint,data){
    return await fetch(url+endpoint+"/"+data,{
        method: "GET",
        headers: {"Content-type": "application/json; charset=UTF-8"}, 
    })
    .then(response => {return response.json()})
    .catch(err => console.log(err)  )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------ Función para enviar y obtener respuesta PUT ----------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
 export async function put(endpoint,data){
    return await fetch(url+endpoint,{
        method: "PUT",
        body: JSON.stringify(data),
        headers: {"Content-type": "application/json; charset=UTF-8"}, 
    })
    .then(response => {return response.json()})
    .catch(err => console.log(err)  )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------ Función para obtener respuesta del API COPOMEX para CP  ----------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export async function getCP(endpoint,data){
    return await fetch(urlCP+endpoint+"/"+data+"?type=simplified&token="+tokenCP,{
        method: "GET",
        headers: {"Content-type": "application/json; charset=UTF-8"}, 
    })
    .then(response => {return response.json()})
    .catch(err => console.log(err))
}
    