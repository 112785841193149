import "../../../App.css";
import "../../../sass/style.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { obtenerClientes } from "../../../components/Funciones";
import ObtUsuarioRemaxComponent from "../../../components/Funciones";
import { Tabla } from "../../../components/Tablas";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

export default function PanelSocios() {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const idSocios = cookies.get("idSocio");
  const [dataClientes, setDataClientes] = useState("");

  useEffect(() => {
    obtenerClientes(idSocios, setDataClientes);
  }, [idSocios]);
  return (
    <>
      <ObtUsuarioRemaxComponent idSocios={idSocios} />
      <div className=" cont_Info">
        <h1 className="titt">Mis clientes</h1>
        <p className="descripcionClientes">
          Aquí puedes visualizar el progreso de tus clientes.
        </p>

        <Tabla arrData={dataClientes}></Tabla>
      </div>
    </>
  );
}